.block-listing {
    @media all and (max-width: $sm-breakpoint) {
        margin-bottom: rem(40);
    }
    margin-bottom: rem(70);
    &--no-bg {
        background-color: transparent;
    }
    .block-title {
        @media all and (max-width: $sm-breakpoint) {
            padding-top: rem(60);
        }
        padding-top: rem(80);
        padding-bottom: rem(41);
        @include container($container, $gutter);
        h1 {
            margin-bottom: 0;
            color: $light-blue;
            font-family: $helvetica-light;
            font-size: rem(32);
            font-weight: 300;
        }
    }
    .text-before {
        margin-bottom: rem(85);
        color: $dark-grey2;
        font-size: rem(14);
        line-height: 1.57;
        @include container($container, $gutter);
        h1 {
            margin-top: rem(2);
            color: $dark-grey2;
            font-family: $helvetica-light;
            font-size: rem(20);
            font-weight: 500;
            line-height: 1.5;
            @media all and (min-width: $md-breakpoint) {
                float: left;
                width: 33.33%;
                padding-right: rem(15);
                margin-right: rem(10);
            }
        }
        p {
            margin-top: rem(22);
        }
    }
    .contents-items {
        @media all and (min-width: $sm-breakpoint) {
            display: flex;
            flex-flow: row wrap;
            margin-right: rem(-15);
            margin-left: rem(-15);
        }
        &__container {
            @include container($container, 10);
            padding-top:65px;

            @media all and (min-width: $sm-breakpoint) {
                @include container($container, 20);
            }
            @media all and (min-width: $md-breakpoint) {
                padding-top:0;
            }
        }
        > div {
            @media all and (min-width: $sm-breakpoint) {
                flex: 1 0 calc(50% - 30px);
                flex-basis:calc(50% - 30px); // flex-basis values doesn't work in the flex shorthand in IE
                /*flex: 1 0 50%;*/
                margin: rem(30) rem(15) 0;
                /*margin: 0 0 rem(30);*/

                &:last-child{
                    flex: 1 0 50%;
                    flex-basis: 50%; // flex-basis values doesn't work in the flex shorthand in IE
                }

            }
            @media all and (min-width: $md-breakpoint) {
                flex: 1 0 calc(33.33% - 30px);
                flex-basis:calc(33.33% - 30px); // flex-basis values doesn't work in the flex shorthand in IE
                /*flex: 1 0 33.33%;*/
            }
            img {
                display: block;
                width: 100%;
            }
            &:nth-of-type(4n) {
                @media all and (min-width: $md-breakpoint) {
                    display: flex;
                    flex: 0 0 calc(100% - 30px);
                    flex-basis:calc(100% - 30px); // flex-basis values doesn't work in the flex shorthand in IE
                    /*flex: 0 0 100%;*/
                    flex-direction: row !important;
                }
                .video-link, .thumb-image {
                    @media all and (min-width: $md-breakpoint) {
                        flex: 0 1 calc(66.66% + 19px);
                        flex-basis:calc(66.66% + 19px); // flex-basis values doesn't work in the flex shorthand in IE
                    }
                }
                .content-item__text {
                    @media all and (min-width: $md-breakpoint) {
                        flex: 1 0 calc(33.33% - 19px);
                        flex-basis:calc(33.33% - 19px); // flex-basis values doesn't work in the flex shorthand in IE
                        padding-top: rem(40);
                    }
                }
            }
            &:nth-of-type(8n) {
                .video-link, .thumb-image {
                    @media all and (min-width: $md-breakpoint) {
                        flex: 0 1 calc(66.66% + 19px);
                        flex-basis:calc(66.66% + 19px); // flex-basis values doesn't work in the flex shorthand in IE
                        order: 2;
                    }
                }
                .content-item__text {
                    @media all and (min-width: $md-breakpoint) {
                        order: 1;
                    }
                }
            }
        }
        .content-item-inner{
            margin:0 rem(15);
            background-color: $white;
            transition: all 0.3s;
            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            }
        }
        .content-item {
            background:#FFFFFF;

            &--hidden {
                margin: 0;
                visibility: hidden;
            }
            .video-link {
                position: relative;
                display: block;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: rem(76);
                    height: rem(76);
                    background-color: $red;
                    border-radius: 50%;
                    content: '';
                    transform: translate(-50%, -50%);
                }
                &:after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 0;
                    height: 0;
                    border: 13px solid transparent;
                    border-left: 16px solid $white;
                    content: '';
                    transform: translate(-5px, -50%);
                }
                &:focus {
                    outline: none;
                }
            }
            &__text {
                padding: rem(30);
                color: $dark-grey2;
                font-size: rem(14);
                line-height: 1.57;
                h2 {
                    margin-top: 0;
                    margin-bottom: rem(23);
                    color: $light-blue;
                    font-family: $helvetica-light;
                    font-size: rem(20);
                    line-height: 1.4;
                }
            }

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.toolbar-bottom--video{
    .pages{
        padding-top:53px;
        padding-bottom:35px;

        @media (min-width: $sm-breakpoint){
            padding-top:75px;
            padding-bottom:10px;
        }
    }
}