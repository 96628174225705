.nav-sections {
    @media all and (min-width: $md-breakpoint) {
        width: max-content;
        margin: 0 auto;
    }

    @media all and (min-width: $xlg-breakpoint) {
        padding-left: rem(192);
        margin-top: -110px;
        margin-bottom: rem(96);
    }

    .navigation {
        z-index: 11;
        color: $mid-grey;
        transition: all 0.3s ease-in-out;

        @media all and (max-width: $sm-breakpoint-max) {
            position: fixed;
            top: calc(#{$header-height} + #{$header-nav-storelocator}) ;
            right: 0;
            bottom: 0;
            left: 0;
            max-height: 0;
            overflow-y: auto;
            opacity: 0;
            li.join-comfort-club,
            li.participating-stores {
                > a > .btn {
                    width: 100%;
                    padding: 0.5rem 1.25rem;
                }
            }
        }
        &.au .occasional {
            display: none;
        }
        &.open {
            max-height: 1000px;
            background: $white;
            opacity: 1;
        }
        > ul {
            padding: 0;
            margin: 0;
            @media all and (min-width: $md-breakpoint) {
                position: relative;
                display: flex;
                justify-content: center;
            }
            > li {
                list-style: none;
                @media all and (max-width: $sm-breakpoint-max) {
                    position: relative;
                    display: block;
                    padding: 0 rem(30);
                    border-bottom: 1px solid $lighter-grey;
                    .toggle-nav {
                        position: absolute;
                        top: 0;
                        right: rem(16);
                        z-index: 13;
                        display: block;
                        width: rem(60);
                        height: rem(60);
                        padding: rem(16) 0;
                        text-align: center;
                        &:after {
                            color: $bright-blue;
                            font-size: rem(20);
                            content: '+';
                        }
                    }
                    &.open > .toggle-nav:after {
                        font-size: rem(22);
                        content: '-';
                    }
                }
                @media all and (min-width: $md-breakpoint) {
                    white-space: nowrap;
                    &:hover {
                        > ul {
                            position: absolute;
                            top: unset;
                            box-sizing: border-box;
                            align-items: flex-start;
                            width: auto;
                            height: auto !important;
                            max-width: rem(1200);
                            min-width: rem(200);
                            padding: rem(48) rem(60);
                            border: 2px solid $lighter-grey;
                            opacity: 1;
                        }
                        a {
                            &:hover {
                                color: $blue;
                            }
                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                    &.mega {
                        &:hover {
                            > ul {
                                right: - rem(160);
                                left: - rem(160);
                                display: flex;
                                margin: auto;
                                @media all and (min-width: $lg-breakpoint) {
                                    right: - rem(240);
                                    left: - rem(240);
                                }
                            }
                        }                    
                    }
                    &.first {
                        display: none;
                    }
                    &.last > a {
                        padding: 0;
                    }
                    &.join-comfort-club > a,
                    &.participating-stores > a {
                        padding-right: 0;
                        margin-left: 0;
                        @media all and (min-width: $lg-breakpoint) {
                            margin-left: 1rem;
                        }
                        .btn {
                            width: 16rem;
                            padding: 0.5rem 1.25rem;
                            @media all and (max-width: $lg-breakpoint) {
                                width: 15rem;
                                padding: 0.5rem 0.75rem;
                        
                            }
                        }
                       
                    }
                    &.join-comfort-club > a .btn {
                        width: auto;
                    }
                    > a > span,
                    > a > .btn {
                        @media all and (max-width: $lg-breakpoint) {
                            font-size: 13px;
                        }
                    }
                }
                > a {
                    display: inline-block;
                    font-family: $helvetica;
                    font-size: rem(13);
                    text-transform: uppercase;

                    $lg-breakpoint: 1200px;
                    @media all and (max-width: $sm-breakpoint-max) {
                        display: block;
                        line-height: rem(60);
                        letter-spacing: rem(1);
                    }
                    @media all and (min-width: $md-breakpoint) {
                        padding: 0 rem(40) 0 rem(10);
                        line-height: $header-height;
                        letter-spacing: 0;
                        text-align: center;
                        background-color: transparent;
                        @media all and (max-width: $lg-breakpoint) {
                            padding: 0 rem(30) 0 rem(10);
                            font-size: rem(14);
                        }
                    }
                }
                &.parent, &.mega {
                    > a {
                        @media all and (min-width: $md-breakpoint) {
                            &:after {
                                position: absolute;
                                color: $bright-blue;
                                font-family: icomoon !important;
                                font-size: rem(22);
                                content: '\e916';
                                transition: all 0.2s;
                            }
                            &:hover {
                                color: $blue;
                                &:after {
                                    transform: scaleY(-1);
                                }
                                .submenu {
                                    display: block !important;
                                }
                            }
                        }
                    }
                }
                &.parent {
                    position: relative;
                    .submenu {
                        list-style: none;
                        @media all and (min-width: $md-breakpoint) {
                            position: absolute;
                            top: rem(60) !important;
                            z-index: 9;
                            display: none;
                            min-width: 200px;
                            padding: rem(18) rem(30);
                            line-height: rem(24);
                            text-align: left;
                            text-transform: uppercase;
                            background-color: $white;
                            border: 1px solid rgba(240, 244, 246, 0.15);
                            box-shadow: 0 1px 12px rgba(0, 0, 0, 0.175);
                            opacity: 0;
                            transition: all 0.3s ease-in-out;
                            &:before {
                                position: absolute;
                                top: -10px;
                                left: 50%;
                                width: 0;
                                height: 0;
                                border-right: 11px solid transparent;
                                border-bottom: 11px solid $white;
                                border-left: 11px solid transparent;
                                content: '';
                                transform: translate(-50%, 0);
                            }
                        }
                        a,
                        span {
                            color: $mid-grey;
                            font-size: rem(13);
                            line-height: 2;
                            &:hover {
                                color: $blue;
                            }
                        }
                    }
                    @media all and (min-width: $md-breakpoint) {
                        &:hover {
                            .submenu {
                                display: block;
                                opacity: 1;
                            }
                        }
                    }
                }
                > ul {
                    @media all and (max-width: $sm-breakpoint-max) {
                        display: none;
                        padding: 0 0 rem(16);
                        opacity: 0;
                        transition: all 0.3s ease-in-out;
                        &.open {
                            display: block;
                            opacity: 1;
                        }
                    }
                    @media all and (min-width: $md-breakpoint) {
                        top: 100px;
                        left: 0;
                        display: none;
                        align-items: flex-start;
                        height: 0;
                        max-height: none;
                        padding: 0;
                        background-color: rgba($white, 1);
                        opacity: 0;
                    }
                    > li {
                        position: relative;
                        list-style: none;
                        @media all and (max-width: $sm-breakpoint-max) {
                            > .toggle-nav {
                                right: - rem(16);
                                padding: 0;
                            }
                            &.open > .toggle-nav:after {
                                content: '-';
                            }
                            > ul {
                                display: none;
                                padding: 0 0 rem(16);
                                list-style: none;
                                opacity: 0;
                                transition: all 0.3s ease-in-out;
                                &.open {
                                    display: block;
                                    opacity: 1;
                                }
                                a {
                                    font-size: rem(13);
                                    line-height: 2;
                                }
                            }
                            &.promo {
                                display: none;
                            }
                        }
                        @media all and (min-width: $md-breakpoint) {
                            flex: 1 0 auto;
                            flex-basis: 25%;
                            text-align: left;
                            > ul {
                                display: block;
                                padding: rem(16) 0;
                                margin-top: rem(16);
                                margin-right: rem(60);
                                border-top: 2px solid $lighter-grey;
                                > li {
                                    list-style: none;
                                    > a {
                                        color: $mid-grey;
                                        font-size: rem(13);
                                        line-height: rem(32);
                                        text-transform: capitalize;
                                        &:hover {
                                            color: $blue;
                                        }
                                    }
                                }
                            }
                            &.features a.level2.last {
                                display: block;
                                padding: rem(8);
                                margin-top: rem(24);
                                color: $bright-blue;
                                text-align: center;
                                text-transform: none;
                                border: 2px solid $bright-blue;
                                transition: all 0.3s ease-in-out;
                                &:hover {
                                    color: $white;
                                    background: $bright-blue;
                                }
                            }
                        }
                        > a,
                        > span {
                            display: inline-block;
                            color: $bright-blue;
                            font-size: rem(15);
                            line-height: rem(40);
                            text-transform: uppercase;
                        }
                    }
                }
            }
            &.customer-mobile-nav {
                @media all and (max-width: $sm-breakpoint-max) {
                    display: block;
                    background: $bright-blue;
                    > li {
                        color: $white !important;
                        border: none;
                        .toggle-nav:after {
                            color: $white;
                        }
                        .submenu a {
                            font-size: rem(13);
                            line-height: 2;
                            text-transform: capitalize !important;
                            span {
                                color: $white;
                            }
                        }
                        > a {
                            font-family: $helvetica-bold;
                            text-transform: capitalize !important;
                        }
                    }
                }
                display: none;
            }
        }
    }
}
a.menu-item-active > span {
    text-decoration: none !important;
}

.nav-sections-item-title {
    display: none;
}

.minicart-wrapper {
    display: none;
}
