.lookbooks-wrapper {
    margin-top: rem(40);
    margin-bottom: rem(40);

    @media all and  (min-width: $sm-breakpoint) {
        margin-top: rem(85);
        margin-bottom: rem(100);
    }
}

.lookbooks-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: rem(-28);
    margin-left: rem(-28);
}

.lookbooks-item {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: rem(28);
    padding-left: rem(28);
    margin-bottom: rem(16);
    text-align: center;

    &:last-child {
        @media all and (max-width: $sm-breakpoint -1 ) {
            margin-bottom: 0;
        }
    }

    &:hover {
        img {
            transform: scale(1.05);
        }
    }

    @media all and (min-width: $sm-breakpoint) {
        flex-basis: calc(100% / 3);
        margin-bottom: rem(34);
    }

    @media all and (min-width: $md-breakpoint) {
        flex-basis: calc(100% / 4);
    }

    h3 {
        margin: 0 0 rem(12);
        color: $light-blue;

        // @media all and (max-width: $md-breakpoint -1) {
        //     font-size: rem(16);
        // }
    }

    p {
        margin: 0;
    }

    a {
        display: block;
    }
}

.lookbooks-item__thumb {
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        transition: all 0.25s ease-in-out;
    }
}
