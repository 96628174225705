.customer-account-createpassword {
    background-color: $blue;

    .page-title-wrapper {
        display: none;
    }

    .page-wrapper {
        padding-top: $header-height;

        @media all and (min-width: $md-breakpoint) {
            padding-top: 0;
            margin-top: $header-height;
        }
    }
}

.reset-password-page {
    padding: rem(30) 0 rem(50);
    text-align: center;

    @media all and (min-width: $md-breakpoint) {
        padding: rem(50) 0 rem(50);
    }

    .page-title {
        width: rem(270);
        margin: 0 auto rem(70) auto !important;

        @media all and (min-width: $sm-breakpoint) {
            width: auto;
        }
    }

    .fieldset {
        width: calc(100% + #{rem(20)});
        margin-bottom: 0;
    }

    .note {
        margin-bottom: rem(45);
    }

    .actions-toolbar {
        margin-top: rem(60);
        margin-bottom: rem(70);

        @media all and (min-width: $sm-breakpoint) {
            justify-content: center !important;
        }
    }

    a {
        color: #0A5389;
    }
}