.wishlist-index-index {
    .page-title-wrapper {
        margin-bottom: rem(80);
    }

    .product-items {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0 rem(-15);
        list-style-type: none;
    }

    .product-item {
        position: relative;
        width: calc(100% - #{rem(30)});
        margin: rem(15);
        background-color: $white;
        transition: all 0.4s ease;

        @media all and (min-width: $sm-breakpoint) {
            width: calc(50% - #{rem(30)});
        }

        @media all and (min-width: $md-breakpoint) {
            width: calc(33.33% - #{rem(30)});
        }

        &:hover {
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        }

        &__title {
            margin: 0 0 rem(5) 0;
            color: $light-blue;
            font-size: rem(18);
            text-align: center;
        }

        .price {
            color: #898989;
            font-family: $helvetica-bold;
            font-size: rem(14);
        }

        .price-box {
            margin-bottom: rem(25);
            text-align: center;

            p {
                margin: 0;
            }
        }

        img {
            width: 100%;
            height: auto;
            margin-bottom: rem(25);
        }
    }

    .btn-remove {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(40);
        height: rem(40);
        color: $white;
        font-size: rem(20);
        background-color: #A5412F;
        transition: all 0.4s ease;

        &:hover {
            background-color: lighten(#A5412F, 10%);
        }
    }
}