.custom-slider {
    @media all and (max-width: 767px) {
        z-index: 2;
    }
    padding:0 !important;
    img {
        position: relative;
        width: auto;
        height: rem(700);

        @media all and (min-width: $md-breakpoint) {
            width: 100%;
            height: auto;
        }
    }
    .owl-theme{
        .owl-stage-outer {
            padding:0;
        }
        .banner-item {
            margin:0 !important;
            > a {
                position: relative;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 12;
                display: block;
                width: 100%;
            }
        }

        &.owl-carousel{
            .owl-item{
                overflow:hidden;

                .banner-title {
                    position: absolute;
                    bottom: 10%;
                    font-family: $helvetica-bold;
                    font-size: rem(20);
                    text-transform: none;
                    @media all and (min-width:$md-breakpoint){
                        bottom: 15%;
                        font-size: rem(24);
                    }
                }

                .banner-image{
                    @media all and (max-width: $sm-breakpoint) {
                        order: 2;
                    }

                    display:block;
                    overflow:hidden;

                    &.banner-video-background{
                        position:relative;
                        display:block;

                        @media all and(min-width:$md-breakpoint){
                            display:none;
                        }

                        a{
                            position:absolute;
                            top:50%;
                            left:50%;
                            z-index:10;
                            transform:translate(-50%, -50%);

                            &:after{
                                top:rem(19);
                            }

                            @media all and(max-width:$md-breakpoint){
                                color: $white;
                                background-color: $light-blue;
                                @include triangle($white);
                            }

                        }
                    }
                }
                .banner-video {
                    @media all and (max-width: $sm-breakpoint) {
                        order: 2;
                    }

                    position: relative;
                    display:none;
                    height: 0;
                    /*padding-top: 35px;*/
                    padding-bottom: 56.25%;
                    /*padding-bottom: 53.5%;*/
                    overflow: hidden;

                    @media all and(min-width:$md-breakpoint){
                        display:block;
                    }

                    iframe {
                        position: absolute;
                        top:0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
                .banner-video-protection{
                    @media all and (max-width: $sm-breakpoint) {
                        order: 2;
                    }

                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    z-index:100;
                    background:transparent;
                }
                img {
                    position: relative;

                    @media all and (min-width: $md-breakpoint) {
                        left:0;
                        width: 100%;
                        transform:none;
                    }
                }
            }

            // Update Styles for better theming.
            .banner-item {
                @media all and (max-width: $sm-breakpoint) {
                    display: flex;
                    flex-flow: row wrap;
                }
                position: relative;

                .content_slider {
                    top:0;
                    right: 0;
                    bottom:0;
                    left:0;
                    width: 100%;

                    .button-content {
                        @include container($container, $gutter);

                        @media all and (max-width: $sm-breakpoint) {
                            justify-content: flex-start !important;
                        }

                        @media all and (max-width: $md-breakpoint) {
                            padding-top: rem(40);
                        }

                        position: relative;
                        top: 0;
                        bottom: 0;
                        display: flex;
                        align-items: flex-start;
                        flex-flow: row nowrap;
                        height: 100%;
                        padding-top: $full-header-height;
                        margin: auto;

                        // Default Theme
                        .custom_content {
                            position: relative;
                            width: rem(387);
                            height: rem(387);
                            padding: rem(38) rem(40);
                            font-family: $helvetica;
                            font-size: rem(14);
                            line-height: 1.57;
                            border: 4px solid $white;

                            h1, h2, h3, h4, h5, h6 {
                                padding: 0;
                                margin: 0;
                                font-family: $helvetica-bold;
                                font-weight: 500;
                            }

                            p {
                                margin: rem(30) 0;
                            }

                            .custom_links {
                                a {
                                    @include triangle($white);

                                    display: block;
                                    font-family: $helvetica-bold;
                                    font-weight: 300;
                                    transition:all 0.3s;

                                    &:after {
                                        position: absolute;
                                        right: auto;
                                        margin-left: rem(20);
                                        transition:all 0.3s;

                                        @media all and (min-width: 450px) {
                                            //right: rem(190);
                                            //left:auto;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // content_slider.right
                    &.right .button-content {
                        justify-content: flex-end;
                    }

                    // content_slider.left
                    &.left .button-content {
                        justify-content: flex-start;
                    }

                    // THEMES for Slider content box

                    // content_slider.blue-box
                    &.blue-box {

                        .custom_content > p {
                            @media all and (max-width: $md-breakpoint) {
                                display: none;
                            }
                        }

                        .custom_text {
                            @media all and (max-width: $md-breakpoint) {
                                display: none;
                            }
                            position: absolute;
                            top: 0;
                            left: 100%;
                            display: block;
                            width: 170%;
                            padding: rem(40) 0 0 rem(40);
                        }

                        .custom_links {
                            @media all and (max-width: $md-breakpoint) {
                                left: 0;
                            }

                            position: absolute;
                            bottom: 50%;
                            left: 100%;
                            display: block;
                            width: 80%;
                            margin-left: rem(20);

                            a {
                                padding:rem(30) rem(20);
                                color:$white;
                                font-size: rem(14);
                                text-decoration: none;
                                transition:all 0.3s;

                                &:after {
                                    top:rem(34);
                                }
                            }

                            a.nearest_store_link {
                                display: none;
                            }
                        }

                        &:before {
                            @media all and (max-width: $md-breakpoint) {
                                top: 0 !important;
                            }
                            position: absolute;
                            top: rem(97);
                            left: -50%;
                            width: 200%;
                            height: rem(250);
                            background: rgba(0, 103, 155, 0.9);
                            content: '';
                        }
                    }

                    // content_slider.white
                    &.white {

                        .custom_content > p {
                            @media all and (max-width: $md-breakpoint) {
                                //display: none;
                            }
                        }

                        .custom_links {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            display: block;
                            width: 100%;
                            margin: rem(-80) 0;
                            background: $blue;

                            a {
                                padding:rem(30) rem(20);
                                color:$white;
                                font-size: rem(14);
                                text-decoration: none;
                                transition:all 0.3s;

                                &:after {
                                    top:rem(34);
                                }
                            }

                            a.nearest_store_link {
                                display: none;
                            }
                        }

                        &:before {
                            @media all and (max-width: $md-breakpoint) {
                                top: 0 !important;
                            }
                            position: absolute;
                            top: rem(97);
                            left: -50%;
                            width: 200%;
                            height: rem(250);
                            background: rgba(0, 103, 155, 0.9);
                            content: '';
                        }
                    }

                    // content_slider.blue
                    &.blue {

                        .button-content {
                            align-items: flex-end;
                            padding-bottom: rem(250);
                        }

                        .custom_content {
                            height: rem(387);
                            color: $blue;
                            border: 4px solid rgba(0, 103, 155, 0.9);

                            h1, h2, h3, h4, h5, h6 {
                                font-size: rem(14) !important;
                            }

                            p {
                                margin: rem(20) 0;
                                font-size: rem(32);
                                font-weight: 300;
                                line-height: 1.19;
                            }

                            .custom_links {
                                position: absolute;
                                top: 100%;
                                left: 0;
                                width: calc(100% + 8px);
                                margin: 0 rem(-4);

                                a {
                                    display: block;
                                    height: rem(60);
                                    padding:rem(20) rem(30);
                                    margin-top: rem(2);
                                    color: $white;
                                    font-size: rem(14);
                                    text-decoration: none;
                                    background: $blue;

                                    @media all and (min-width: $md-breakpoint) {
                                        color:$blue;
                                        background:transparent;
                                        @include triangle($blue);
                                    }

                                    &:after {
                                        right: auto;
                                        margin: rem(3) 0 0 rem(20);
                                        border-left-color: $white !important;
                                    }

                                    &.detail_link {
                                        position: absolute;
                                        top: rem(-80);
                                        width: 100%;
                                        height: rem(80);
                                        padding: rem(30) rem(30);
                                        //text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

                                        &:after {
                                            border-left-color: $blue !important;
                                        }

                                        &:hover {
                                            color: $white;
                                            text-shadow: none;
                                            background: $blue;
                                        }
                                    }

                                    &.nearest_store_link {
                                        @media all and (max-width: 400px) {
                                            width: 100%;
                                        }
                                        width: rem(387);
                                        padding: rem(20) rem(30);
                                        margin-top: rem(60);
                                        color: $white;
                                        background: rgba(0, 103, 155, 1) !important;

                                        &:after {
                                            right: rem(20);
                                            left: auto;
                                            border-left-color: $white !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // content_slider.blue-box-solid
                    &.blue-box-solid {
                        @media all and (max-width: $sm-breakpoint) {
                            position: static;
                        }

                        .button-content {
                            @media all and (max-width: $sm-breakpoint) {
                                padding: 0;
                            }

                            @media all and (min-width: $md-breakpoint) {
                                padding-bottom: rem(240);

                                .custom_content {
                                    height: auto !important;
                                }
                            }
                            @media all and (min-width: $lg-breakpoint) {
                                padding-bottom: rem(250);
                            }
                            @media all and (min-width: $xlg-breakpoint) {
                                padding-bottom: rem(300);
                            }
                            align-items: flex-end;
                            padding-bottom: rem(250);
                        }

                        .custom_content {

                            @media all and (max-width: $sm-breakpoint) {
                                width: 100%;
                                height: 100%;
                            }

                            height: rem(323);
                            color: $white;
                            background: rgba(0, 103, 155, 0.9);
                            border: none;

                            h1, h2, h3, h4, h5, h6 {
                                font-size: rem(14) !important;
                            }

                            p {
                                @media all and (max-width: $sm-breakpoint) {
                                    font-size: rem(24);
                                }
                                margin: rem(20) 0;
                                font-family: $helvetica-light;
                                font-size: rem(32);
                                font-weight: 300;
                                line-height: 1.19;
                            }

                            .custom_links {
                                position: absolute;
                                top: 100%;
                                left: 0;
                                width: 100%;
                                margin-top: rem(0);

                                a {
                                    display: block;
                                    height: rem(60);
                                    padding:rem(20) rem(30);
                                    margin-top: rem(2);
                                    color: $white !important;
                                    font-size: rem(14);
                                    text-decoration: none;
                                    //text-shadow: 0 0 rem(4) $dark-grey;
                                    background: rgba(0, 103, 155, 0.9) !important;

                                    @media all and (min-width: $md-breakpoint) {
                                        color:$blue;
                                        background:transparent;
                                        @include triangle($blue);
                                    }

                                    &:after {
                                        right: rem(20);
                                        margin: rem(3) 0 0;
                                        border-left-color: $white !important;
                                    }

                                    &:hover {
                                        background-color: rgba(156, 97, 110, 0.9) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .owl-dots{
            position: absolute;
            left: 50%;
            width: auto;
            padding: 0;
            margin: 0;
            overflow:hidden;
            transform: translate(-50%, 0);

            .owl-dot {
                display:inline-block;
                padding:0 rem(4);

                span {
                    width: rem(12);
                    height: rem(12);
                    padding: 0;
                    margin: rem(12) 0;
                    font-size: 0;
                    line-height: 1;
                    background-color: $white;
                    border: none;
                    border-radius: 50%;
                    opacity: 1;

                    &:hover {
                        cursor: pointer;
                    }
                    &:focus {
                        outline: none;
                    }
                }
                &.active {
                    span {
                        background-color: $bright-blue;
                        opacity: 1;
                    }
                }
            }
        }
        .owl-controls .owl-nav {
            [class*=owl-] {
                background-color: $bright-blue;
                border-radius: 50%;
                &.owl-next {
                    &:before {
                        top: rem(16) !important;
                        right: rem(12) !important;
                        width: rem(12) !important;
                        border-color: $white !important;
                        transform: rotate(45deg) !important;
                    }
                    &:after {
                        top: rem(24) !important;
                        right: rem(12) !important;
                        width: rem(12) !important;
                        border-color: $white !important;
                        transform: rotate(-45deg) !important;
                    }
                }
                &.owl-prev {
                    &:before {
                        top: rem(16) !important;
                        left: rem(12) !important;
                        width: rem(12) !important;
                        border-color: $white !important;
                        transform: rotate(-45deg) !important;
                    }
                    &:after {
                        top: rem(24) !important;
                        left: rem(12) !important;
                        width: rem(12) !important;
                        border-color: $white !important;
                        transform: rotate(45deg) !important;
                    }
                }
                &:hover {
                    background-color: darken($bright-blue, 10%);
                }
            } 
        }
    }
}

#cssload-pgloading {
    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        content: '';
    }
    .cssload-loadingwrap {
        position:absolute;
        top:45%;
        right:25%;
        bottom:45%;
        left:25%;
    }
    .cssload-bokeh{
        position: relative;
        width: 1em;
        height: 1em;
        padding:0;
        margin: 0 auto;
        font-size: 97px;
        list-style: none;
        border-radius: 50%;
    }
    .cssload-bokeh li{
        position: absolute;
        width: 0.2em;
        height: 0.2em;
        border-radius: 50%;
    }
    .cssload-bokeh li:nth-child(1){
        top: 0;
        left: 50%;
        margin: 0 0 0 -0.1em;
        background: $bright-blue;
        transform-origin: 50% 250%;
        animation: cssload-rota 1.3s linear infinite, cssload-opa 4.22s ease-in-out infinite alternate;
    }
    .cssload-bokeh li:nth-child(2){
        top: 50%;
        right: 0;
        margin: -0.1em 0 0 0;
        background: $light-blue;
        transform-origin: -150% 50%;
        animation: cssload-rota 2.14s linear infinite, cssload-opa 4.93s ease-in-out infinite alternate;
    }
    .cssload-bokeh li:nth-child(3){
        bottom: 0;
        left: 50%;
        margin: 0 0 0 -0.1em;
        background: $blue;
        transform-origin: 50% -150%;
        animation: cssload-rota 1.67s linear infinite, cssload-opa 5.89s ease-in-out infinite alternate;
    }
    .cssload-bokeh li:nth-child(4){
        top: 50%;
        left: 0;
        margin: -0.1em 0 0 0;
        background: $light-grey;
        transform-origin: 250% 50%;
        animation: cssload-rota 1.98s linear infinite, cssload-opa 6.04s ease-in-out infinite alternate;
    }

}
@keyframes cssload-rota {
    from { }
    to
    { transform: rotate(360deg); }
}

@keyframes cssload-opa {
    0% { }
    12.0%
    { opacity: 0.8; }
    19.5%
    { opacity: 0.88; }
    37.2%
    { opacity: 0.64; }
    40.5%
    { opacity: 0.52; }
    52.7%
    { opacity: 0.69; }
    60.2%
    { opacity: 0.6; }
    66.6%
    { opacity: 0.52; }
    70.0%
    { opacity: 0.63; }
    79.9%
    { opacity: 0.6; }
    84.2%
    { opacity: 0.75; }
    91.0%
    { opacity: 0.87; }
}
@-o-keyframes cssload-opa {
    0% { }
    12.0%
    { opacity: 0.8; }
    19.5%
    { opacity: 0.88; }
    37.2%
    { opacity: 0.64; }
    40.5%
    { opacity: 0.52; }
    52.7%
    { opacity: 0.69; }
    60.2%
    { opacity: 0.6; }
    66.6%
    { opacity: 0.52; }
    70.0%
    { opacity: 0.63; }
    79.9%
    { opacity: 0.6; }
    84.2%
    { opacity: 0.75; }
    91.0%
    { opacity: 0.87; }
}
@-ms-keyframes cssload-opa {
    0% { }
    12.0%
    { opacity: 0.8; }
    19.5%
    { opacity: 0.88; }
    37.2%
    { opacity: 0.64; }
    40.5%
    { opacity: 0.52; }
    52.7%
    { opacity: 0.69; }
    60.2%
    { opacity: 0.6; }
    66.6%
    { opacity: 0.52; }
    70.0%
    { opacity: 0.63; }
    79.9%
    { opacity: 0.6; }
    84.2%
    { opacity: 0.75; }
    91.0%
    { opacity: 0.87; }
}
@-webkit-keyframes cssload-opa {
    0% { }
    12.0%
    { opacity: 0.8; }
    19.5%
    { opacity: 0.88; }
    37.2%
    { opacity: 0.64; }
    40.5%
    { opacity: 0.52; }
    52.7%
    { opacity: 0.69; }
    60.2%
    { opacity: 0.6; }
    66.6%
    { opacity: 0.52; }
    70.0%
    { opacity: 0.63; }
    79.9%
    { opacity: 0.6; }
    84.2%
    { opacity: 0.75; }
    91.0%
    { opacity: 0.87; }
}
@-moz-keyframes cssload-opa {
    0% { }
    12.0%
    { opacity: 0.8; }
    19.5%
    { opacity: 0.88; }
    37.2%
    { opacity: 0.64; }
    40.5%
    { opacity: 0.52; }
    52.7%
    { opacity: 0.69; }
    60.2%
    { opacity: 0.6; }
    66.6%
    { opacity: 0.52; }
    70.0%
    { opacity: 0.63; }
    79.9%
    { opacity: 0.6; }
    84.2%
    { opacity: 0.75; }
    91.0%
    { opacity: 0.87; }
}