.cms-comfort-club {
    p {
        font-family: $helvetica-light;
        font-size: rem(14);
        font-weight: 100;
        line-height: 1.5;
    }

    .custom-slider img {
        height: auto;
    }

    .comfort-club-signup {
        padding: rem(32);
        margin-bottom: rem(32);
        form {
            @include container($container, $gutter);
            @media (max-width: $xs-breakpoint) {
                @include container($xs-container, $xs-gutter);
            }
        }
        .control {
            margin-bottom: rem(16);
        }
        .input-text {
            border: 2px solid $light-blue;
            &.select {
                padding: rem(20) rem(24) rem(12);
            }
        }
        input[type='checkbox'] {
            float: left;
            margin-right: rem(22);
            margin-bottom: rem(22);
            &:after {
                position: absolute;
                display: block;
                width: rem(24);
                height: rem(24);
                margin: rem(-2) 0 0 0;
                cursor: pointer;
                background: $white;
                border: 2px solid $light-blue;
                border-radius: 0;
                content: '';
                transition: all 0.4s;
            }

            &:hover:after {
                background: $light-blue;
            }

            &:checked:after {
                background: $light-blue;
            }

        }

        label {
            color: $dark-grey2;
            font-size: rem(14);
            cursor: pointer;
            a {
                color: $light-blue;
            }
            .asterisk {
                color: $error-color;
            }
        }

        .field-error {
            display: flex;
            flex-wrap: wrap;
            border: none;
            label {
                flex-basis: 85%;
                order: 1;
                padding-top: rem(2);
            }
            .fv_inline_error {
                flex-basis: 100%;
                order: 2;
            }
            #captcha_status-error {
                flex-basis: rem(300);
                margin-top: rem(12) !important;
            }
        }

        .g-recaptcha {
            width: rem(300);
        }

        #captcha_status {
            position: absolute;
        }

        .input-group {
            &.field-error {
                .fv_inline_error {
                    margin-top: rem(12) !important;
                }
            }
        }
        
        @media all and (min-width: $sm-breakpoint) {
            .fieldset {
                display: flex;
                justify-content: space-between;
            }
        }
        .btn--dark-blue-solid {
            height: rem(60);
            @media all and (max-width: $sm-breakpoint) {
                width: 100%; 
            }
        }
    }
}