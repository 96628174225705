.coloured-blocks {
    @include container($container, 0);
    position: relative;
    z-index: 8;
    text-align: left;
    
    @media all and (min-width: $sm-breakpoint) {
        margin-top: rem(-80);
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        article {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            min-height: rem(100);
            color: $white;
            opacity: 0.9;
            @media all and (min-width: $sm-breakpoint) {
                align-items: flex-start;
                width: 33.33%;
                min-height: rem(387);
                padding: rem(90) rem(26) rem(80);
            }
            &:nth-of-type(1) {
                background-color: $light-blue;
            }
            &:nth-of-type(2) {
                background-color: $rose;
            }
            &:nth-of-type(3) {
                background-color: $olive;
            }
        }
    }
    &__text {
        display: none;
        font-size: rem(14);
        line-height: 1.57;
        @media all and (min-width: $sm-breakpoint) {
            display: block;
        }
        h1,
        h2{
            margin-top: 0;
            margin-bottom: rem(32);
            font-family: $helvetica-light;
            font-size: rem(32);
            font-weight: 300;
            line-height: 1.31;
        }
    }
    &__link {
        width: 100%;
        padding-left: rem(26);
        font-family: $helvetica-bold;
        font-size:rem(14);
        text-decoration: none;
        transition: all 0.4s;
        @include triangle($white);

        @media all and (min-width: $sm-breakpoint) {
            position: absolute;
            bottom: 0;
            left: 0;
            line-height: rem(80);
        }
        &:after {
            position: absolute;
            top: 50%;
            right: rem(20);
            margin-top: rem(-5);
        }
        &:hover {
            background-color: rgba($white, 0.12);
        }
        &--desktop {
            display: none;
            @media all and (min-width: $sm-breakpoint) {
                display: block;
            }
        }
        &--mobile {
            display: block;
            padding: rem(28);
            @media all and (min-width: $sm-breakpoint) {
                display: none;
            }
            p {
                display: none;
            }
            h1 {
                font-family: $helvetica-light;
                font-size: rem(18);
                font-weight: 300;
            }
        }
    }
}