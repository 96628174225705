.sitemap-index-index {
    .page-main {
        @extend .container;
        @media all and (max-width: $sm-breakpoint) {
            padding-bottom: rem(112);
        }
    }

    .page-title-wrapper {
        margin-top: rem(85);
        margin-bottom: rem(60);
    }

    .mpcss {
        @extend .container;
        margin-bottom: rem(135);

        .row {
            padding: rem(25) 0;
            border-top: 1px solid rgba($black, 0.05);
            border-bottom: 1px solid rgba($black, 0.05);
        }
    }

    .footer-coloured-blocks {
        display: none;
    }
}

.mp-sitemap-listing {
    padding: 0;
    margin: 0;
    columns: 1;

    li {
        display: block;

        a {
            color: $light-blue;
            font-family: $helvetica;
            font-size: rem(14);
            line-height: 1.57;
        }
    }
}

.sitemap-title {
    margin-top: 0;
    margin-bottom: rem(35);
    color: $light-blue;
    font-family: $helvetica-light;
    font-size: rem(18);
}