input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
input[type='color']:focus,
input[type='date']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='email']:focus,
input[type='month']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='text']:focus,
input[type='time']:focus,
input[type='url']:focus,
input[type='week']:focus,
select:focus,
textarea {
    font-size: rem(14);
}

// IE fix to hide default dropdown arrow.
select::-ms-expand {
    display: none;
}

.fieldset {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + #{rem(20)});
    padding: 0;
    margin: 0 rem(-10) 0 rem(-10);
    border: 0;
}

.field {
    position: relative;
    width: calc(100% - #{rem(20)});
    margin: 0 rem(10) rem(10) rem(10);

    &.half-input {
        @media all and (min-width: $sm-breakpoint) {
            width: calc(50% - #{rem(20)});
        }
    }

    &.one-third-input {
        @media all and (min-width: $sm-breakpoint) {
            width: calc(33.33% - #{rem(20)});
        }
    }

    &.two-third-input {
        @media all and (min-width: $sm-breakpoint) {
            width: calc(66.66% - #{rem(20)});
        }
    }

    &.field-error {
        border: 1px solid $error-color;
    }
}

.legend {
    width: 100%;
    margin: rem(70) rem(10) rem(30) rem(10);
    font-family: $helvetica-light;
    font-size: rem(24);
}

.nested {
    margin: rem(10) rem(-10) 0 rem(-10);
}

.captcha-image {
    text-align: left;

    img {
        margin-right: rem(10);
        margin-bottom: rem(10);
        vertical-align: top;
    }
}

div.mage-error,
div.validation-advice,
#mc_embed_signup div.mce_inline_error {
    padding: rem(10) !important;
    margin: 0 !important;
    color: $white !important;
    font-size: rem(14);
    font-weight: $normal !important;
    text-align: left;
    background-color: $error-color !important;

    .recaptcha & {
        width: rem(302);
    }
}

input.mage-error, input.validation-failed, input.mce_inline_error, textarea.validation-failed {
    display:block;
    border: 1px solid $error-color;
}

.actions-toolbar {
    max-width: 100%;
}

.control {
    position: relative;

    .fv_inline_error {
        display: none;
    }

    &.field-error {
        border: 1px solid $error-color;

        .fv_inline_error {
            padding: rem(10) !important;
            margin: 0 !important;
            color: $white !important;
            font-size: rem(14);
            font-weight: $normal !important;
            text-align: left;
            background-color: $error-color !important;
        }
    }
    &.field {
        label.error {
            display: block !important;
            padding: rem(10) !important;
            margin: 0 !important;
            color: $white !important;
            font-size: rem(14);
            font-weight: $normal !important;
            text-align: left;
            background-color: $error-color !important;
        }
        input.error {
            border: 1px solid $error-color;
            
        }
    }
}

label.label {
    position: absolute;
    top: rem(20);
    left: rem(25);
    color: $placeholder-color;
    font-size: rem(14);
    pointer-events: none;
    transition: all 0.4s ease;
}

input[type=text]::-ms-clear {
    display: none !important;
}

.input-text {
    width: 100%;
    height: rem(60);
    padding: rem(27) rem(25) rem(16);
    font-family: $helvetica-bold;
    font-size: rem(14);
    border: 0;
    border-radius: 0;

    &:focus, &.filled {
        ~ .label {
            top: rem(10);
            font-size: rem(10);
        }
    }
}

//.select-wrapper {
//    overflow: hidden;
//    background: $white url('../images/arrow-grey.svg') no-repeat center right rem(30);
//
//    select {
//        width: 110%; /* for Firefox fix */
//        background: transparent;
//
//        // to remove outline from Firefox.
//        &:-moz-focusring {
//            color: transparent;
//            text-shadow: 0 0 0 #000000;
//        }
//    }
//}

.select-wrapper {
    position: relative;
    background: $white url('../images/arrow-grey.svg') no-repeat center right rem(30);

    @-moz-document url-prefix('') {
        &:after {
            position: absolute;
            top: 0;
            left: 100%;
            display: block;
            width: 20px;
            height: 100%;
            margin: 0 0 0 rem(-20);
            background: $white;
            content: '';
        }
    }

    select {
        width: 100%;
        padding: rem(22) rem(24);
        font-family: $helvetica-bold;
        font-size: rem(14);
        background: transparent;
        border: none;
        border-radius: 0;
        appearance: none;

        option {
            color: $rose;
            background: $white;
        }

        &:focus {
            outline: none;
        }
        &::-ms-expand {
            display: none; // Hide arrow in IE
        }

        // to remove outline from Firefox.
        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000000;
        }
    }
}

select.required-entry, select.form-control {
    width: 100%;
    height: rem(60);
    padding: rem(20) rem(25) rem(20);
    font-family: $helvetica-bold;
    font-size: rem(14);
    border: 0;
    border-radius: 0;
    appearance: none;

    &.mce_inline_error {
        border: 1px solid $error-color;
    }

    ~ label {
        position: absolute;
        top: rem(30);
        right: rem(25);
        left: auto;
        width: 0;
        height: 0;
        overflow: hidden;
        font-size: 0;
        border-top: 5px solid $black;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        content: '';
        transform: translate(0, -50%);
    }

    &:focus option {
        //background: $blue;
    }
}

textarea.input-text {
    height: rem(370);
    resize: none;

    @media all and (min-width: $sm-breakpoint) {
        height: rem(200);
    }

    &.validation-failed {
        margin-bottom: rem(-8);
    }
}