
/*------------------------------------*\
    NAVIGATION STORELOCATOR
\*------------------------------------*/
.switcher {
    &.switcher-store,
    &.switcher-language {
        display:none;
    }
}
.slocator-nav-wrapper {
    position: relative;
    z-index: 20;
    display: none;
    @media all and (min-width: $xlg-breakpoint) {
        @include container($xl-container, $gutter);
    }
    @media all and (max-width: $md-breakpoint - 1) {
        display: block;
    }
    .pref-page-content {
        display: none;
    }
}

.slocator-nav {
    @media all and (min-width: $md-breakpoint) {
        position: absolute;
        right: 0;
    }

    @media all and (min-width: $lg-breakpoint) {
        right: rem(64);
    }

    &:before {
        // @media all and (max-width: $md-breakpoint - 1) {
        //     position: absolute;
        //     top: 0;
        //     right: calc(#{$header-nav-storelocator} + #{rem(4)});
        //     z-index: 30;
        //     width: 1px;
        //     height: $header-nav-storelocator;
        //     background: #FFFFFF;
        //     content: '';
        // }
    }
    &.restricted-popup {
        .slocator-nav-content {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 450px;
            height: 300px;
            margin: auto;
            @media all and (max-width: $sm-breakpoint) {
                width: 90vw;
            }
            @media all and (min-width: $lg-breakpoint) {
                width: 450px;
            }
        }
        .slocator-nav-btn {
            display: none;
        }
    }

    &-btn {
        position: relative;
        z-index: 20;
        display: block;
        padding: rem(11) 0 rem(11) rem(24);
        color: $mid-grey;
        font-size: rem(12);
        text-transform: uppercase;
        background: #EBEBEB;
        transition: all 0.2s;
        .city {
            font-family: $helvetica-bold;
            @media all and (min-width: $md-breakpoint) {
                margin-top: -0.4rem;
            }
        }
        .address {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            @media all and (max-width: $sm-breakpoint) {
                max-width: 65vw;
            }
            @media all and (min-width: $md-breakpoint) {
                max-width: 100px;
            }
            @media all and (min-width: $lg-breakpoint) {
                max-width: 155px;
            }
        }
        @media all and (min-width: $md-breakpoint) {
            width: rem(190);
            padding: rem(20) rem(12);
            background: #FFFFFF;
            border-top: 5px solid #0070AF;
        }

        @media all and (min-width: $lg-breakpoint) {
            width: rem(250);
            padding: rem(20);
        }

        &:before {
            float: left;
            width: rem(24);
            margin-top: - rem(8);
            margin-right: rem(4);
            color: $bright-blue;
            font-family: icomoon;
            font-size: rem(24);
            content: '\e91c';
            transition: all 0.2s;

            @media all and (min-width: $md-breakpoint) {
                width: rem(32);
                margin-top: rem(-13);
                margin-right: rem(2);
                font-size: rem(32);
            }

            @media all and (min-width: $lg-breakpoint) {
                margin-top: rem(-13);
                margin-right: rem(8);
            }
        }

        // &:after {
        //     position: absolute;
        //     top: 47%;
        //     right: - rem(15);
        //     width: $header-nav-storelocator;
        //     height: $header-nav-storelocator;
        //     color: $light-blue;
        //     font-family: icomoon;
        //     font-size: rem(22);
        //     line-height: $header-nav-storelocator;
        //     text-align: center;
        //     content: '\e916';
        //     transform: translate(-50%, -50%);
        //     transition: all 0.2s;
        //     @media all and (min-width: $md-breakpoint) {
        //         right: 0;
        //         width: initial;
        //         height: initial;
        //         // margin-top: -0.2rem;
        //     }
        // }

        &.active {
            background: #F2F2F2;

            &:after {
                transform: translate(-50%, -50%) rotate(180deg);
            }
        }
    }

    .slocator-nav-content {
        position: absolute;
        right: 0;
        width: 100%;
        color: $mid-grey;
        background: #FFFFFF;

        @media all and (min-width: $sm-breakpoint) {
            width: rem(424);
        }
    }

    .slocator-nav-content-cotainer {
        z-index: 10;
        display: none;

        &:before {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            display: block;
            background-color: rgba(0, 0, 0, 0.5);
            content: '';
        }
        &.default {
            &:before {
                background: url('../images/popup_bg.png') no-repeat top center;
                background-color: rgba(0, 0, 0, 0.5);
            }
            .pref-tabs-btn,
            .pref-tabs-title {
                font-family: $helvetica;
                font-size: rem(18);
                background: $bright-blue;
                &.default {
                    display: inline-block;
                    width: 100%;
                    padding: 1.25rem 1rem;
                    color: $white;
                    text-transform: none;
                    white-space: nowrap;
                    pointer-events: none;
                    background: $bright-blue;
                    > div {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    img {
                        width: 200px;
                        margin-left: rem(16);
                    }
                    @media all and (max-width: $xs-breakpoint) {
                        display: block;
                        >div {
                            display: block;
                            vertical-align: middle;
                        }
                        img {
                            margin-left: 0;
                        }
                    }
                }
            }
            .pref-tabs-content {
                background: $white;
            }
            .pref-locations {
                max-height: 200px;
                overflow-y: scroll;
            }
            .pref-error {
                display: none !important;
            }
        }
        
    }

    /*------------------------------------*\
        TABS
    \*------------------------------------*/

    .pref-tabs {
        &-title {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-btn {
            width: 50%;
            padding: rem(20) 0 rem(22);
            color: #BBCDD6;
            font-family: $helvetica-bold;
            font-size: rem(15);
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            background: $light-blue;
            transition: all 0.3s;

            &:hover {
                color: #FFFFFF;
                background-color: darken($light-blue, 10%);
            }

            &.active {
                color: $mid-grey;
                background: #FFFFFF;

                &:hover {
                    background: #FFFFFF;
                }
            }
        }

        &-content {
            display: none;
            padding: rem(26) rem(38) rem(38);
            font-size: rem(14);

            &.active {
                display: block;
            }
        }
    }

    .pref-locations {
        max-height: 225px;
        overflow: auto;
    }
}

/*------------------------------------*\
    STORE DETAILS
\*------------------------------------*/

.pref-tabs-details {
    .address {
        display: block;
        width: rem(230);
    }

    .btn {
        display: block;
        width: 100%;
        padding-top: rem(13);
        padding-bottom: rem(14);
        font-size: rem(15);
        text-align: center;

        &:first-child {
            margin-bottom: rem(21);
        }
    }

    &-buttons {
        margin-top: rem(21);
    }

    p {
        margin-top: 0;
        margin-bottom: rem(24);
        line-height: 1.5;
    }

    .email {
        color: $light-blue;
    }
}

/*------------------------------------*\
    SEARCH
\*------------------------------------*/
.pref-search {
    position: relative;

    input {
        width: 100%;
        height: 45px;
        padding: rem(12) rem(20);
        font-size: rem(12);
        border: 2px solid $light-blue;
        border-radius: 0;
        box-shadow: none;
        outline: none;
        appearance: none;


        &:focus {
            font-size: rem(12);
        }
    }

    .btn {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        height: 45px;
        padding: 0 rem(12);
        background: #FFFFFF;
        &:hover {
            background: $light-blue;
            &:before {
                color: #FFFFFF;
            }
        }

        &:before {
            @include icon();
            display: inline-block;
            color: $light-blue;
            font-size: rem(21);
            content: '\e916';
            transform: rotate(-90deg);
            transition: all 0.3s;
        }
    }
}

/*------------------------------------*\
    CHANGE STORE
\*------------------------------------*/

.pref-tabs-change {
    h4 {
        font-family: $helvetica;
        font-size: rem(18);

        small {
            display: block;
            margin-top: rem(13);
            color: lighten($mid-grey, 10%);
            font-size: 70%;
        }

        a {
            text-decoration: underline;
        }
    }
}

.pref-location {
    position: relative;
    padding: rem(18) 100px rem(18) 0;
    border-top: 1px solid #E7E7E7;

    &:last-child {
        border-bottom: 1px solid #E7E7E7;
    }

    .btn {
        position: absolute;
        top: 50%;
        right: 0;
        width: auto;
        padding: rem(12) rem(18);
        font-size: rem(12);
        transform: translateY(-50%);
    }

    .city {
        font-family: $helvetica-bold;
        font-size: rem(15);
        line-height: rem(20);
        text-transform: uppercase;
    }

    .distance {
        color: $light-blue;
    }
}
