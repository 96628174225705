.catalog-topnav {
    @media all and (min-width: $md-breakpoint) {
        @include container($xl-container, $gutter);
        margin-bottom: rem(100);
        text-align: center;
    }

    .filter-options {
        @media all and (min-width: $md-breakpoint) {
            background: #F0F4F6;
        }
    }

    .filter-title {
        @media all and (min-width: $md-breakpoint) {
            display: none;
        }
    }

    .filter-subtitle {
        @media all and (min-width: $md-breakpoint) {
            display: none;
        }
    }

    .filter-actions {
        display: none;
    }

    .filter-options-item {
        @media all and (min-width: $md-breakpoint) {
            display: inline-block;
        }

        &.active {
            .filter-options-title {
                &:after {
                    transform: scaleY(-1);
                }
            }
        }
    }

    .filter-options-title {
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: pointer;

        @media all and (min-width: $md-breakpoint) {
            padding: rem(20) rem(30);
        }

        &:after {
            @include icon();
            position: relative;
            top: 3px;
            display: inline-block;
            padding-left: rem(12);
            color: $light-blue;
            font-size: rem(21);
            content: '\e916';
            transition: all 0.2s;
        }
    }

    .filter-options-content {
        display: none;

        @media all and (min-width: $md-breakpoint) {
            position: absolute;
            z-index: 9;
            min-width: 100px;
            padding: rem(18) rem(30);
            text-align: left;
            background-color: $white;
            border: 1px solid rgba(240, 244, 246, 0.15);
            box-shadow: 0 1px 12px rgba(0, 0, 0, 0.175);

            &:before {
                position: absolute;
                top: -10px;
                left: 50%;
                width: 0;
                height: 0;
                border-right: 11px solid transparent;
                border-bottom: 11px solid #FFFFFF;
                border-left: 11px solid transparent;
                content: '';
                transform: translate(-50%, 0);
            }
        }

        .item {
            position: relative;
        }

        a {
            position: relative;
            display: block;
            padding: rem(7) rem(0) rem(7) rem(28);

            &.am_shopby_link_selected, 
            &:hover {
                input[type='radio'],
                input[type='checkbox'] {
                    &:after {
                        background: $light-blue;
                    }
                }
            }
            input[type='radio']:checked,
            input[type='checkbox']:checked {
                &:after {
                    background: $light-blue;
                }
            }
        }
    }

    ul,
    ol{
        padding: 0;
        margin: 0;
        list-style: none;
    }

    input[type='radio'],
    input[type='checkbox'] {
        position: absolute;
        top: 7px;
        left: 0;

        &:before,
        &:after {
            position: absolute;
            top: 0;
            left: -2px;
            display: inline-block;
            width: 18px;
            height: 18px;
            pointer-events: none;
            background: #FFFFFF;
            content: '';
            transition: all 0.3s;
        }

        &:after {
            border: 1px solid $light-blue;
            border-radius: 50%;
        }
        
        &:checked {
            &:after {
                background: $light-blue;
            }
        }
    }

    .swatch-option-link-layered {
        padding: rem(7) rem(0) rem(7) rem(28) !important;
    }
}

@media all and (max-width: $md-breakpoint - 1) {
    .filter {
        &:before {
            position: fixed;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: #000000;
            content: '';
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
        }

        &.active {
            position: relative;
            z-index: 99;
            visibility: visible;

            &:before {
                opacity: 0.1;
                visibility: visible;
            }

            .filter-title {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 100;
                width: 80%;
                height: 70px;

                strong {
                    color: transparent;
                    cursor: pointer;

                    &:after {
                        @include icon();
                        position: absolute;
                        top: 50%;
                        right: rem(30);
                        color: #000000;
                        font-size: rem(14);
                        content: '\e90f';
                    }
                }
            }

            .filter-content {
                display: block;
            }
        }

        .filter-subtitle {
            display: block;
            padding-bottom: rem(30);
            color: $light-blue;
            font-family: $helvetica-light;
            font-size: rem(22);
            letter-spacing: 1px;
        }

        .filter-content {
            position: fixed;
            top: 0;
            right: auto;
            bottom: 0;
            left: 0;
            z-index: 10;
            display: none;
            width: 80%;
            padding: rem(30);
            overflow-x: hidden;
            overflow-y: scroll;
            background: #FFFFFF;
        }

        .filter-options {
            border-bottom: 1px solid #F0F4F6;
        }

        .filter-options-title {
            padding: rem(16) 0;
            border-top: 1px solid #F0F4F6;

            &:after {
                top: -3px;
                display: block;
                float: right;
                color: $light-blue;
            }
        }

        .filter-options-content {
            padding-bottom: rem(18);
        }

        &:not(.active) {
            padding-right: rem($gutter);
            padding-left: rem($gutter);

            .filter-title {
                position: relative;
                top: -56px;
                left: 0;
                display: inline-block;
                width: calc(50% - 15px);
                //max-width: rem(410);


                strong {
                    display: block;
                    padding: rem(11) rem(42) rem(11) rem(11);
                    font-family: $helvetica-bold;
                    font-size: rem(14);
                    cursor: pointer;
                    background: $white url('../images/arrow-down.svg') no-repeat;
                    background-position: right 16px top 16px;
                    background-size: 11px 5.9px;
                    border: 1px solid #F6F6F6;
                    border-radius: 0;
                    appearance: none;
                }
            }
        }


    }
}
