.catalogsearch-result-index {

    .sc-search-result {
        @include container($container, $gutter);
        @media all and (min-width: $md-breakpoint) {
            padding: rem(20) rem(200);
            margin: auto;
        }
        + h3 {
            display: none;
        }
        ~ ul {
            @include container($container, $gutter);
            @media all and (min-width: $md-breakpoint) {
                padding: 0 rem(200) rem(60);
                margin: auto;
            }
        }
    }

    .sub-search-for-content,
    .page-title-wrapper,
    .field.limiter,
    .toolbar-sorter.sorter {
        display: none;
    }

    h1.search-results {
        color: $light-blue;
        font-family: $helvetica-light;
        font-size: rem(32);
        font-weight: 300;
        line-height: 1.5;
    }

    ol.search-products {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            padding: rem(40) 0;
            overflow:hidden;
            border-bottom: 1px solid $lighter-grey;

            .search-result-wrapper {
                @media all and (max-width: $sm-breakpoint) {
                    flex-flow: row wrap !important;
                }

                display: flex;
                flex-flow: row nowrap;

                &__image {
                    @media all and (max-width: $sm-breakpoint) {
                        flex-basis: 100%;
                        margin: 0 0 rem(24);
                    }

                    display: flex;
                    align-items: center;
                    flex: 2;
                    justify-content: center;
                    min-height: rem(200);
                    margin: 0 rem(24) 0 0;
                    background: $white;

                    a {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        padding: 0 rem(7);
                    }

                    img {
                        @media all and (max-width: $sm-breakpoint) {
                            width: auto;
                            max-height: rem(200);
                        }
                        width: 100%;
                        max-width: 100%;
                    }

                }

                &__description {
                    @media all and (max-width: $sm-breakpoint) {
                        flex-basis: 100%;
                    }

                    flex: 5;

                    h3 {
                        padding: 0;
                        margin: rem(32) 0 0;
                        color: $light-blue;
                        font-family: $helvetica-light;
                        font-size: rem(24);
                        font-weight: 300;

                        a {
                            display: block;
                            color: $light-blue;
                            font-family: $helvetica-light;
                            font-size: rem(24);
                            font-weight: 300;
                        }
                    }

                    div.search-products-price{
                        margin: 21px 0 -10px;
                        color: $light-blue;
                        font-family: $helvetica-bold;
                        span {
                            &.covered-text, 
                            &.was-price
                            {
                                color: #898989;
                                font-weight: 700;
                            }
                            &.was-price {
                                text-decoration: line-through;
                            }
                            &.now-price {
                                color: rgba(159, 56, 57, 1);
                                font-weight: 700;
                            }
                        }
                    }

                    a {
                        display: none;
                        margin: 2px 0 0;
                        color: $light-blue;
                        font-family: $helvetica-light;
                        font-size: rem(14);
                        line-height: 1.5;
                        outline: none;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    div {
                        margin-top: rem(16);
                        color: $dark-grey2;
                        font-family: $helvetica-light;
                        font-size: rem(14);
                        line-height: 1.5;
                    }
                }
            }
        }
    }

    .message.notice {
        margin-bottom: 32px;
    }

    .pages {
        padding: rem(40) 0 0;
    }
}


div#search_autocomplete {
    opacity: 0 !important;
}
