.cms-warranty-claim {
    .page-main {
        .webforms {
            .webforms-description-text {
                margin-bottom: 0;
            }
            .actions-toolbar {
                padding-right: 0;
                padding-left: 0;
            }
            .fieldset {
                padding-right: 0;
                padding-left: 0;
                .field {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
            .form {
                .legend {
                    margin: 2.375rem 0.625rem 1.875rem 0.625rem;
                    font-size: 1.25rem;
                }
                .warranty-claim-customer-details{
                    .field {
                        width: 48%;
                        .control {
                            input {
                                border: 1px solid #7297A9;
                            }
                            select {
                                color: #9B9B9B;
                                background: transparent; 
                                border: 1px solid #7297A9;
                            }
                        }
                        &.address {
                            width: 97.7%;
                        }
                        @media screen and (max-width: 1075px) {
                            &.address {
                                width: 94%;
                            }
                        }
                        @media screen and (max-width: 800px) {
                            &.address {
                                width: 94.65%;
                            }
                        }
                        @media screen and (max-width: 599px) {
                            &.address {
                                width: 100%;
                            }
                        }
                    }
                    @media screen and (max-width: 1075px) {
                        .field {
                            width: 46%;
                        }
                    }
                    @media screen and (max-width: 599px) {
                        .field {
                            width: 100%;
                        }
                    }
                    .top-text {
                        width: 100%;
                        margin-bottom: 20px;
                    }
                    .country-label label {
                        display:none;
                    }
                }
                .warranty-claim-purchase-details{
                    .field {
                        width: 48%;
                        &.date-container {
                            width: 23.2%;
                        }
                        .control {
                            input {
                                border: 1px solid #7297A9;
                            }
                            select {
                                color: #9B9B9B;
                                background: transparent;
                                border: 1px solid #7297A9;
                                & ~ .label.filled {
                                    top: rem(30);
                                    right: rem(25);
                                }
                            }
                            .webforms-datepicker {
                                .webforms-calendar {
                                    width: 100%;
                                    ._has-datepicker {
                                        &:focus, &.filled {
                                            ~ .label {
                                                top: rem(10);
                                                font-size: rem(10);
                                            }
                                        }
                                        ~ .ui-datepicker-trigger:before {
                                            padding-top:5px;
                                        }
                                    }
                                }
                            } 
                            .label.filled {
                                top: rem(10);
                                font-size: rem(10);
                            }
                        }
                        &.type-date {
                            &:focus {
                                .label {
                                    top: rem(10);
                                    font-size: rem(10);
                                }
                            }
                        }
                    }
                    @media screen and (max-width: 1075px) {
                        .field {
                            width: 46%;
                            &.date-container {
                                width: 22%;
                            }
                        }
                    }
                    @media screen and (max-width: 599px) {
                        .field {
                            width: 100%;
                            &.date-container {
                                width: 100%;
                            }
                        }
                    }
                    @media screen and (min-width: 600px) {
                        .purchased-from {
                            p {
                                margin-top: -100px;
                            }
                            select.validation-failed ~ p {
                                margin-top: -136px;
                            }
                        }
                        .field {
                            @media screen and (max-width: 1024px) {
                                &.date-container {
                                    width: 21.6%;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: 599px) {
                        .purchased-from {
                            p {
                                margin-top: -130px;
                            }
                            select.validation-failed ~ p {
                                margin-top: -166px;
                            }
                            div {
                                margin-top: 90px;
                                margin-bottom: 60px;
                            }
                        }
                        .field {
                            .webforms-datepicker,
                            .webforms-calendar {
                                margin-bottom: 0;
                            }
                            &.purchased-from,
                            &.purchased-state {
                                order: 2;
                            }
                        }

                    }
                }
                .warranty-claim-product-details{
                    .field {
                        width: 31.5%;
                        .control {
                            input {
                                border: 1px solid #7297A9;
                            }
                            .input-file {
                                height: 50px;
                                padding: 1rem 1.5625rem;
                                ~ .label {
                                    display: none;
                                }
                                @media screen and (max-width: 599px) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: 1075px) {
                        .field {
                            width: 100%;
                        }
                    }
                    @media screen and (max-width: 599px) {
                        .field {
                            width: 100%;
                        }
                    }
                    .warrant-claim-describe-container {
                        width: 100%;
                        .warrant-claim-describe-input {
                            width:100%;
                            height: 100px;
                            padding: 1.6875rem 1.5625rem 1rem;
                            border: 1px solid #7297A9;
                            &:focus, &.filled {
                                ~ .label {
                                    top: rem(10);
                                    font-size: rem(10);
                                }
                            }
                        }
                    }
                }  
                
            }
        }
    }
}