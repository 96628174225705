/* Fonts */
$helvetica: HelveticaNeue, Arial, sans-serif;
$helvetica-light: HelveticaNeueLight, Arial, sans-serif;
$helvetica-bold: HelveticaNeueBold, Arial, sans-serif;

$base-font-size: 16;

/* Colours */
$blue: #00679B;
$white: #FFFFFF;
$black: #000000;
$light-black: #EDEDED;

$dark-grey: #4A4A4A;
$dark-grey2: #666666;
$dark-grey3: #343434;
$mid-grey: #54565B;
$light-grey: #D8D8D8;
$lighter-grey: #F6F6F6;

$olive: #A59C4E;
$rose: #9C616E;
$light-rose: #A4727C;
$light-blue: #7297A9;
$bright-blue: #003B5D;
$dark-blue: #243A55;
$red: #D0011D;
$orange: #ECB22B;

$error-color: #A0393A;
$placeholder-color: #9B9B9B;

$header-height: rem(70);
$header-nav-storelocator: rem(35);
$breadcrumbs-height: rem(50);
$full-header-height: rem(230);

//$container: 1160;
$xs-container: 480;
$xs-gutter: 0;
$container: 1180;
$gutter: 32;
$l-container: 1320;
$xl-container: 1600;
$xl-gutter: 32;

$normal: normal;

/* Breakpoints */
$xs-breakpoint: 480px;
$sm-breakpoint: 768px;
$sm-breakpoint-max: 990px;
$md-breakpoint: 991px;
$lg-breakpoint: 1200px;
$xlg-breakpoint: 1500px;
