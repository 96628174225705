.cms-enquire-now,
#lead-generation {
    #product-intro {
        margin: 1.4375rem 0;
        color: #666666;
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.5;
    }
    .messages {
        .message-notice.notice {
            display: none;
        }
    }
    .webforms {
        .webforms-description-text {
            margin-bottom: 0;
        }
        .actions-toolbar {
            padding-right: 0;
            padding-left: 0;
        }
        .fieldset {
            padding-right: 0;
            padding-left: 0;
            .field {
                padding-right: 0;
                padding-left: 0;
            }
        }
        .form {
            .legend {
                margin: 2.375rem 0.625rem 1.875rem 0.625rem;
                font-size: 1.25rem;
            }
            .field {
                width: 48%;
                .control {
                    input {
                        border: 1px solid #7297A9;
                    }
                    select {
                        color: #9B9B9B;
                        background: transparent; 
                        border: 1px solid #7297A9;
                    }
                }
                &.address {
                    width: 97.7%;
                }
                @media screen and (max-width: 1075px) {
                    &.address {
                        width: 94%;
                    }
                }
                @media screen and (max-width: 800px) {
                    &.address {
                        width: 94.65%;
                    }
                }
                @media screen and (max-width: 599px) {
                    &.address {
                        width: 100%;
                    }
                }
            }
            @media screen and (max-width: 1075px) {
                .field {
                    width: 46%;
                }
            }
            @media screen and (max-width: 599px) {
                .field {
                    width: 100%;
                }
            }
            .top-text {
                width: 100%;
                margin-bottom: 20px;
            }
            .country-label label {
                display:none;
            }
        }
    }
}