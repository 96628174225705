.featured-article {
    overflow-x: hidden;
    background-color: $lighter-grey;
    &__container {
        position: relative;
        @include container($container, 0);
        @media all and (min-width: $md-breakpoint) {
            display: flex;
            align-items: center;
            height:rem(100); // Fix vertically align in IE. It doesn't work with min-height only
            min-height: rem(720);
        }
    }
    &__text {
        @media all and (min-width: $md-breakpoint) {
            width: 50%;
        }
        &-container {
            @include container($container, $gutter);
            padding-top: rem(50);
            padding-right: rem(20);
            padding-bottom: rem(110);
            font-size: rem(14);
            line-height: 1.57;
            @media all and (min-width: $md-breakpoint) {
                padding-top: rem(30);
                padding-right: rem(50);
                padding-bottom: rem(30);
            }
            h1,
            h2{
                margin-bottom: rem(40);
                font-family: $helvetica-light;
                font-size: rem(32);
                font-weight: 300;
            }
            p {
                margin-top: 0;
                margin-bottom: rem(28);
                color: $dark-grey2;
            }
        }
    }
    &__image {
        &--mobile {
            display: block;
            @media all and (min-width: $md-breakpoint) {
                display: none;
            }
            img {
                display: block;
                width: 100%;
            }

        }
        &--desktop {
            display: none;
            @media all and (min-width: $md-breakpoint) {
                position: absolute;
                top:0;
                left: 50%;
                display: block;
                width: 100%;
                /*max-width: rem(720);*/
                min-height: rem(720);
                background-repeat: no-repeat;
            }
        }
    }
    &__link {
        @media all and (max-width: $sm-breakpoint) {
            text-align:center;
        }
        .btn {
            @media all and (max-width: ($sm-breakpoint - 1px )) {
                display: block;
                width: 100%;
            }
        }
        margin-top: rem(120);
    }

}