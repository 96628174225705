.ct-store {

    .ct-store-wrapper{
        overflow:hidden;
    }

    .no-records {
        @media all and (min-width: $md-breakpoint) {
            text-align: left;
        }
        flex-basis: 100%;
        color: $light-grey;
        text-align: center;
    }
    .hidden { display: none }

    h2.section-title {
        padding: 0;
        margin: 0 0 rem(40);
        color: $dark-grey;
        font-family: $helvetica;
        font-size: rem(24);
        font-weight: 300;
        line-height: rem(28);
    }

    .breadcrumbs {
        width: 102%;
        margin-left: -10px;
    }

    &__top-content {
        @include container($container, $gutter);
        @media all and (min-width: $md-breakpoint) {
            display: flex;
            align-items: center;
            padding: 0 rem(20);
        }
        padding: 0 rem(35);

        .store-title {
            padding: rem(80) 0 rem(40);

            @media all and (min-width: $md-breakpoint) {
                flex: 1 0 auto;
                padding-bottom: rem(80) !important;
            }
            h1 {
                margin-bottom: 0;
                color: $light-blue;
                font-family: $helvetica-light;
                font-size: rem(32);
                font-weight: 300;
            }
        }
    }

    &__intro {
        position: absolute;
        top:0;
        right: 0;
        left: 0;
        z-index: 1;
        display: flex;
        flex-flow: row wrap;;
        padding: 0 rem(24) rem(17) rem(20);
        overflow: hidden;
        background: rgba(0, 103, 155, 0.9);
        transition: all 0.1s;

        @media all and (min-width: $md-breakpoint) {
            position: fixed;
            top:rem(160);
            transition: all 0.6s;
        }

        &.hideElement{
            display:none;
        }

        .title {
            flex: 1;
            h1 {
                @media all and (min-width: $md-breakpoint) {
                    padding:0;
                    //margin-top: rem(-160);
                    font-size: rem(32);
                }
                padding: rem(15) 0;
                margin:0;
                color: $white;
                font-size: rem(24);
                font-weight: 300;
            }
        }
        .contact {
            @media all and (min-width: $md-breakpoint) {
                display: block;
                flex: 1;
                color: $white;
                font-family: $helvetica-light;
                font-size: rem(32);
                font-weight:300;
                line-height:1.31;
                text-align: right;
                text-transform: uppercase;

                small {
                    font-size: rem(14);
                }

                * {
                    display: inline;
                    padding:0;
                    margin: 0;
                }
            }
            display: none;
        }

        &.is-scrolled {
            opacity: 0;
        }
    }

    .top-banner {
        @media all and (max-width: $md-breakpoint) {
            min-height: rem(72);
        }
        position: relative;
        min-height: rem(292);
        margin-top: 0;
        overflow: hidden;

        p {
            padding:0;
            margin:0;
        }

        img {
            @media all and (min-width: $md-breakpoint) {
                width:100%;
            }
            display: flex;
            justify-content: center;
            width: auto;
            margin: auto;
            text-align: center;
        }

        .promotional-offer-box {
            @include container($container, $gutter);

            @media all and (min-width: $md-breakpoint) {
                //padding-top: rem(370);
            }

            position: absolute;
            right: 0;
            left: 0;
            display: flex;
            align-items: flex-end;
            height: 100%;

            .promotional-offer {
                @media all and (min-width: $sm-breakpoint) {
                    width: 387px;
                    height: 387px;
                    margin-bottom: rem(137);
                }

                display: flex;
                flex-flow: column nowrap;
                justify-content: flex-start;
                width: 100%;
                height: rem(325);
                padding: rem(52);
                margin-bottom: rem(55);
                color: $white;
                text-shadow: 0 0 1rem $dark-grey;
                border: 4px solid $white;

                small {
                    display: block;
                    flex: 2;
                    font-size: rem(14);
                    font-weight: 700;
                }

                p {
                    display: flex;
                    align-items: flex-start;
                    flex: 10;
                    font-size: rem(32);
                    font-weight: 100;
                    line-height: 1.31;
                }

                a {
                    position:relative;
                    display:block;
                    flex: 1;
                    text-decoration:none;

                    &:hover {
                        text-decoration: underline;
                    }
                    &:after {
                        position: absolute;
                        width: 0;
                        height: 0;
                        margin:2px 0 0 32px;
                        border: 6px solid transparent;
                        border-left: 6px solid #FFFFFF;
                        content: '';
                        visibility: visible;
                    }
                }
                &.align-right {
                    justify-content: flex-end;
                }

                &:hover {
                    text-shadow: none;
                }
            }
        }
    }

    &__contents {
        @include container($container, $gutter);

        @media all and (min-width: $md-breakpoint) {
            padding: 0 rem(20);
        }
        padding: 0;

        &--description{
            @media all and (min-width: $md-breakpoint) {
                margin: rem(-75) auto 0;
            }
        }

        .content-description {
            display: flex;
            flex-flow: row wrap;
            margin-bottom:rem(100);

            &__form {
                @media all and (min-width: $md-breakpoint) {
                    flex: 3;
                    order: 1;
                }
                @media all and (max-width: $md-breakpoint) {
                    width: 100%;
                }
                flex: 1 0 100%;
                order: 2;
                color: $dark-grey2;
                font-size: rem(14);
                line-height:1.57;

                .secondary-title {
                    position:relative;

                    @media all and (max-width: $md-breakpoint) {
                        display: none;
                    }

                    /*height: rem(75);*/
                    padding: rem(17) 0;
                    color: $white;
                    background: $blue;
                    opacity: 0;
                    transition: all 0.6s;

                    &::before {
                        position: absolute;
                        top:0;
                        right:100%;
                        bottom:0;
                        z-index: -1;
                        width: 100%;
                        /*height: rem(75);*/
                        /*margin-top: rem(-22);*/
                        background: $blue;
                        content: '';
                        opacity: 0.9;
                    }

                    &.is-scrolled {
                        @media all and (min-width: $md-breakpoint) {
                            opacity: 0.9;
                        }
                    }

                    h1 {
                        padding:0;
                        margin:0;
                        color: $white;
                        font-size: rem(32);
                        font-weight:300;
                        line-height:1.31;
                    }
                }

                .description{
                    @media all and (min-width: $md-breakpoint) {
                        padding: rem(70) rem(50) 0 0;
                    }
                    padding: rem(50) rem(20) rem(20);
                    color: $dark-grey2;
                    font-size: rem(14);
                    line-height: 1.57;
                }

                .contact-form {
                    @media all and (min-width: $md-breakpoint) {
                        padding: rem(17) rem(30) 0 0;
                    }
                    padding: rem(20);
                    color: $dark-grey2;
                    font-size: rem(14);
                    line-height: 1.57;

                    h2 {
                        margin: rem(32) 0;
                    }
                }

                textarea {
                    margin-bottom:0;
                }
                .type-textarea{
                    margin-bottom: rem(50);

                }

                .btn {
                    @media all and (max-width: $sm-breakpoint) {
                        width: 100%;
                    }
                    width: auto;
                    padding: rem(20) rem(35);
                }
            }

            &__info {
                @media all and (min-width: $md-breakpoint) {
                    flex:2;
                    order: 2;
                }

                flex: 1 0 100%;
                order: 1;
                overflow:hidden;
                color: $white;
                font-family: $helvetica-light;
                font-size: rem(14);
                line-height: 1.86;

                h3 {
                    padding:0;
                    margin:0;
                    font-size: rem(20);
                    font-weight:300;

                    @media all and (min-width:$sm-breakpoint){
                        font-size: rem(24);
                    }
                }

                p {
                    padding:0;
                    margin:0;
                }

                .info-title {
                    display: flex;
                    align-items: center;
                    height: rem(100);
                    padding: 0 rem(29);
                    font-size:rem(24);
                    font-weight:300;

                    h3 {
                        flex: 1;
                        * {
                            padding: 0;
                            margin: 0;
                        }
                    }

                    .lazcon {
                        display: flex;
                        flex: 1;
                        margin-bottom: 1rem;
                        background-position: center right;
                    }
                }

                .store-address {
                    padding-top:7px;
                    background: rgba(114, 151, 169, 0.9);
                    opacity: 0.99;

                    @media all and (min-width:$md-breakpoint){
                        padding-top:0;
                    }

                    .info-title {
                        @media all and (min-width: $sm-breakpoint) {
                            height: rem(75);
                        }
                        display: flex;
                        align-items: center;
                        height: rem(90);
                    }
                    .info-content {
                        @media all and (min-width: $sm-breakpoint) {
                            height: rem(170);
                        }
                        display: flex;
                        align-items: center;
                        height: rem(150);
                        padding: 0 rem(29) rem(20);
                        font-family: $helvetica;
                    }
                }

                .store-map {
                    background: $white;

                    #store-map {
                        width: 100%;
                        height: rem(465);
                        outline: none;

                        * {
                            outline: none;
                        }
                    }
                }

                .store-contact {
                    background: $light-blue;
                    opacity: 0.9;
                }

                .store-email {

                    background-color: $olive;
                    opacity: 0.9;
                }

                .store-hours {
                    background: $rose;
                    opacity: 0.9;

                    .info-content {
                        padding: rem(10) rem(29) rem(80);
                        table tr td:last-child {
                            font-weight: 700;
                            text-align: right;
                        }
                    }
                }
            }
        }

        .lazcon {
            position: relative;
            top: 10px;
            width: 18px;
            height:18px;

            &.lazcon-location {
                background: url('../images/icons/location.svg') no-repeat center center;
            }
            &.lazcon-phone {
                background: url('../images/icons/phone.svg') no-repeat center center;
            }
            &.lazcon-message {
                background: url('../images/icons/message.svg') no-repeat center center;
            }
            &.lazcon-hours {
                background: url('../images/icons/hours.svg') no-repeat center center;
            }
        }

        .featured-products {
            @media all and (min-width: $md-breakpoint) {
                margin: rem(90) 0;
            }
            overflow: hidden;

            h3 {
                @media all and (max-width: $md-breakpoint) {
                    margin: rem(30) rem(20);
                }
                margin:rem(30) 0;
                color: $dark-grey;
                font-family: $helvetica-light;
                font-size: rem(24);
                font-weight:300;
            }

            .products-carousel {

                @media all and (min-width: $md-breakpoint) {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                }

                margin-bottom: rem(90);
                overflow: hidden;

                .item {
                    @media all and (min-width: $md-breakpoint) {
                        flex: 1 0 calc(25% - 20px);
                        padding-top: rem(30);
                        //margin-left:auto;
                        margin-left: rem(4);
                    }
                    @media all and (max-width: $sm-breakpoint) {
                        width: rem(269);
                    }
                    padding: rem(20) rem(10);
                    margin: rem(5) rem(20) rem(5) rem(2);
                    background: $white;

                    .featured-product-link {
                        display: block;
                        overflow: hidden;
                    }

                    .image {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 180px;
                    }

                    .title {
                        color: #608598;
                        font-size: rem(18);
                        font-weight:300;
                        line-height:1.11;
                        text-align: center;

                        small {
                            display: block;
                            margin-top: rem(5);
                            color: #898989;
                            font-size: rem(14);
                            font-weight: 500;
                            line-height:1.43;
                            text-align: center;
                        }
                    }

                    &:hover {
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }

        .video-section {
            margin-bottom: rem(115);

            @media all and (max-width: $md-breakpoint) {
                margin-bottom: rem(30);
            }

            h3 {
                @media all and (max-width: $md-breakpoint) {
                    margin: rem(10) 0 rem(40);
                    font-size: rem(24);
                }
                margin:  0 0 rem(36);
                color: $dark-grey;
                font-family:$helvetica-light;
                font-size: rem(32);
                font-weight:300;
                text-align: center;
            }

            &__description {
                @media all and (max-width: $md-breakpoint) {
                    width: 100%;
                    padding: 0 rem(10) rem(20);
                }

                width: rem(960);
                margin: 0 auto rem(55);
                color: $dark-grey2;
                font-size: rem(14);
                line-height: 1.57;
                text-align: center;
            }

            &__embed {
                @media all and (max-width: $md-breakpoint) {
                    margin: rem(25) 0 0;
                }
                position: relative;
                //
                //padding-bottom: 52%;
                margin: rem(25) 0 rem(80);

                iframe {
                    @media all and (max-width: $sm-breakpoint) {
                        // height: rem(186);
                    }

                    position: absolute;
                    width: 100%;
                    height: 100%;
                    max-height: 600px;
                }
            }
        }
    }

    .footer-banner {
        @media all and (min-width: $md-breakpoint) {
            margin: 0;
            overflow: hidden;
        }
        display: flex;
        justify-content: center;
        margin:0 0 rem(0);
        overflow: hidden;

        img {
            @media all and (min-width: $md-breakpoint) {
                width: 100%;
                height: 100%;
            }
            width: auto;
        }

        * {
            //width: 100%;
            //padding:0;
            //margin:0;
        }
    }
}

.storelocator-store-index {

    .page-title-wrapper {
        display: none;
    }

    .contact-form {
        .please-wait {
            position: absolute;
            margin: -3.4rem 0 0 9rem;
            transform: scale(0.4);

            @media all and (max-width: 770px) {
                margin: 0;
            }
            .loading-indicator {
                display:none;
            }
            .loading-indicator-2 {
                display:none;
            }
            
        }
    }
    
    .featured-products-outer-wrapper {
        position: relative;

        a.featured-product-link {
            margin: rem(-30) rem(-10) 0;
            .image {
                height: rem(220) !important;
                overflow: hidden;
                background-size: cover !important;
            }
            .title {
                //min-height: rem(120) !important;
                padding: rem(20) 0;

                .title-text {
                    padding: 0 rem(20);
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
            }
        }


        .slick-arrow {
            opacity: 0;
        }

        .carousel-nav {
            @media all and (max-width: $md-breakpoint) {
                display: none;
            }
            position: absolute;
            top: 50%;
            width: rem(30);
            height: rem(30);
            margin-top: rem(-30);
            cursor: pointer;

            &.carousel-nav-prev {
                left: rem(27);
                background: url('../images/icons/slide-prev.png') no-repeat center center;
            }
            &.carousel-nav-next {
                right : rem(27);
                background: url('../images/icons/slide-next.png') no-repeat center center;
            }

            &:hover {
                zoom: 110%;
            }
        }
    }
}