/*------------------------------------*\
    STORE LOCATOR
\*------------------------------------*/
.amlocator-index-index {
    .loading-mask {
        position: absolute;
        top: rem(320);
        right: 0;
        left: 0;
        z-index: 10;
        display: none;
        height: rem(600);
        background: $white url('../images/loader-2.gif') no-repeat center center;
        opacity: 0.8;
        img, p {
            display: none;
        }
    }

    .page-main {
        @media (min-width: $md-breakpoint) {
            padding-top: rem(20);
            @include container($container, $gutter);
        }
        @media (min-width: $xlg-breakpoint) {
            @include container($xl-container, $xl-gutter);
        }
    }

    .page-title {
        margin-bottom: rem(48);
        @media (max-width: $md-breakpoint) {
            @include container($container, $gutter);
            margin-top: rem(32);
            margin-bottom: rem(32);
            font-size: rem(30);
        }
    }
}


/*------------------------------------*\
    GRID
\*------------------------------------*/
.slocator {
    display: flex;
    @media (max-width: $md-breakpoint) {
        flex-wrap: wrap;
    }
}

.slocator-form,
.slocator-map {
    flex-basis: 100%;
    flex-direction: column;
    flex-shrink: 0;
}

.slocator-form {
    @media (max-width: $md-breakpoint) {
        @include container($container, $gutter);
    }
    @media (min-width: $md-breakpoint) {
        flex-basis: 42.5%;
        padding-right: rem(48);
    }
}

.slocator-map {
    @media (min-width: $md-breakpoint) {
        flex-basis: 57.5%;
    }
}


/*------------------------------------*\
    SEARCH
\*------------------------------------*/

.slocator-search {
    padding-bottom: rem(32);

    .amlocator_input, .input-box {
        margin: 0;
    }

    .input-text,
    .select {
        border: 1px solid $blue;
    }

    label {
        display: block;
        margin-bottom: 5px;
        font-weight: 700;
    }
}

#search-radius {
    display: none;
}

.slocator-btn {
    margin-top: rem(24);

    .action.primary {
        width: 100%;
        color: $white;
        font-weight: 700;
        background-color: $light-blue;
        border: none;
        &:hover {
            color: $light-blue;
            background-color: $light-grey;
        }
    }
}


/*------------------------------------*\
    MAP
\*------------------------------------*/
.slocator-canvas {
    height: 600px;
}

.slocator-popup {
    h4 {
        margin-top: 1rem;
        margin-bottom: 0;
    }
}


/*------------------------------------*\
    RESULTS
\*------------------------------------*/

.slocator-list {
    @media (min-width: $md-breakpoint) {
        height: 570px;
        overflow: hidden;
    }
}

.slocator-results {
    padding-bottom: rem(16);
    h2 {
        padding-bottom: rem(32);
        margin-bottom: 0;
        color: $dark-grey;
        border-bottom: 1px solid $light-black;
    }
}
.slocator-items {
    @media (min-width: $md-breakpoint) {
        height: rem(354);
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.slocator-item {
    position: relative;
    font-size: rem(14);
    border-collapse: collapse;
    border-bottom: 1px solid $light-black;

    .location_header {
        margin-bottom: rem(8);
        font-size: rem(14);
    }

    > span a {
        display: block;
        padding: rem(36) rem(36) rem(36) rem(16);
        cursor: pointer;
        @media (min-width: $md-breakpoint) {
            height: rem(117);
        }
        &:hover {
            background: $lighter-grey;
            i.chevron-right {
                &:after {
                    border-left-color: $lighter-grey;
                }
            }
        }
    }

    @include chevron(chevron-right, 12px, 2px, $blue, right, $white);

    i.chevron-right {
        position: absolute;
        top: 50%;
        right: rem(24);
        margin-top: -8px;
    }

}


/*------------------------------------*\
    STORE DIRECTORY
\*------------------------------------*/

.slocator-directory {
    padding-bottom: rem(32);
    @media (max-width: $md-breakpoint) {
        .btn {
            width: 100%;
        }
        @include container($container, $gutter);
    }

    h2 {
        padding: rem(32) 0 10px;
        color: $dark-grey;

        @media (min-width: $md-breakpoint) {
            display: inline-block;
            padding: rem(32) rem(32) rem(32) 0;
        }
    }

    .action.primary {
        color: $white;
        background-color: $light-blue;
        border: none;
        @include chevron(chevron-right, 8px, 2px, $white, right, $light-blue);

        i.chevron-right {
            position: absolute;
            top: 50%;
            right: 24px;
            margin-top: -8px;
            &:before, &:after {
                transition: all 0.4s ease;
            }
        }

        &:hover {
            color: $light-blue;
            background-color: $light-grey;

            i.chevron-right {
                &:before {
                    border-left-color: $light-blue;
                }
                &:after {
                    border-left-color: $light-grey;
                }
            }
        }
    }
}
