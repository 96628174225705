.stockists {
    padding-top: rem(32);
    padding-bottom: rem(45);
    color: $white;
    background-color: $rose;
    @media all and (min-width: $sm-breakpoint) {
        padding-top: rem(56);
        padding-bottom: rem(64);
    }
    &__container {
        @include container($container, $gutter);
        @media all and (min-width: $sm-breakpoint) {
            display: flex;
        }
    }
    &__title {
        margin-bottom: rem(45);
        font-family: $helvetica-light;
        font-size: rem(32);
        line-height: 1.34;
        @media all and (min-width: $sm-breakpoint) {
            width: 50%;
            margin-bottom: 0;
            line-height: rem(60);
        }
    }
    &__form {
        @media all and (min-width: $sm-breakpoint) {
            width: 50%;
            overflow: hidden;
        }

        background: rgba($white, 0.1) url('../images/arrow.png') no-repeat;
        background-position: right;

        .select-wrapper-fix {
            position: relative;

            @-moz-document url-prefix('') {
                &:after {
                    position: absolute;
                    top: 0;
                    left: 100%;
                    display: block;
                    width: 20px;
                    height: 100%;
                    margin: 0 0 0 rem(-20);
                    background: rgba(166, 113, 125, 1);
                    content: '';
                }
            }
        }

        select {
            width: 100%;
            padding: rem(22) rem(24);
            color: $white;
            font-family: $helvetica-bold;
            font-size: rem(14);
            background: transparent;
            border: none;
            border-radius: 0;
            appearance: none;

            option {
                color: $rose;
                background: $white;
            }

            &:focus {
                outline: none;
            }
            &::-ms-expand {
                display: none; // Hide arrow in IE
            }
        }
    }
}