.login-page {
    padding: 0;
    text-align: center;
    .container {
        padding: 0;
    }

    @media all and (min-width: $md-breakpoint) {
        padding: rem(50) 0 rem(50);
    }

    .page-title {
        width: rem(270);
        margin: 0 auto rem(70) auto !important;

        @media all and (min-width: $sm-breakpoint) {
            width: auto;
        }
    }

    .fieldset {
        width: calc(100% + #{rem(20)});
        margin-bottom: 0;
    }

    .note {
        margin-bottom: rem(45);
    }

    .actions-toolbar {
        margin-top: rem(60);
        margin-bottom: rem(70);

        @media all and (min-width: $sm-breakpoint) {
            justify-content: center !important;
        }
    }

    a {
        color: $light-blue;
    }
}

.customer-account-login {
   
    .page-title-wrapper {
        display: none;
    }

    .block-new-customer {
        display: none;
    }

    .page-wrapper {
        padding-top: $header-height;

        .modal__logo {
            display: none;
        }

        @media all and (min-width: $md-breakpoint) {
            padding-top: 0;
            margin-top: $full-header-height;
        }
    }
}