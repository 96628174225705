.btn {
    position: relative;
    display: inline-block;
    width: rem(300);
    max-width: 100%;
    padding: rem(18) rem(24);
    font-family: $helvetica-bold;
    font-size: rem(14);
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    background-color: transparent;
    border-radius: 0;
    transition: all 0.4s ease;

    &--light-blue {
        color: $light-blue;
        border: 2px solid $light-blue;

        &:hover, &--active {
            color: $lighter-grey;
            background-color: $light-blue;
        }
    }

    &--light-blue-solid {
        color: $lighter-grey;
        background-color: $light-blue;
        border: 2px solid $light-blue;

        &:hover {
            background-color: darken($light-blue, 10%);
            border-color: darken($light-blue, 8%);
        }
    }

    &--bright-blue {
        color: $bright-blue;
        border: 2px solid $bright-blue;

        &:hover, &--active {
            color: $white;
            background-color: $bright-blue;
        }
    }

    &--bright-blue-solid {
        color: $white;
        background-color: $bright-blue;
        border: 2px solid $bright-blue;

        &:hover {
            background-color: darken($bright-blue, 10%);
            border-color: darken($bright-blue, 8%);
        }
    }

    &--dark-blue-solid {
        color: $white;
        background-color: $dark-blue;
        border: 2px solid $dark-blue;

        &:hover {
            background-color: darken($dark-blue, 10%);
            border-color: darken($dark-blue, 8%);
        }
    }

    &--orange {
        color: $white;
        background-color: $orange;
        border: 2px solid $orange;

        &:hover, &--active {
            background-color: darken($orange, 8%);
            border-color: darken($orange, 8%);
        }
    }

    &--dark-grey-solid {
        color: $lighter-grey;
        background-color: $dark-grey2;
        border: 2px solid $dark-grey2;

        &:hover {
            background-color: darken($dark-grey2, 10%);
            border-color: darken($dark-grey2, 8%);
        }
    }

    &:after {
        position: absolute;
        top: rem(22);
        right: rem(24);
        transition: all 0.4s ease;
    }
    &--border-red-centered {
        width: 100%;
        max-width: rem(180);
        color: $rose;
        text-align: center;
        border: 2px solid $rose;
        &:hover {
            color: $white;
            background-color: $rose;
        }
    }
    &--border-red {
        color: $rose;
        border: 2px solid $rose;
        @include triangle($rose);
        &:hover {
            color: $white;
            background-color: $rose;
            @include triangle($white);
        }
    }
    &--border-blue {
        color: $light-blue;
        border: 2px solid $light-blue;
        @include triangle($light-blue);
        &:hover {
            color: $white;
            background-color: $light-blue;
            @include triangle($white);
        }
    }
    &--border-white {
        color: $white;
        text-align: center;
        border: 2px solid $white;
    }
}
