.contentmanager-contenttype-store-gallery,
.contentmanager-contenttype-store_gallery {
    .footer-signup {
        display: none;
    }

    .cms-store_gallery {

        &__intro {
            padding-top: rem(32);
            margin: rem(32) 0;
            text-align: center;
            @include container($container, $gutter);
            .store-logo {
                padding-top: rem(16);
            }
            .title {
                margin: rem(32) 0;
                color: $light-blue;
                font-family: $helvetica-light;
                font-size: rem(24);
                font-weight: 300;
                @media all and (min-width: $md-breakpoint) {
                    font-size: rem(32);
                }
            }
            .description {
                font-size: rem(15);
                line-height: 1.5;
            }
            .participating-store {
                margin-bottom: 1rem;
                .btn {
                    display: flex;
                    align-items: center;
                    width: auto;
                    margin: 0 auto;
                    .participating-store-icon {
                        margin-right: 1rem;
                        &.custom-tick {
                            font-size: 1.3rem;
                        }
                    }
                }
            }
        }

        &__nav {
            margin: rem(32) 0;
            background-color: $light-black;
            &.is-scrolled {
                position: fixed;
                bottom: 0;
            }
            ul {
                display: flex;
                align-items: center;
                flex-flow: row wrap;
                justify-content: center;
                padding: 0;
                margin: 0;
            }
            li {
                padding: rem(16) 0;
                list-style: none;
                a {
                    padding: 0 rem(8);
                    font-family: $helvetica-bold;
                    font-size: rem(11);
                    text-transform: uppercase;
                    border-left: 1px solid $black;
                    @media (min-width: $xs-breakpoint) {
                        padding: 0 rem(16);
                        font-size: rem(14);
                    }
                }
                &:first-child a {
                    border: none;
                }
                &.comfort-club a {
                    border-right: 1px solid $black;
                    border-left: 1px solid $black;
                }
                &.store-promotions, 
                &.special-offers {
                    & + .comfort-club a {
                        border-right: none;
                    }
                }
            }
            @media (max-width: 600px) {
                .store-promotions + .special-offers + .comfort-club .join {
                    display: none;
                }
            }
        }

        &__details {
            @include container($container, $gutter);
            @media (min-width: $xlg-breakpoint) {
                @include container($xl-container, $xl-gutter);
            }
            @media (max-width: $xs-breakpoint) {
                @include container($xs-container, $xs-gutter);
            }
            h3 {
                margin-top: 0;
                font-size: rem(20);
            }
            @media all and (min-width: $md-breakpoint) {
                display: flex;
                align-items: flex-start;
                flex-flow: row;
            }
            transition: all 0.1s;
            .detail__hours {
                flex-basis: 32%;
                padding: rem(32);
                margin: rem(32) 0;
                @media all and (min-width: $md-breakpoint) {
                    height: rem(360);
                    padding: rem(32) rem(40);
                    margin: 0 2% 0 0;
                }
                background-color: $light-black;
                h3 {
                    color: $dark-grey;
                }
                // collapsible on mobile
                @media all and (max-width: $sm-breakpoint) {
                    > h3:after {
                        float: right;
                        margin-top: - rem(12);
                        font-size: rem(32);
                        letter-spacing: - rem(4);
                        content: '--';
                    }
                    &.collapsed {
                        padding-bottom: rem(10);
                        > h3 {
                            &:after {
                                margin-top: - rem(13);
                                content: '+';
                            }
                            ~ * {
                                display: none;
                            }   
                        }
                    }
                }
                table {
                    border-spacing: 0;
                } 
                td {
                    padding: rem(6) rem(16) rem(6) 0;
                    font-size: rem(14);
                    &:last-child {
                        padding-right: 0;
                    }
                }
                .holidays td {
                    padding-top: rem(16);
                    color: $dark-grey;
                }
            }
            .detail__contact {
                position: relative;
                flex-basis: 32%;
                height: rem(360);
                padding: rem(32);
                background-color: $light-blue;
                @media all and (min-width: $md-breakpoint) {
                    padding: rem(32) rem(40);
                    margin: 0;
                }
                h3 {
                    color: $white;
                }
                // collapsible on mobile
                @media all and (max-width: $sm-breakpoint) {
                    > h3:after {
                        float: right;
                        margin-top: - rem(12);
                        font-size: rem(32);
                        letter-spacing: - rem(4);
                        content: '--';
                    }
                    &.collapsed {
                        height: auto;
                        padding-bottom: rem(10);
                        > h3 {
                            &:after {
                                margin-top: - rem(13);
                                content: '+';
                            }
                            ~ * {
                                display: none;
                            }   
                        }
                        ~ * {
                            display: none;
                        }   
                    }
                }
                address, p {
                    color: $white;
                    font-size: rem(14);
                    font-style: normal;
                    line-height: 1.5;
                }
                .get-directions {
                    position: absolute;
                    right: rem(32);
                    bottom: rem(40);
                    left: rem(32);
                    width: auto;
                    @media all and (min-width: $md-breakpoint) {
                        right: rem(40);
                        left: rem(40);
                    }
                    .custom-external {
                        position: absolute;
                        top: rem(14);
                        right: rem(16);
                        font-size: rem(24);
                    }
                    &:hover {
                        color: $light-blue;
                        background: $white;
                    }
                }
            }
            .detail__map {
                flex-basis: 34%;
                height: rem(360);
                padding: 0;
                background-color: $lighter-grey;
                #store_map {
                    height: 100%;
                }
            }
        }
        &__video {
            margin-top: rem(32);
            margin-bottom: rem(32);
            @include container($container, $gutter);
            @media (min-width: $xlg-breakpoint) {
                @include container($xl-container, $xl-gutter);
            }
            @media (max-width: $xs-breakpoint) {
                @include container($xs-container, $xs-gutter);
            }
            @media all and (min-width: $sm-breakpoint) {
                display: flex;
                flex-flow: row;
            }
            .promo-block {
                width: 100%;
                max-height: rem(435);
                padding: 0;
                font-size: 0;
                text-align: center;
                background-color: $light-black;
                img {
                    width: 100%;
                    max-width: unset;
                }
                h3 {
                    padding: rem(48) rem(32) 0;
                    color: $dark-grey;
                    font-size: rem(20);
                    @media all and (min-width: $md-breakpoint) {
                        padding: rem(48) rem(40) 0;
                    }
                }
                p {
                    padding: 0 rem(32) rem(48);
                    margin-bottom: 0;
                    font-size: rem(16);
                    @media all and (min-width: $md-breakpoint) {
                        padding: 0 rem(40) rem(48);
                    }
                }
                @media all and (min-width: $md-breakpoint) {
                    flex-basis: 50%;
                    margin: 0;
                    text-align: left;
                }
            }
        }
        &__voucher {
            margin-top: rem(32);
            margin-bottom: rem(32);
            @include container($container, $gutter);
            @media (min-width: $xlg-breakpoint) {
                @include container($xl-container, $xl-gutter);
            }
            @media (max-width: $xs-breakpoint) {
                @include container($xs-container, $xs-gutter);
            }
            #mc_voucher {
                padding: rem(32);
                background-color: $rose;
            }
            #mc-voucher-form {
                max-width: rem(960);
                margin: rem(32) auto rem(8);
            }
            h2 {
                margin: rem(24) auto;
                color: $white;
                font-size: rem(40);
                text-align: center;
                span {
                    color: $orange;
                    font-weight: 700;
                    white-space: nowrap;
                }
            }
            p {
                max-width: rem(960);
                margin: 0 auto rem(16);
                color: $white;
                font-size: rem(14);
                line-height: 1.5;
                text-align: center;
            }
            .control {
                margin-bottom: rem(16);
            }
            @media all and (min-width: $sm-breakpoint) {
                .fieldset {
                    display: flex;
                    align-items: flex-start;
                    flex-flow: row;
                }
                .control {
                    flex-basis: 33%;
                }
                .action-buttons {
                    width: 180px;
                }
            }
            .btn--orange {
                height: rem(60); 
                @media all and (max-width: $sm-breakpoint) {
                    width: 100%; 
                }
            }
        }
        &__promos {
            padding: 0 2%;
            margin-top: rem(32);
            margin-bottom: rem(32);
            .slick-slide {
                margin: 0 rem(10);
            }
            .slick-list {
                margin: 0 - rem(10);
            }
            @include container($container, $gutter);
            @media (min-width: $xlg-breakpoint) {
                @include container($xl-container, $xl-gutter);
            }
            @media all and (min-width: $sm-breakpoint) {
                display: flex;
                align-items: flex-start;
                flex-flow: row;
            }
            .promo-block {
                padding: 0;
                background-color: $lighter-grey;
                img {
                    width: 100%;
                    max-width: unset;
                }
                @media all and (min-width: $sm-breakpoint) {
                    flex-basis: 32%;
                    margin: 0 0 0 2%;
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
        &__offers {
            padding: 0 rem(32);
            margin: rem(32) 0;
            @include container($container, $gutter);
            @media (min-width: $xlg-breakpoint) {
                @include container($xl-container, $xl-gutter);
            }

            h2 {
                @media all and (max-width: $md-breakpoint) {
                    margin: rem(32) 0;
                }
                margin: rem(32) 0;
                color: $dark-grey2;
                font-family: $helvetica-light;
                font-size: rem(24);
                font-weight:300;
            }

            .special-carousel {

                .slick-slide {
                    margin: 0 rem(10);
                }
                .slick-list {
                    margin: 0 - rem(10);
                }
                @media all and (min-width: $sm-breakpoint) {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                }

                margin-bottom: rem(32);

                .item {
                    box-align: center;
                    box-orient: vertical;
                    box-pack: start;
                    align-items: center;
                    box-direction: normal;
                    flex-basis: 100%;
                    flex-flow: column nowrap;
                    justify-content: flex-start;
                    width: rem(320);
                    padding: rem(20) 0 0;
                    margin: 0;
                    text-align: center;
                    background: $white;
                    border: 1px solid $lighter-grey;
                    @media all and (min-width: $sm-breakpoint) {
                        flex-basis: 33.3333%;
                        width: 33.3333%;
                    }
                    @media all and (min-width: $md-breakpoint) {
                        flex-basis: 25%;
                        width: 25%;
                    }
                    .photo {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: rem(320);
                        img {
                            width: 100%;
                            max-height: rem(360);
                        }
                    }
                    .product-item-name a {
                        display: block;
                        height: rem(20);
                        margin: 0;
                        overflow: hidden;
                        color: $light-blue;
                        font-family: $helvetica;
                        font-size: rem(18);
                        text-align: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .product-item-details {
                        padding: 0 rem(16) rem(32);
                    }
                    .price-box {
                        height: rem(14);
                        margin-top: rem(12);
                        font-size: rem(14);
                    }
                    .price-text {
                        height: rem(14);
                        margin-top: rem(12);
                    }
                    .old-price {
                        padding-right: rem(8);
                        text-decoration: line-through;
                    }
                    .special-price {
                        color: $error-color;
                    }
                    .product-covering {
                        height: rem(16);
                        margin-top: 0;
                        color: $light-blue;
                    }
                    .featured-product-link {
                        display: block;
                        width: 100%;
                        overflow: hidden;
                        text-transform: uppercase;
                        border-color: $lighter-grey;
                        border-width: 1px 0 0;
                    }
                    &:hover {
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                        .featured-product-link {
                            color: $white;
                            background-color: $light-blue;
                        }
                    }
                }
                .next-arrow, .prev-arrow {
                    position: absolute;
                    top: 50%;
                    z-index: 10;
                    margin-top: - rem(16);
                    color: $light-blue;
                    font-size: rem(24);
                    cursor: pointer;
                    @media (min-width: $md-breakpoint) {
                        font-size: rem(32);
                    }
                }
                .next-arrow {
                    right: - rem(20);
                    transform: scale(-1);
                    @media (min-width: $md-breakpoint) {
                        right: - rem(32);
                    }
                }
                .prev-arrow {
                    left: - rem(20);
                    @media (min-width: $md-breakpoint) {
                        left: - rem(32);
                    }
                }
                .prev-arrow[aria-disabled='true'] {
                    display: none !important;
                }
                .next-arrow[aria-disabled='true'] {
                    display: none;
                }
            }
        }
        &__signup {
            margin-top: rem(32);
            margin-bottom: rem(32);
            background-color: $light-blue;
            @include container($container, $gutter);
            @media (min-width: $xlg-breakpoint) {
                @include container($xl-container, $xl-gutter);
            }
            @media (max-width: $xs-breakpoint) {
                @include container($xs-container, $xs-gutter);
            }
            #mc_signup {
                padding: rem(32);
                @include container($container, $gutter);
            }
            h3 {
                margin: 1rem 1rem 2.5rem;
                color: $white;
                font-size: rem(20);
                text-align: center;
            }
            .control {
                margin-bottom: rem(16);
            }
            @media all and (min-width: $sm-breakpoint) {
                .fieldset {
                    display: flex;
                    align-items: flex-start;
                    flex-flow: row;
                }
                .control {
                    flex-basis: 33%;
                }
                .action-buttons {
                    width: 180px;
                }
            }
            .btn--orange {
                height: rem(60); 
                @media all and (max-width: $sm-breakpoint) {
                    width: 100%; 
                }
            }
            .social {
                padding-bottom: rem(32);
                color: $white;
                text-align: center;
                background-color: $light-blue;
                span {
                    display: block;
                    padding: rem(12);
                    font-size: rem(12);
                    vertical-align: super;
                    @media all and (min-width: $sm-breakpoint) {
                        display: inline-block;
                    }
                }
                &-icon {
                    background-color: $white;
                }
                i {
                    color: $light-blue;
                }
            }
        }
        &__backup {
            margin-top: rem(32);
            margin-bottom: rem(32);
            @include container($container, $gutter);
            @media (min-width: $xlg-breakpoint) {
                @include container($xl-container, $xl-gutter);
            }
            .btn {
                position: relative;
                text-align: center;
                transition: none;
                @include chevron(chevron-left, 8px, 2px, $light-blue, left, $white);
                .chevron-left {
                    position: absolute;
                    top: 50%;
                    left: rem(32);
                    margin-top: -8px;
                }
                &:hover {
                    .chevron-left {
                        &:before {
                            border-right-color: $white;
                        }
                        &:after {
                            border-right-color: $light-blue;
                        }
                    }
                }
                @media all and (max-width: 400px) {
                    width: calc(100% - 60px);
                }
            }
            .back-to-top {
                position: absolute;
                right: rem(32);
                margin-top: rem(8);
                @media all and (min-width: $sm-breakpoint) {
                    right: rem(32);
                }
                @include chevron(chevron-up, 8px, 2px, $light-blue, up, $white);
                .chevron-up {
                    position: relative;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    background-color: $white;
                    border: 2px solid $light-blue;
                    border-radius: 50%;
                    &:before {
                        bottom: 15px;
                        left: 11px;
                    }
                    &:after {
                        bottom: 13px;
                        left: 11px;
                    }
                }
                &:hover {
                    .chevron-up {
                        background-color: $light-blue;
                        &:before {
                            border-bottom-color: $white;
                        }
                        &:after {
                            border-bottom-color: $light-blue;
                        }
                    }
                }
            }
        }
    }
}