.categories-strip {
    position: relative;
    z-index: 2;
    @include container($container, 0);

    &--mobile {
        @media all and (min-width: $md-breakpoint) {
            display: none;
        }
    }

    &--desktop {
        display: none;
        @media all and (min-width: $md-breakpoint) {
            display: block;
        }
    }

    &__container {
        @media all and (min-width: $md-breakpoint) {
            display: flex;
            align-items: baseline;
            width: 100%;
        }
        a {
            position: relative;
            width: 16.66%;
            min-height: rem(180);
            font-family: $helvetica-bold;
            font-size: rem(12);
            text-align: center;
            text-decoration: none;
            background-color: rgba($white, 0.9);
            transition: background 0.3s;

            &:hover {
                background-color: rgba(241, 239, 239, 0.9);
            }

            .category-dining &{
                width:50%;
            }
            .category-occassional &{
                width:33.33%;
            }

            .content {
                position: absolute;
                bottom: rem(45);
                width: 100%;
                i {
                    display: block;
                    margin-bottom: rem(20);
                    font-variant: normal;

                    &:before{
                        display: block;
                        font-variant: normal;
                        font-family: icomoon !important;
                        font-size:rem(35);
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1;
                        text-transform: none;
                        speak: none;
                    }

                    &.custom-recliner,
                    &.custom-recliners {
                        &:before{
                            font-size: rem(50);
                            content: '\e909';

                            /*@media all and (min-width: $sm-breakpoint) {
                                font-size:rem(25);
                            }
                            @media all and (min-width: $md-breakpoint) {
                                font-size:rem(50);
                            }*/

                            .ct-view-homepage_top_content &{
                                font-size:rem(50);
                            }
                        }
                    }
                    &.custom-suite,
                    &.custom-suites{
                        &:before{
                            font-size:rem(40);
                            content: '\e908';

                            /*@media all and (min-width: $sm-breakpoint) {
                                font-size:rem(20);
                            }
                            @media all and (min-width: $md-breakpoint) {
                                font-size:rem(40);
                            }*/

                            .ct-view-homepage_top_content &{
                                font-size:rem(60);
                            }
                        }
                    }
                    &.custom-sofa,
                    &.custom-sofas {
                        &:before{
                            font-size: rem(43);
                            content: '\e90a';

                            /*@media all and (min-width: $sm-breakpoint) {
                                font-size:rem(22);
                            }
                            @media all and (min-width: $md-breakpoint) {
                                font-size:rem(43);
                            }*/

                            .ct-view-homepage_top_content &{
                                font-size:rem(43);
                            }
                        }
                    }
                    &.custom-chair,
                    &.custom-chairs {
                        &:before{
                            font-size: rem(56);
                            content: '\e90c';

                            /*@media all and (min-width: $sm-breakpoint) {
                                font-size:rem(28);
                            }
                            @media all and (min-width: $md-breakpoint) {
                                font-size:rem(56);
                            }*/

                            .category-dining &{
                                font-size: rem(56);
                            }
                            .ct-view-homepage_top_content &{
                                font-size:rem(56);
                            }
                        }
                    }
                    &.custom-lift-chairs {
                        &:before{
                            font-size: rem(56);
                            content: '\e907';

                            /*@media all and (min-width: $sm-breakpoint) {
                                font-size:rem(28);
                            }
                            @media all and (min-width: $md-breakpoint) {
                                font-size:rem(56);
                            }*/

                            .ct-view-homepage_top_content &{
                                font-size:rem(56);
                            }
                        }
                    }
                    &.custom-sideboard {
                        &:before{
                            font-size: rem(40);
                            content: '\e90d';

                            /*@media all and (min-width: $sm-breakpoint) {
                                font-size:rem(20);
                            }
                            @media all and (min-width: $md-breakpoint) {
                                font-size:rem(40);
                            }*/

                            .ct-view-homepage_top_content &{
                                font-size:rem(40);
                            }
                        }
                    }
                    &.custom-footstools-ottomans{
                        &:before{
                            font-size: rem(40);
                            content: '\e906';

                            /*@media all and (min-width: $sm-breakpoint) {
                                font-size:rem(20);
                            }
                            @media all and (min-width: $md-breakpoint) {
                                font-size:rem(40);
                            }*/

                            .ct-view-homepage_top_content &{
                                font-size:rem(40);
                            }
                        }
                    }
                    &.custom-nordic,
                    &.custom-nordic-collection {
                        &:before{
                            font-size: rem(60);
                            content: '\e90e';

                            /*@media all and (min-width: $sm-breakpoint) {
                                font-size:rem(30);
                            }
                            @media all and (min-width: $md-breakpoint) {
                                font-size: rem(60);
                            }*/

                            .ct-view-homepage_top_content &{
                                font-size:rem(60);
                            }
                        }
                    }
                    &.custom-tables {
                        &:before{
                            font-size: rem(46);
                            content: '\e90b';

                            /*@media all and (min-width: $sm-breakpoint) {
                                font-size:rem(23);
                            }
                            @media all and (min-width: $md-breakpoint) {
                                font-size: rem(46);
                            }*/

                            .category-dining &{
                                font-size: rem(46);
                            }
                            .ct-view-homepage_top_content &{
                                font-size:rem(46);
                            }
                        }
                    }
                    &.custom-coffee-tables{
                        &:before{
                            font-size: rem(50);
                            content: '\e912';

                            /*@media all and (min-width: $sm-breakpoint) {
                                font-size:rem(25);
                            }
                            @media all and (min-width: $md-breakpoint) {
                                font-size: rem(50);
                            }*/

                            .category-occassional &{
                                font-size: rem(50);
                            }
                            .ct-view-homepage_top_content &{
                                font-size:rem(50);
                            }
                        }
                    }
                    &.custom-lamp-side-tables{
                        &:before{
                            font-size: rem(53);
                            content: '\e911';

                            /*@media all and (min-width: $sm-breakpoint) {
                                font-size:rem(26);
                            }
                            @media all and (min-width: $md-breakpoint) {
                                font-size: rem(53);
                            }*/

                            .category-occassional &{
                                font-size: rem(53);
                            }
                            .ct-view-homepage_top_content &{
                                font-size:rem(53);
                            }
                        }
                    }
                    &.custom-entertainment-units{
                        &:before{
                            font-size: rem(40);
                            content: '\e90d';

                            /*@media all and (min-width: $sm-breakpoint) {
                                font-size:rem(20);
                            }
                            @media all and (min-width: $md-breakpoint) {
                                font-size: rem(40);
                            }*/

                            .category-occassional &{
                                font-size: rem(40);
                            }
                            .ct-view-homepage_top_content &{
                                font-size:rem(40);
                            }
                        }
                    }
                }
            }
        }

        &.total-strips-6 a{
            width: 16.66%;
        }
        &.total-strips-5 a{
            width: 20%;
        }
        &.total-strips-4 a{
            width: 25%;
        }
        &.total-strips-3 a{
            width: 33.33%;
        }
        &.total-strips-2 a{
            width: 50%;
        }
        &.total-strips-1 a{
            width: 100%;
        }
    }
}
#scroll{
    position:absolute;
    bottom:0;

    @media all and (min-width: $md-breakpoint) {
        bottom:rem(150);
    }
}