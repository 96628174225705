.ct-listing-generic {
    background-color: $lighter-grey;
    .block-title {
        padding-top: rem(80);
        margin-bottom: rem(60);
        @include container($container, $gutter);
        h1 {
            color: $light-blue;
            font-family: $helvetica-light;
            font-size: rem(32);
            font-weight: 300;
        }
    }
    .text-before {
        margin-bottom: rem(85);
        color: $dark-grey2;
        font-size: rem(14);
        line-height: 1.57;
        @include container($container, $gutter);
        h1 {
            margin-top: 0;
            color: $dark-grey2;
            font-family: $helvetica;
            font-size: rem(20);
            font-weight: 500;
            line-height: 1.5;
            @media all and (min-width: $md-breakpoint) {
                float: left;
                width: 33.33%;
                padding-right: rem(15);
                margin-right: rem(10);
            }
        }
        p {
            margin-top: rem(22);
        }
        a{
            color:#7297A9;
            text-decoration:underline;
        }
    }
    .contents-items {
        @media all and (min-width: $sm-breakpoint) {
            display: flex;
            flex-flow: row wrap;
            margin-right: rem(-15);
            margin-left: rem(-15);
        }
        &__container {
            @include container($container, 10);
            @media all and (min-width: $sm-breakpoint) {
                @include container($container, 20);
            }
        }
        > div {
            background-color: $white;
            @media all and (min-width: $sm-breakpoint) {
                flex: 1 0 calc(50% - 30px);
                // IE FIX
                flex-basis: calc(50% - 30px);
                margin: 0 rem(15) rem(30);
            }
            @media all and (min-width: $md-breakpoint) {
                flex: 1 0 calc(33.33% - 30px);
                // IE FIX
                flex-basis: calc(33.33% - 30px);
            }
            img {
                display: block;
                width: 100%;
            }
        }
        .content-item {
            transition:all 0.3s;

            &:hover{
                box-shadow:0 0 10px 0 rgba(0, 0, 0, 0.1);
            }
            &--hidden {
                visibility: hidden;
            }
            &__text {
                padding: rem(30);
                color: $dark-grey2;
                font-size: rem(14);
                line-height: 1.57;
                h2 {
                    margin-top: 0;
                    margin-bottom: rem(23);
                    color: $light-blue;
                    font-family: $helvetica-light;
                    font-size: rem(20);
                    line-height: 1.4;
                }
            }
        }
    }

}