.page-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: calc(#{$header-height} + #{$header-nav-storelocator}) ;
    color: $bright-blue;
    background-color: $white;
    border-bottom: 1px solid rgba($lighter-grey, 1);
    @media all and (min-width: $md-breakpoint) {
        height: $full-header-height;
        .content {
            max-width: rem(1600);
            padding: rem(32) rem(32) 0;
            margin: auto;
            @media all and (min-width: $xlg-breakpoint) {
                padding-bottom: rem(32);
            }
        }
    }

    .logo {
        display: none;
        @media all and (min-width: $md-breakpoint) {
            display: inline-block;
            margin-top: - rem(16);
        }
        @media all and (min-width: $xlg-breakpoint) {
            margin-top: rem(32);
        }
    }

    .floating-logo {
        z-index: 12;
        padding-left: rem(24);
        outline: none;
        img {
            width: auto;
            height: rem(50);
            margin-top: rem(10);

            @media all and (max-width: $xs-breakpoint) {
                max-width: 180px;
            }
        }
        @media all and (min-width: $md-breakpoint) {
            display: none;
            opacity: 0;
        }
    }

    .search-trigger {
        position: absolute;
        top: 0;
        right: rem(65);
        z-index: 12;
        width: rem(40);
        font-size: rem(32);
        line-height: $header-height;
        text-align: center;
        cursor: pointer;
        @media all and (min-width: $md-breakpoint) {
            display: none;
        }
    }

    .custom-icon-search:before {
        content: '\e919';
    }

    .nav-toggle {
        position: absolute;
        top: 0;
        right: rem(20);
        width: rem(40);
        font-size: rem(18);
        line-height: $header-height;
        text-align: center;
        cursor: pointer;
        @media all and (min-width: $md-breakpoint) {
            display: none;
        }

        &.open {
            i:before {
                content: '\e90f';
            }
        }
    }

    .header {
        position: relative;
        .free-comfort-guide {
            position: absolute;
            bottom: rem(36);
            z-index: 8;
            width: auto;
            height: rem(40);
            padding: rem(12) rem(32);
            font-size: rem(15);
            text-transform: uppercase;
            border: none;
            @media all and (max-width: $sm-breakpoint-max) {
                display: none;
            }
            @media all and (min-width: $md-breakpoint) {
                right: rem(360);
                bottom: auto;
            }
            @media all and (min-width: $xlg-breakpoint) {
                right: rem(32);
                bottom: rem(40);
            }
        }
        &.panel {
            .navigation {
                display: none;
            }
            @media all and (min-width: $md-breakpoint) {
                display: block;
                padding: rem(20) 0;
                font-size: rem(12);
                border-top: 5px solid $bright-blue;
                border-bottom: 1px solid $lighter-grey;
                .submenu {
                    display: none;
                    list-style: none;
                    @media all and (min-width: $md-breakpoint) {
                        position: absolute;
                        top: rem(24) !important;
                        z-index: 9;
                        min-width: 230px;
                        padding: rem(18) rem(30);
                        line-height: rem(24);
                        text-align: left;
                        text-transform: uppercase;
                        background-color: $white;
                        border: 1px solid rgba(240, 244, 246, 0.15);
                        box-shadow: 0 1px 12px rgba(0, 0, 0, 0.175);

                        &:before {
                            position: absolute;
                            top: -10px;
                            left: 50%;
                            width: 0;
                            height: 0;
                            border-right: 11px solid transparent;
                            border-bottom: 11px solid $white;
                            border-left: 11px solid transparent;
                            content: '';
                            transform: translate(-50%, 0);
                        }
                    }
                }
                .navigation {
                    display: block;
                    max-width: rem(1600);
                    padding: 0 rem(32);
                    margin: auto;
                    color: $mid-grey;
                    transition: all 0.3s ease-in-out;
                    > ul li.level1 {
                        &.parent {
                            padding-bottom: rem(24);
                            margin-bottom: - rem(24);
                            > a {
                                position: relative;
                                padding-right: rem(24);
                                &:after {
                                    position: absolute;
                                    top: 0;
                                    right: initial;
                                    margin-top: - rem(8);
                                    color: $bright-blue;
                                    font-family: icomoon !important;
                                    font-size: rem(22);
                                    content: '\e916';
                                    // transform: rotateZ(-90deg);
                                    transition: all 0.2s;
                                }
                            }
                            &:hover {
                                > a {
                                    &:after {
                                        // transform: rotateZ(90deg);
                                        transform: scaleY(-1);
                                    }
                                    + .submenu {
                                        // left: - rem(16);
                                        // left: 0;
                                        position: relative;
                                        top: 0 !important;
                                        display: block !important;
                                        min-width: 200px;
                                        padding: 0;
                                        margin: 0 0 0 0.5rem;
                                        // margin-left: 12.5rem;
                                        border: 0;
                                        box-shadow: unset;
                                        a {
                                            &:hover {
                                                color: $blue;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    > ul {
                        padding: 0;
                        margin: 0;
                        > li {
                            position: relative;
                            display: inline-block;
                            list-style: none;
                            > a {
                                position: relative;
                                display: block;
                                padding: 0 rem(16);
                                font-family: $helvetica;
                                font-size: rem(12);
                                text-align: center;
                                text-transform: uppercase;
                                span {
                                    text-decoration: none;
                                }
                                &:hover {
                                    color: $blue;
                                }
                            }
                            &.parent {
                                padding-bottom: rem(24);
                                margin-bottom: - rem(24);
                                > a {
                                    position: relative;
                                    padding-right: rem(24);
                                    &:after {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        margin-top: - rem(8);
                                        color: $bright-blue;
                                        font-family: icomoon !important;
                                        font-size: rem(22);
                                        content: '\e916';
                                        transition: all 0.2s;
                                    }
                                }
                                &:hover {
                                    > a {
                                        &:after {
                                            transform: scaleY(-1);
                                        }
                                        + .submenu {
                                            left: - rem(16);
                                            display: block !important;
                                            a {
                                                &:hover {
                                                    color: $bright-blue;
                                                }
                                            }
                                        }
                                    }
                                }     
                            }
                            &.first {
                                a {
                                    padding-left: 0;
                                }
                            }
                            &.contact, &.sign-in, &.store-locator, &.other-suppliers {
                                float: right;
                                > a {
                                    padding: 0;
                                    margin-left: rem(32);
                                    &:before {
                                        float: left;
                                        width: rem(32);
                                        margin-top: - rem(12);
                                        margin-right: rem(8);
                                        color: $bright-blue;
                                        font-family: icomoon !important;
                                        font-size: rem(32);
                                        content: '\e913';
                                        transition: all 0.2s;
                                    }
                                    &:hover {
                                        color: $blue;
                                    }
                                }
                            }
                            &.sign-in > a:before {
                                content: '\e91b';
                            }
                            &.store-locator > a:before {
                                content: '\e91c';
                            }
                            &.other-suppliers {
                                > ul.submenu {
                                    > li.parent {
                                        > a span {
                                            font-family: HelveticaNeueBold;
                                        }
                                        ul.submenu {
                                            margin-left: 1.5rem;
                                            list-style: disc;
                                        }
                                    }
                                }
                                > a:before {
                                    display: none;
                                    content: '\e949';
                                }
                                > a:after {
                                    right: initial;
                                }
                            }
                        }
                    }
                }
            }
            // .navigation {
            //     @media all and (min-width: $md-breakpoint) {
            //         padding-right: rem(195);
            //     }

            //     @media all and (min-width: $lg-breakpoint) {
            //         padding-right: rem(300);
            //     }
            // }
        }

        &.links {
            display: none;
            padding: 0;
            margin: 0;
            a {
                text-decoration: none;
            }
        }
    }

    .block-search {
        position: absolute;
        top: $header-height;
        right: 0;
        left: 0;
        z-index: 12;
        display: none;
        height: auto;
        background-color: $white;
        opacity: 0;
        transition: all 0.3s;
        &.open {
            display: block;
            opacity: 1;
        }
        @media all and (max-width: $sm-breakpoint-max) {
            // .block-content.nz .form.minisearch .field.search {
            margin-top: 13px;
            // }
        }
        @media all and (min-width: $md-breakpoint) {
            position: static;
            display: inline-block;
            float: right;
            opacity: 1;
        }

        .minisearch {
            background: $white;
            @media all and (max-width: $sm-breakpoint-max) {
                margin-top: -13px;
                border-bottom: 1px solid $lighter-grey;
            }
        }

        .search {
            width: 100%;
            margin: 0;
            @media all and (min-width: $md-breakpoint) {
                position: relative;
                &:after {
                    position: absolute;
                    top: 1px;
                    right: rem(8);
                    z-index: 2;
                    color: $bright-blue;
                    font-family: icomoon;
                    font-size: rem(32);
                    background-color: $white;
                    content: '\e919';
                }
            }
        }

        .block-title,
        .nested,
        .actions,
        .label {
            display: none;
        }

        .mst-searchautocomplete {
            &__close {
                display: none;
            }
            &__autocomplete {
                display: none;
                &._active {
                    display: block;
                }
            }
            &__wrapper {
                z-index: 15;
                padding: rem(20);
                overflow-y: scroll;
                background: $white;
                @media all and (max-width: $sm-breakpoint-max) {
                    position: fixed;
                    top: rem(145);
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
                @media all and (min-width: $md-breakpoint) {
                    position: absolute;
                    width: rem(300);
                    height: rem(400);
                    border: 1px solid $bright-blue;
                    border-top: none;
                }
                @media all and (min-width: $xlg-breakpoint) {
                    width: rem(400);
                }
            }
            &__index > ul {
                padding: 0;
                list-style: none;
            }
            &__item {
                display: flex;
                align-items: center;
                flex: 1;
                padding: rem(10) 0;
                list-style: none;
                border-bottom: 1px solid $lighter-grey;
                img {
                    width: rem(130);
                    margin-right: rem(10);
                }
                .description {
                    display: none;
                }
                .meta {
                    color: $light-blue;
                    font-family: $helvetica-light;
                    font-size: rem(20);
                }
                .store {
                    display: none;
                }
            }
        }
        .close-search {
            position: fixed;
            top: 34px;
            right: rem(65);
            width: rem(40);
            font-size: rem(18);
            line-height: $header-height;
            text-align: center;
            cursor: pointer;
            background-color: $white;
            // &.nz {
            // top: 34px;
            // }
            @media all and (min-width: $md-breakpoint) {
                display: none;
            }
        }
        .search-label, .submit-button {
            @media all and (max-width: $sm-breakpoint-max) {
                right: rem(30);
            }
            position: absolute;
            top: 50%;
            right: rem(30);
            z-index: 1;
            padding: 0;
            color: $blue;
            font-family: $helvetica-bold;
            font-size: rem(14);
            transform: translateY(-50%);
            @media all and (min-width: $md-breakpoint) {
                right: rem(40);
            }
        }
        .search-label {
            display: none;
        }
        .submit-button {
            display: block;
            border: none;
            @media all and (min-width: $md-breakpoint) {
                right: rem(8);
                width: auto;
            }
        }
        input {
            @include placeholder($light-grey);
            padding: 0 rem(30) 0 rem(30);
            margin: 0;
            font-family: $helvetica-light;
            font-size: rem(16);
            background-color: transparent;

            @media all and (min-width: $md-breakpoint) {
                width: rem(300);
                height: rem(40);
                padding: 0 rem(40) 0 rem(20);
                color: $dark-grey;
                font-size: rem(12);
                border: 1px solid $bright-blue;
            }
            @media all and (min-width: $xlg-breakpoint) {
                width: rem(400);
            }
            &:focus {
                outline: none;
            }
            &::-ms-clear {
                display: none;
            }
        }
    }
}
