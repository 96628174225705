.mfp-bg {
    background-color: #00679B;
    opacity: 0.9;
}

.mfp-content {
    min-height: 100vh;
    padding: rem(50) 0;

    .container {
        padding: 0;
    }
}

//.mfp-close {
//    top: rem(10) !important;
//    right: rem(10);
//    color: $white !important;
//    font-family: $helvetica-light;
//    font-size: rem(60);
//    opacity: 1;
//
//    @media all and (min-width: $sm-breakpoint) {
//        right: rem(20);
//    }
//}

.mfp-close {
    top: rem(38) !important;
    right: rem(20) !important;
    width: rem(32);
    color: transparent !important;
    &:before,
    &:after {
        position: absolute;
        left: rem(9);
        width:rem(2);
        height:rem(32);
        background: $white;
        content: ' ';
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}

//.mfp-video {
//    .mfp-content {
//        max-width: 80%;
//        min-height: 0;
//        padding: 0;
//    }
//    .mfp-close {
//        position: fixed;
//        top: rem(10) !important;
//        right: rem(10);
//        color: $white !important;
//        font-family: $helvetica-light;
//        font-size: rem(60);
//        opacity: 1;
//    }
//}

.mfp-video {
    .mfp-content {
        max-width: 80%;
        min-height: 0;
        padding: 0;
    }
    .mfp-close {
        top:rem(-50) !important;
        right:0 !important;
        width:auto !important;

        @media all and (min-width: $sm-breakpoint){
            top:0 !important;
            right:rem(-50) !important;
        }
    }
}

.modal {
    text-align: center;

    &__logo {
        margin-bottom: rem(35);
    }

    &__header {
        height: rem(190);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @media all and (min-width: $sm-breakpoint) {
            height: rem(330);
        }
    }

    &__content {
        padding: rem(40) rem(30) rem(90) rem(30);

        @media all and (min-width: $sm-breakpoint) {
            padding: rem(50) rem(90) rem(90);
        }
    }

    &__footer {
        width: 100%;
        font-size: rem(14);
    }

    .page-title {
        width: rem(270);
        margin: 0 auto rem(70) auto !important;

        @media all and (min-width: $sm-breakpoint) {
            width: auto;
        }
    }

    .fieldset {
        width: calc(100% + #{rem(20)});
        margin-bottom: 0;
    }

    .note {
        margin-bottom: rem(45);
        color: #666666;
        font-size: rem(14);
        line-height: 1.57;
    }

    .actions-toolbar {
        margin-top: rem(60);
        margin-bottom: rem(70);

        @media all and (min-width: $sm-breakpoint) {
            justify-content: center !important;
        }
    }

    a {
        color: #0A5389;
    }
}

.modal-inner {
    background-color: #F6F6F6;

    .btn {
        @media all and (max-width: $md-breakpoint) {
            width:100%;
        }
    }
}