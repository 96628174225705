
/*------------------------------------*\
    PREFERENCES
\*------------------------------------*/
.preferences {
    .slocator-nav-btn,
    .pref-tabs-title,
    .pref-tabs-details,
    .pref-header-content {
        display: none;
    }

    @media all and (min-width: $sm-breakpoint) {
        width: rem(533);
        margin: 0 auto;
    }

    .pref-search {
        &:after {
            display: block;
            height: 1px;
            margin-top: rem(38);
            background: #E7E7E7;
            content: '';

            @media all and (max-width: $sm-breakpoint - 1) {
                margin-right: - rem($gutter);
                margin-left: - rem($gutter);
            }

            @media all and (min-width: $md-breakpoint) {
                margin-top: rem(48);
            }
        }

        @media all and (min-width: $md-breakpoint) {
            input {
                height: rem(62);
                font-size: rem(16);

                &:focus {
                    font-size: rem(16);
                }
            }

            .btn {
                width: rem(62);
                height: rem(62);
                font-size: rem(16);

                &:before {
                    font-size: rem(32);
                }
            }
        }
    }

    h4 {
        color: $mid-grey;

        @media all and (min-width: $md-breakpoint) {
            margin-top: rem(38);
            font-size: rem(22);
        }
    }

    .pref-location {
        @media all and (min-width: $md-breakpoint) {
            padding-top: rem(22);
            padding-bottom: rem(22);

            .city {
                font-size: rem(18);
                line-height: rem(24);
            }
        }
    }
}

.pref-title {
    padding: rem(25) rem(25) rem(45);
    margin: 0;
    color: $light-blue;
    font-size: rem(22);
    text-align: center;

    @media all and (min-width: $md-breakpoint) {
        font-size: rem(32);
    }
}

.pref-tabs-change {
    position: relative;
}

.pref-loading {
    position: absolute;
    top: rem(90);
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: none;
    background: $white url('../images/loader-2.gif') no-repeat top center;
    background-size: 150px;
    // opacity: 0.8;
}
