.sendfriend-product-send {

    .page-main {
        //@include container($container, $gutter);
    }

    h1{
        &.page-title{
            @include container($container, $gutter);

            @media all and (max-width: $sm-breakpoint) {
                padding-top:60px;
                margin-top:0;
            }
        }
    }
    form{
        @include container($container, $gutter);
    }
    fieldset{
        legend{
            padding-top:40px;
            margin-top:0;
            margin-bottom:10px;
        }

        &.recipients{
            padding-right:10px;
            padding-left:10px;

            legend{
                margin-right:0;
                margin-left:0;
            }
        }
    }

    label.label{
        z-index:100;
    }
    a.btn{
        text-align:center;
    }

    .btn-toolbar{
        padding:30px 0 60px;
        overflow:hidden;

        &>div{
            float:left;
            width:33.3333%;
            margin:20px 0 20px;

            &:nth-child(1){
                padding:0 20px 0 0;
            }
            &:nth-child(2){
                padding:0 10px;
                text-align:center;
            }
            &:nth-child(3){
                padding:0 0 0 20px;
                text-align:right;
            }

            @media all and (max-width: $sm-breakpoint) {
                float:none;
                width:100%;
                padding:0 !important;

                a,button{
                    width:100%;
                }
            }
        }
    }
    .remove-toolbar{
        .btn{
            margin:40px 0 25px;
        }
    }
}