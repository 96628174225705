.video-embed {
    padding-bottom: rem(100);
    background-color: $lighter-grey;
    &__container {
        padding-top: rem(70);
        font-size: rem(14);
        line-height: 1.57;
        @include container($container, $gutter);
        @media all and (min-width: $sm-breakpoint) {
            text-align: center;
        }
        h1,
        h2{
            margin-top: 0;
            margin-bottom: rem(36);
            font-family: $helvetica-light;
            font-size: rem(24);
            font-weight: 300;
            text-align: center;
            @media all and (min-width: $sm-breakpoint) {
                font-size: rem(32);
            }
        }
        p:last-of-type {
            position: relative;
            height: 0;
            padding-bottom: 56.25%;
            margin-right: -20px;
            margin-left: -20px;
            @media all and (min-width: $sm-breakpoint) {
                margin-right: 0;
                margin-left: 0;
            }
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin-top: rem(40);
            }
        }

    }
}