.cms-contact-us {

    p {
        a {
            color: $light-blue;
        }
    }

    .actions-toolbar {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: rem(30);
        text-align: center;

        @media all and (min-width: $sm-breakpoint) {
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 0;
            text-align: left;
        }

        .primary {
            @media all and (max-width: $md-breakpoint) {
                display: block;
                width: 100%;
            }
            max-width: 100%;
        }

        .secondary {
            display: none;
        }
    }

    .webforms {

        position: relative;
        margin: 0 0 rem(68);

        .webforms-description-text {
            @media all and (max-width: $md-breakpoint) {
                margin: rem(74) 0 0;
            }
            margin: rem(70) 0 0;
        }

        .webforms-description-text {
            margin-bottom: rem(30);
            color: $dark-grey;
            font-family: $helvetica-light;
            font-size: rem(24);
        }

        .recaptcha {
            display: inline-block;
            float: none;
            width: 100%;
            margin: 0;
            margin-bottom: rem(40);
            text-align: left;

            @media all and (min-width: $sm-breakpoint) {
                margin-bottom: 0;
                text-align: left;
            }
        }

        .g-recaptcha {
            @media all and (min-width: $sm-breakpoint) {
                position:static;
                left:0;
                margin-top: rem(14);
                transform: none;
            }

            position:relative;
            left:50%;
            display: inline-block;
            margin-top: rem(5);
            transform: scale(0.89) translate(-50%, 0);
            transform-origin: left;
        }

        .btn {
            @media all and (max-width: $md-breakpoint) {
                margin-bottom: rem(24);
            }
            margin-bottom: rem(8);
        }
    }

    .footer-coloured-blocks {
        @media all and (max-width: $md-breakpoint) {
            margin: 0 rem(-20) rem(100);
        }
    }
}

.cms-contact-thanks {
    .page-main {
        @extend .container;
        padding-top: rem(50);
        font-family: $helvetica-light;
        font-size: rem(20);
        text-align: center;

        @media all and (min-width: $sm-breakpoint) {
            padding-top: rem(140);
        }
    }

    .page-title {
        margin-bottom: rem(50);
    }

    .thanks-button {
        margin-top: rem(70);
        margin-bottom: rem(50);

        @media all and (min-width: $sm-breakpoint) {
            margin-bottom: rem(150);
        }
    }
}