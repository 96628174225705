
$product-modal-padding-sm: $gutter;
$product-modal-padding-lg: 90;
.price-value-proposition {
    display: none;
}

.catalog-product-view {
    .product__container {
        .price-value-proposition {
            display: block;
            margin-top: 1.5rem;
            color: #9F3834;
        }
    }
    
    .custom-question.tooltip {
        display: none;
    }
    .addthis-smartlayers,
    #at4-follow,
    #at4-share,
    #at4-thankyou,
    #at4-whatsnext {
        display: none !important;
    }

    .page-main {
        // @media all and (min-width: $md-breakpoint) {
        //     top: -$full-header-height;
        //     margin-bottom: -240px;
        // }
        // position: relative;
        // margin-bottom: -1px;
    }
    .product-info-main {
        position: relative;
    }
    .product {
        &__slider {
            position: relative;
            z-index: 0;
            min-height: rem(300);
            margin: 0;
            @media all and (max-width: $md-breakpoint - 1) {
                border-bottom: 1px solid #F5F5F5;
            }

            @media all and (min-width: $md-breakpoint) {
                border: 1px solid #F5F5F5;
            }

            .item {
                overflow: hidden;

                @media all and (max-width: $sm-breakpoint) {
                    display: flex;
                    align-items: center;
                    flex-flow: row nowrap;
                }

                img {

                    @media all and (max-width: 500px) {
                        position: relative;
                        left: 50%;
                        width: 100%;
                        height: auto;
                        max-width: none;
                        margin: auto;
                        transform: translateX(-50%);
                    }
                    max-width: 100%;
                    margin: auto;
                }
            }

            li.slick-slide {
                text-align: center;
            }
            li.slick-current {

            }
            img {
                @media all and (min-width: $md-breakpoint) {
                    width: 100%;
                    height: auto;
                }
                position: relative;
                left:50%;
                width: auto;
                height: rem(700);
                max-width: none !important;
                transform:translateX(-50%);
            }
            .next-arrow, .prev-arrow {
                position: absolute;
                top: 50%;
                z-index: 10;
                margin-top: rem(-20);
                color: $blue;
                font-size: rem(18);
                cursor: pointer;

                @media all and (min-width: $md-breakpoint) {
                    font-size: rem(35);
                }
            }
            .next-arrow {
                right: rem(20);
                transform: scale(-1);
            }
            .prev-arrow {
                left: rem(20);
            }

            .slick-dots {
                position: relative;
                top: rem(-24);
                padding: 0;
                margin: 0;
                text-align: center;
                list-style: none;

                @media all and (min-width: $md-breakpoint) {
                    display: none !important;
                }

                button {
                    display: none;
                }

                .slick-active:after {
                    background: $light-blue;
                }

                li {
                    display: inline-block;
                    padding: 0 rem(8);

                    &:after {
                        display: block;
                        width: 9px;
                        height: 9px;
                        background: #F5F5F5;
                        border-radius: 50%;
                        content: '';
                    }
                }
            }

        }

        &__thumb {
            padding: rem(38) 0;
            text-align: center;

            @media all and (max-width: $md-breakpoint) {
                display: none;
            }

            //.slick-track {
            //    display: inline-block;
            //    width: auto !important;
            //}

            .slick-current {
                .item {
                    border-color: $light-blue;
                }
            }

            .slick-slide {
                margin: 0 rem(12);
            }

            .item {
                padding: 0 rem(12);
                border: 1px solid #F5F5F5;
                &.is-video {
                    padding: 0;
                    .img-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 90px;
                        height: 74px;
                        background-size: cover;
                    }
                }
            }
        }


        &__details {
            @include container($xl-container, $gutter);
            @media all and (max-width: $md-breakpoint) {
                padding: 0;
                margin-bottom: rem(20);
            }
            display: flex;
            align-items: flex-start;
            //margin-bottom: rem(-30);
            margin-bottom: rem(48);

            &:before {
                @media all and (min-width: $md-breakpoint) {
                    position: absolute;
                    right: 0;
                    left: 0;
                    z-index: -1;
                    height: 75px;
                    background: #F5F5F5;
                    content: '';
                }
            }

            .detailed {
                width: 100%;
            }

            &-title {
                margin: rem(32) 0;
                color: $light-blue;
                font-family: $helvetica-light;
                font-size: rem(20);
                text-align: center;

                @media all and (min-width: $md-breakpoint) {
                    margin: rem(54) 0 rem(36);
                    font-size: rem(32);
                }

                @media all and (min-width: $xlg-breakpoint) {
                    margin: rem(84) 0 rem(50);
                }
            }
        }
        &__tabs {
            @media all and (max-width: $md-breakpoint) {
                flex-flow: row wrap;

                .data {
                    flex: 1 0 100%;
                }
            }
            display: flex;
            justify-content: center;
            .title {
                flex: 0 0 100%;

                @media all and (min-width: $md-breakpoint) {
                    flex: 0 0 rem(180);
                }

                @media all and (max-width: $md-breakpoint - 1) {
                    a {
                        position: relative;
                        display: block;
                        padding: rem(24) rem($gutter);
                        margin-bottom: rem(4);
                        color: $bright-blue;
                        font-size: rem(16);
                        font-weight: 500;
                        text-align: left;
                        // background: url('../images/arrow-grey.png') no-repeat right center;
                        background: #F5F5F5;
                        // border-bottom: 1px solid $light-black;

                        &:after {
                            @include icon();
                            position: absolute;
                            top: 50%;
                            right: rem($gutter);
                            display: inline-block;
                            color: $light-blue;
                            font-size: rem(16);
                            content: '\ea0a';
                            transform: translate(0, -50%);
                            transition: all 0.3s;
                        }

                        &.activeMobile {
                            &:after {
                                content: '\ea0b';
                            }
                        }
                    }
                }

                @media all and (min-width: $md-breakpoint) {
                    a {
                        display: block;
                        padding: rem(24) rem(15);
                        color: #4A4A4A;
                        font-size: rem(18);
                        text-align: center;
                        background-image: none;
                        border-bottom: 7px solid transparent;
                        transition: all 0.3s;

                        &:hover,
                        &.active {
                            color: $blue;
                            border-bottom: rem(7) solid $blue !important;
                        }
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
            .content {
                display: none;

                &.activeMobile {
                    transition: all 0.3s;

                    @media all and (max-width: $md-breakpoint) {
                        display: block;
                        padding: rem(20) rem($gutter) rem(40);
                        color: #666666;
                        font-size: rem(14);
                        line-height: 1.57;
                        border-bottom: rem(1) solid $light-black;

                        img {
                            display: block;
                            width: 100%;
                            margin-bottom: rem(50);
                        }
                    }
                }
            }
            &-content {
                @media all and (max-width: $md-breakpoint) {
                    display: none !important;
                }
                padding-top: rem(50);
                padding-right: rem(50);
                padding-bottom: rem(90);
                color: $dark-grey2;
                font-family: $helvetica-light;
                font-size: rem(14);
                font-weight: 300;
                line-height: 1.5;
                .headline {
                    font-size: rem(20);
                }
                img {
                    display: block;
                    width: 100%;
                    margin-bottom: rem(50);
                }

                .product_key_features {
                    margin-bottom: rem(30);
                    color: #666666;
                    font-size: rem(20);
                    font-weight: 300;
                    line-height: 1.5;
                }

                ul {
                    padding: 0;
                    margin: rem(10) rem(45) 0;
                    list-style: none;

                    li {
                        padding-left: rem(12);

                        &:before {
                            position: absolute;
                            width: rem(3);
                            height: rem(3);
                            margin: rem(10) 0 0 rem(-25);
                            background: #666666;
                            border-radius: 50%;
                            content: '';
                        }

                        a {
                            color: $light-blue;
                        }
                    }
                }

                .product.attribute.warranty ul {
                    //margin: rem(35) rem(45) rem(25);
                }
            }
        }
    }
    .video-container {
        display: none;
        @media all and (max-width: $md-breakpoint - 1) {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            padding: 2rem 0;
            color: $light-blue;
            font-size: rem(20);
            text-transform: uppercase;
            border-top: 1px solid #EFEFEF;
            border-bottom: 1px solid #EFEFEF;
            .custom-watch-video {
                margin-right: 1rem;
            }
        }
    }
    .value-proposition {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        padding: 0 2rem;
        margin-top: -50px;
        color: $white;
        background: #9F3834;
        @media all and (max-width: $md-breakpoint - 1) {
            position: unset;
            margin-top: 0;
        }
    }
    .custom-question.tooltip {
        position: relative;
        cursor: pointer;
        .tooltip-text {
            position: absolute;
            bottom: 125%;
            left: 0;
            z-index: 15;
            display: none;
            width: 25rem;
            padding: 0.5rem;
            color: #4A4A4A;
            font-family: $helvetica-light;
            font-size: rem(14);
            text-align: center;
            background-color: #FFFFFF;
            box-shadow: 0 1px 12px rgba(0, 0, 0, 0.175);
            @media all and (max-width: $md-breakpoint - 1) {
                right: -100px;
                left: initial;
                width: 200px;
            }
            &:before {
                position: absolute;
                bottom: -6px;
                left: 10px;
                z-index: 99993;
                width: 0;
                height: 0;
                border-right: 11px solid transparent;
                border-bottom: 11px solid #FFFFFF;
                border-left: 11px solid transparent;
                content: '';
                transform: translate(-50%, 0) rotate(180deg);
                @media all and (max-width: $md-breakpoint - 1) {
                    right: 39%;
                    left: initial;
                }
            }
        }
        &:hover {
            .tooltip-text {
                display: block;
            }
        }
    }
    .page-title-wrapper {
        @media all and (max-width: $sm-breakpoint) {
            text-align: center;
        }

        h1 {
            @media all and (min-width: $md-breakpoint) {
                font-size: rem(32);
            }
            // flex: 0 0 auto;
            // order: 1;
            // max-width: 70%;
            padding: rem(27) 0 rem(3);
            margin: 0 rem(14) 0 0;
            overflow: hidden;
            // color: $white;
            font-family: $helvetica-light;
            font-size: rem(22);
            font-weight: 300;
            line-height: 1.31;
            // text-overflow: ellipsis;
            // white-space: nowrap;
        }
        .product-info-stock-sku {
            display: none;
            flex: 0 0 auto;
            order: 2;
            font-family: $helvetica-light;
            font-size: rem(14);
            .type, .stock {
                display: none;
            }
        }
        .product-info-price {
            flex: 1 0 auto;
            order: 3;
            margin: rem(25) 0 rem(15);
            color: #4A4A4A;
            // font-family: $helvetica-light;
            font-size: rem(16);
        }
        
        span.price {
            display: flex;
            flex-direction: column;
            justify-content: left;
            margin-top: 1rem;
            &:before {
                margin-right: rem(10);
                // font-family: $helvetica-light;
                // font-size: rem(14);
                // content: 'From RRP';
            }
            div {
                &.fabric-container,
                &.leather-container {
                    display:flex;
                    align-items: center;
                    flex-direction: row;
                    padding: 0;
                    margin: 0.25rem 0;
                    div,
                    span {
                        margin-right: 0.5rem;
                    }
                    .leather-price,
                    .fabric-price {
                        display: flex;
                        align-items: center;
                        .now-price {
                            font-weight: 700;
                        }
                        .custom-question.tooltip {
                            display: block;
                            margin-left: 0.5rem;
                        }
                    }
                    &.has-discount {
                        .was-price {
                            margin-right: 0.5rem;
                            font-weight: 700;
                            text-decoration: line-through;
                        }
                        .now-price {
                            color: rgba(159, 56, 57, 1);
                            font-weight: 700;
                        }
                    }
                    .swatch-count {
                        display: none;
                    }
                }
                &.leather-container {
                    border-left: 1px solid #F6F6F6;
                }
            }
            &.leather-only {
                div.leather-container {
                    border-left: 0;
                }
            }
            span,
            strong {
                &.was-price {
                    font-weight: 700;
                    text-decoration: line-through;
                }
                &.now-price {
                    // color: rgba(159, 56, 57, 1);
                    font-weight: 700;
                }
            }
        }
        span.old-price.sly-old-price.no-display, span.old-price {
            display:none;
        }
        span.non-covered {
            &:before {
                margin-right: rem(10);
                // font-family: $helvetica-light;
                // font-size: rem(14);
                // content: 'Fabric from RRP';
            }
            span,
            strong {
                &.was-price {
                    font-weight: 700;
                    text-decoration: line-through;
                }
                &.now-price {
                    color: rgba(159, 56, 57, 1);
                    font-weight: 700;
                }
            }
        }
        span.covered {
            &:before {
                margin-right: rem(10);
                // font-family: $helvetica-light;
                // font-size: rem(14);
                // : 'From RRP';
            }
            span,
            strong {
                &.was-price {
                    font-weight: 700;
                    text-decoration: line-through;
                }
                &.now-price {
                    color: rgba(159, 56, 57, 1);
                    font-weight: 700;
                }
            }
        }

        &.secondary {
            @media all and (min-width: $md-breakpoint) {
                display: block;
            }
            @media all and (max-width: $md-breakpoint) {
                opacity: 1;
            }
            position: absolute;
            top: rem(-75);
            right: 0;
            //display: none;
            display: flex !important;
            align-items: center;
            height: rem(75);
            padding-top: rem(15);
            padding-left: 0;
            opacity: 0;
            transition: opacity 0.3s;

            @supports (-ms-ime-align:auto) {
                top: rem(-74);
            }

            h1 {
                @media all and (max-width: $sm-breakpoint) {
                    visibility: hidden;
                }

                flex: 2 1 auto;
                overflow: hidden;

                .base {
                    display: block;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space:nowrap;
                }
            }

            .product-info-price {
                @media all and (min-width: $sm-breakpoint) {
                    text-align: right;
                }
                //position: relative;
                //top: rem(-34);
                //display: block;
                flex: 1 0 auto;
                text-align: left;
            }

            .product-info-stock-sku {
                display: none;
            }

            &.show {
                opacity: 1;
            }
            &:before {
                position: absolute;
                top: 0;
                right: 100%;
                width: 1000%;
                height: rem(75);
                background-color: rgba($blue, 0.9);
                content: '';
            }
        }

        &.absolute-title-header {
            @media all and (max-width: $md-breakpoint) {
                position: absolute !important;
                top: 0 !important;
            }
        }
    }
    .secondary-mob {
        @media all and (min-width: $md-breakpoint) {
            display: none !important;
        }
        position: absolute;
        top: -70px;
        right: 0;
        display: none;
        height: rem(75);
        padding-top: rem(15);
        padding-left: 0;
        opacity: 1;
        transition: opacity 0.3s;
        &:before {
            position: absolute;
            top: 0;
            right: 100%;
            width: 1000%;
            height: rem(75);
            background-color: rgba($blue, 0.9);
            content: '';
        }

        .product-info-price {
            display: block;
            padding:rem(24) 0;
            font-size: rem(24);
            text-align: left;
        }

        &:before {
            position: absolute;
            top: 0;
            right: 100%;
            width: 1000%;
            height: rem(75);
            background-color: rgba($blue, 0.9);
            content: '';
        }
    }
    .product-options-wrapper {
        // @media all and (min-width: $md-breakpoint) {
        //     top: -80px;
        // }
        position: relative;

        .product {
            &__options-tabs {
                // border: 1px solid #F7F7F7;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    font-family: $helvetica-light;
                    border: 1px solid #EFEFEF;
                    // padding: rem(20);

                    &:first-child {
                        // border-bottom: 1px solid #EFEFEF;
                        margin-bottom: 1rem;
                    }

                    &:hover {
                        color: $light-blue;
                    }

                    &:after {
                        @include icon();
                        position: absolute;
                        top: 50%;
                        right: 0;
                        display: inline-block;
                        margin-right: 0.5rem;
                        color: $light-blue;
                        font-size: rem(18);
                        content: '\e916';
                        transform: translate(0, -50%) rotate(-90deg);
                        transition: all 0.3s;

                        @media all and (min-width: $sm-breakpoint) {
                            font-size: rem(24);
                        }
                    }

                    &.active {
                        // height: rem(80);
                        // background-color: #F0F0F0;
                    }

                    &.disabled {
                        color: #E8E8E8;
                        cursor: not-allowed;
                    }
                    .numbering {
                        padding: rem(20) rem(26);
                        color: $white;
                        font-size: rem(20);
                        background: $light-blue;
                        @media all and (max-width: $md-breakpoint - 1) {
                            padding: rem(20) rem(20);
                            font-size: rem(14);
                        }
                    }   
                    .context {
                        display: flex;
                        @media all and (min-width: $md-breakpoint) {
                            align-items: center;
                            flex-direction: row;
                            padding-left: 1.5rem;
                        }
                        strong {
                            pointer-events: none;
                        }
                        .option-preview,
                        .swatch-preview {
                            display: none;
                            align-items: center;
                            flex-direction: row;
                            margin-left: 1rem;
                            &__separator,
                            &__price {
                                visibility: hidden;
                            }
                            &__material {
                                margin-left: 0.25rem;
                                &::before {
                                    content: '(';
                                }
                                &::after {
                                    content: ')';
                                }
                            }
                            &.active {
                                display: flex;
                            }
                        }
                        .main-option-preview {
                            margin-left: 1rem;
                        }
                        @media all and (max-width: $md-breakpoint - 1) {
                            align-items: left;
                            flex-direction: column;
                            padding: rem(10) 0;
                            margin-left: 1rem;
                            strong {
                                align-items: left;
                                font-size: rem(16);
                            }
                            .main-option-preview,
                            .option-preview,
                            .swatch-preview {
                                align-items: left;
                                margin-left: 0;
                                font-size: rem(16);
                            }
                        }
                    }  
                }
                &-content {
                    background-color: $white;
                    outline: none;

                    .product-coverings {
                        padding-top: 100px;

                        @media all and (min-width: $md-breakpoint) {
                            padding-top: 180px;
                        }

                        &__info{
                            margin-top: rem(23);
                            margin-bottom: rem(27);
                            font-size: rem(14);
                        }

                        &__filters {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 2rem;
                            div {
                                position: relative;
                                display: flex;
                                align-items: center;
                                flex-direction: row;
                                width: 33.33%;
                                padding: 0.75rem 0.5rem 0.75rem 1rem;
                                background: #F0F4F6;
                                select {
                                    width: 100%;
                                    font-family: $helvetica-light;
                                    font-size: 0.7rem;
                                    letter-spacing: 1px;
                                    text-transform: uppercase;
                                    cursor: pointer;
                                    background: #F0F4F6;
                                    border: 0;
                                    border-radius: 0;
                                    appearance: none;
                                }
                                &:nth-child(2n) {
                                    margin: 0 1rem;
                                }
                                &:after {
                                    position: absolute;
                                    right: 10px;
                                    display: inline-block;
                                    padding-left: 0.75rem;
                                    color: #7297A9;
                                    font-variant: normal;
                                    font-family: icomoon !important;
                                    font-size: 1.3125rem;
                                    -webkit-font-smoothing: antialiased;
                                    -moz-osx-font-smoothing: grayscale;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 1;
                                    text-transform: none;
                                    pointer-events: none;
                                    content: '\e916';
                                    transition: all 0.2s; 
                                    speak: none;
                                }
                                &:hover,
                                &.active {
                                    &:after {
                                        transform: scaleY(-1);
                                    }
                                }
                            }
                        }
                        &__types{
                            padding: 0;
                            margin: 0;
                            list-style:none;
                            border-bottom: 1px solid $light-black;

                            li {
                                .product-coverings__title {
                                    position: relative;
                                    min-height: 60px;
                                    // padding: 0 rem(30);
                                    color: $dark-grey;
                                    font-family: $helvetica;
                                    font-weight: 500;
                                    line-height: 60px;
                                    border-top: 1px solid $light-black;

                                    @media all and (min-width: $md-breakpoint) {
                                        min-height: 69px;
                                        font-size: rem(18);
                                        line-height: 69px;
                                    }

                                    &.has-options {
                                        cursor: pointer;
                                        // background: url('../images/arrow-grey.png') no-repeat right center;

                                        &:after {
                                            @include icon();
                                            position: absolute;
                                            top: 50%;
                                            right: 0;
                                            display: inline-block;
                                            color: $light-blue;
                                            font-size: rem(24);
                                            content: '\ea0a';
                                            transform: translate(0, -50%);
                                            transition: all 0.3s;
                                        }
                                    }
                                }

                                ul.product-coverings__items {
                                    display: none;
                                    li {

                                    }
                                }

                                &.active {
                                    .product-opt__items {
                                        display: block;
                                    }
                                }

                                .product-coverings__swatches {
                                    display: none;
                                    .swatch-preview {
                                        display: none;
                                        align-items: center;
                                        flex-flow: row nowrap;
                                        justify-content:space-around;
                                        height: rem(100);
                                        color: $white;
                                        background: $white;

                                        &__color {
                                            flex: 2;
                                            padding-left:rem(25);
                                        }
                                        &__price {
                                            flex: 1;
                                            padding-right: rem(25);
                                            text-align: right;
                                            visibility: visible;
                                        }
                                        &.active {
                                            display: flex;
                                            background-size: cover !important;
                                        }
                                    }
                                    .swatch-items {
                                        display: flex;
                                        flex-flow: row wrap;
                                        margin-right: rem(-12);
                                        margin-left: rem(-12);

                                        @media all and (min-width: $sm-breakpoint) {
                                            margin-right: rem(-18);
                                            margin-left: rem(-18);
                                        }
                                        
                                        // display: flex;
                                        // // flex-direction: row;
                                        // // flex-wrap: wrap;
                                        // flex-flow: row wrap;
                                        // justify-content: space-between;
                                        .swatch-item {
                                            position: relative;
                                            // overflow: hidden;
                                            cursor: pointer;
                                            background-size: cover !important;
                                            transition: 0.2s ease-out, border 0.3s ease-in;
                                            &:hover::before {
                                                visibility: visible;
                                            }
                                            &:before {
                                                position: absolute;
                                                top: -25px;
                                                left: 50%;
                                                z-index: 99993;
                                                width: 0;
                                                height: 0;
                                                border-right: 11px solid transparent;
                                                border-bottom: 11px solid #FFFFFF;
                                                border-left: 11px solid transparent;
                                                content: '';
                                                visibility: hidden;
                                                transform: translate(-50%, 0) rotate(180deg);
                                                @media all and (max-width: $md-breakpoint - 1) {
                                                    display: none;
                                                }
                                            }
                                            &--overlay {
                                                position: absolute;
                                                top: 110px;
                                                left: 0;
                                                z-index: 99993;
                                                display: none;
                                                flex-direction: row;
                                                width: 100%;
                                                height: 300px;
                                                padding: 2rem;
                                                background: #FFFFFF;
                                                box-shadow: 0 1px 12px rgba(0, 0, 0, 0.175);
                                                &.active {
                                                    display: flex;
                                                }
                                                &--dismiss {
                                                    display: none;   

                                                }
                                                &--bg {
                                                    width: 50%;
                                                    height: 100%;
                                                }
                                                &--content {
                                                    display: flex;
                                                    align-items: center;
                                                    flex-direction: column;
                                                    justify-content: center;
                                                    width: 50%;
                                                    .title {
                                                        margin-bottom: 1rem;
                                                        color: $light-blue;
                                                        font-size: 1.25rem;
                                                    }
                                                    .price-container {
                                                        margin-bottom: 3rem;
                                                        font-size: 0.9rem;
                                                        .was-price {
                                                            font-weight: 700;
                                                            text-decoration: line-through;
                                                        }
                                                        .now-price {
                                                            font-weight: 700;
                                                        }
                                                        &.has-discount {
                                                            .now-price {
                                                                color: rgba(159, 56, 57, 1);
                                                            }
                                                        }
                                                    }
                                                    .covering {
                                                        display: flex;
                                                        align-items: center;
                                                        flex-direction: row;
                                                        font-size: 0.8rem;
                                                        .custom-fabric, 
                                                        .custom-leather {
                                                            margin-right: 0.5rem;
                                                        }
                                                    }
                                                }
                                                &--actions {
                                                    display: none;
                                                }
                                                @media all and (max-width: $md-breakpoint - 1) {
                                                    position: fixed;
                                                    top: 0;
                                                    left: 0;
                                                    align-items: center;
                                                    flex-direction: column;
                                                    width: 100%;
                                                    height: 100%;
                                                    padding: 2rem;
                                                    margin: 0;
                                                    &--dismiss {
                                                        display: block;
                                                        width: 100%;
                                                        padding: 2rem 1rem;
                                                        text-align: right;
                                                    }
                                                    &--bg,
                                                    &--content {
                                                        padding-top: 3rem;
                                                        .price-container {
                                                            display: flex;
                                                            flex-direction: row;
                                                            span,
                                                            strong {
                                                                margin-right: 0.5rem;
                                                            }
                                                        }
                                                    }
                                                    &--actions {
                                                        display: flex;
                                                        flex-direction: column;
                                                        padding-top: 3rem;
                                                        button:first-child {
                                                            margin-bottom: 2rem;
                                                        }
                                                    }
                                                }
                                            }
                                            @media all and (max-width: $sm-breakpoint - 1) {
                                                // flex-basis: calc((100% + 23px) / 3 - 23px);
                                                flex-basis: 33.333%;
                                                margin-bottom: rem(20);
                                                font-size: rem(13);
                                                line-height: rem(15);
                                            }

                                            @media all and (min-width: $sm-breakpoint) {
                                                // flex-basis: calc(((100% + 39px) / 4) - 39px);
                                                flex-basis: 25%;
                                                margin-bottom: rem(36);
                                            }
                                            
                                            &:hover {
                                                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                                    // IDK why??
                                                    flex-basis: calc(12.5% - 8px);
                                                }
                                                &::after {
                                                    visibility: visible; 
                                                }
                                                .swatch-item--bg {
                                                    opacity: 0.8;
                                                }
                                            }
                                            &.active:hover {
                                                border: none;
                                            }
                                            &.active {
                                                .swatch-item--bg:before {
                                                    @include icon();
                                                    position: absolute;
                                                    top: 50%;
                                                    left: 50%;
                                                    display: inline-block;
                                                    color: $white;
                                                    font-size: rem(24);
                                                    background: $light-blue;
                                                    border-radius: 50%;
                                                    content: '\e91d';
                                                    transform: translate(-50%, -50%);
                                                    transition: all 0.3s;

                                                    @media all and (min-width: $sm-breakpoint) {
                                                        font-size: rem(32);
                                                    }
                                                }
                                            }

                                            &--bg {
                                                position: relative;
                                                width: 100%;
                                                height: 0;
                                                padding-bottom: 60.25%;
                                                margin-bottom: rem(12);
                                                background-size: cover !important;
                                                opacity: 1;
                                                transition: all 0.3s;
                                            }

                                            &--inner {
                                                pointer-events: none;

                                                @media all and (max-width: $sm-breakpoint - 1) {
                                                    padding: 0 rem(12);
                                                }

                                                @media all and (min-width: $sm-breakpoint) {
                                                    padding: 0 rem(18);
                                                }
                                            }
                                            &--name {
                                                font-size: rem(14);
                                            }
                                        }
                                    }
                                    .swatch-opt {
                                        .swatch-attribute-options {
                                            display: flex;

                                            .swatch-option {
                                                flex-basis: 12%;
                                                height: 58px !important;
                                                padding: 0;
                                                margin: 0;
                                                overflow: hidden;
                                                text-align: center;
                                                text-overflow: ellipsis;
                                                cursor: pointer;
                                                border: none;
                                                transition: 0.2s ease-out, border 0.3s ease-in;

                                                &:hover {
                                                    border: 4px solid $white;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.active {
                                    .product-coverings__title {
                                        // background: url('../images/arrow-reverse.png') no-repeat right center !important;
                                        &:after {
                                            content: '\ea0b';
                                        }
                                    }

                                    .product-coverings__swatches {
                                        display: block;
                                        transition: 0.2s all;
                                    }
                                }
                            }
                        }
                    }

                    .product-opt {
                        padding-top: 150px;
                        @media all and (min-width: $md-breakpoint) {
                            padding-top: 250px;
                        }

                        &__types {
                            padding:0;
                            margin:0;
                            list-style:none;
                            border-bottom: 1px solid $light-black;
                            .upgrade-item {
                                &--overlay {
                                    position: absolute;
                                    top: 110px;
                                    left: 0;
                                    z-index: 99993;
                                    display: none;
                                    flex-direction: row;
                                    width: 100%;
                                    height: 300px;
                                    padding: 2rem;
                                    background: #FFFFFF;
                                    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.175);
                                    &.active {
                                        display: flex;
                                    }
                                    &:before {
                                        position: absolute;
                                        bottom: -10px;
                                        left: 12%;
                                        width: 0;
                                        height: 0;
                                        border-right: 11px solid transparent;
                                        border-bottom: 11px solid #FFFFFF;
                                        border-left: 11px solid transparent;
                                        content: '';
                                        transform: translate(-50%, 0) rotate(180deg);
                                    }
                                    &--dismiss {
                                        display: none;   

                                    }
                                    &--bg {
                                        width: 50%;
                                        height: 100%;
                                    }
                                    &--content {
                                        display: flex;
                                        align-items: center;
                                        flex-direction: column;
                                        justify-content: center;
                                        width: 50%;
                                        .title {
                                            margin-bottom: 1rem;
                                            color: $light-blue;
                                            font-size: 1.25rem;
                                        }
                                        .price-container {
                                            margin-bottom: 3rem;
                                            font-size: 0.9rem;
                                            .was-price {
                                                font-weight: 700;
                                                text-decoration: line-through;
                                            }
                                            .now-price {
                                                font-weight: 700;
                                            }
                                            &.has-discount {
                                                .now-price {
                                                    color: rgba(159, 56, 57, 1);
                                                }
                                            }
                                        }
                                    }
                                    &--actions {
                                        display: none;
                                    }
                                    @media all and (max-width: $md-breakpoint - 1) {
                                        position: fixed;
                                        top: 0;
                                        left: 0;
                                        align-items: center;
                                        flex-direction: column;
                                        width: 100%;
                                        height: 100%;
                                        padding: 2rem;
                                        margin: 0;
                                        &--dismiss {
                                            display: block;
                                            width: 100%;
                                            padding: 2rem 1rem;
                                            text-align: right;
                                        }
                                        &--bg,
                                        &--content {
                                            padding-top: 3rem;
                                            .price-container {
                                                display: flex;
                                                flex-direction: row;
                                                span,
                                                strong {
                                                    margin-right: 0.5rem;
                                                }
                                            }
                                        }
                                        &--actions {
                                            display: flex;
                                            flex-direction: column;
                                            padding-top: 3rem;
                                            button:first-child {
                                                margin-bottom: 2rem;
                                            }
                                        }
                                    }
                                }
                            }
                            li {
                                .product-opt__types--title {
                                    position: relative;
                                    min-height: 60px;
                                    // padding: 0 rem(30);
                                    color: $dark-grey;
                                    font-family: $helvetica;
                                    font-weight: 500;
                                    line-height: 60px;
                                    border-top: 1px solid $light-black;

                                    @media all and (min-width: $md-breakpoint) {
                                        min-height: 69px;
                                        font-size: rem(18);
                                        line-height: 69px;
                                    }

                                    &.has-options {
                                        cursor: pointer;
                                        // background: url('../images/arrow-grey.png') no-repeat right center;

                                        &:after {
                                            @include icon();
                                            position: absolute;
                                            top: 50%;
                                            right: 0;
                                            display: inline-block;
                                            color: $light-blue;
                                            font-size: rem(24);
                                            content: '\ea0a';
                                            transform: translate(0, -50%);
                                            transition: all 0.3s;
                                        }
                                    }
                                }

                                ul.product-opt__items {
                                    display: none;
                                    flex-wrap: wrap;
                                    padding: 0;
                                    margin-bottom: 2rem;
                                    list-style: none;
                                    li {
                                        display: flex;
                                        flex-basis: 33.33%;
                                        @media all and (max-width: $md-breakpoint - 1) {
                                            flex-basis: 50%;
                                        }
                                        .wrapper {
                                            display: flex;
                                            align-items: left;
                                            flex-direction: column;
                                            width: 100%;
                                            padding-right: rem(30);
                                            padding-bottom: rem(60);
                                            cursor: pointer;
                                            &__image {
                                                width:100%;
                                                min-height: rem(150);
                                                color: $light-grey;
                                                line-height:100px;
                                                text-align: center;
                                                background: $white;
                                            }
                                            &__name {
                                                flex: 2;
                                                margin-top: 1rem;
                                                font-size: rem(14);
                                                // padding-left: rem(28);
                                            }
                                            &__price {
                                                flex: 1;
                                                font-size: rem(14);
                                                text-align: left;
                                                .price-poa {
                                                    color: #9B9B9B;
                                                    font-size: rem(12);
                                                }
                                            }
                                        }
                                        &:before {
                                            content: '';
                                        }
                                        &:after {
                                            display: block;
                                            height: 1px;
                                            background: $black;
                                            content: '';
                                            opacity: 0.05;
                                        }
                                        &.active .wrapper__image:before {
                                            @include icon();
                                            position: relative;
                                            top: 30%;
                                            left: 8%;
                                            display: inline-block;
                                            color: $white;
                                            font-size: rem(24);
                                            background: $light-blue;
                                            border-radius: 50%;
                                            content: '\e91d';
                                            transform: translate(-50%, -50%);
                                            transition: all 0.3s;
                                            @media all and (min-width: $sm-breakpoint) {
                                                font-size: rem(32);
                                            }
                                        }
                                    }
                                }

                                &.active {
                                    .product-opt__types--title {
                                        &:after {
                                            content: '\ea0b';
                                        }
                                    }
                                    .product-opt__items {
                                        display: flex;
                                        padding-bottom: 2rem;  
                                    }
                                }
                            }
                        }
                    }

                    .field {
                        display: none;
                        padding: rem(10);
                        background: rgba(255, 0, 0, 0.1);
                        opacity: 0.2;
                    }
                }
            }

            &__options-heading {
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                z-index: 100;
                padding: rem(24) rem($product-modal-padding-sm) 0;
                background: $white;

                @media all and (min-width: $md-breakpoint) {
                    left: 30%;
                    padding: rem(50) rem($product-modal-padding-lg) 0;
                }

                @media all and (min-width: $lg-breakpoint) {
                    left: 50%;
                }
            }

            &__options-title {
                margin-bottom: rem(24);
                color: $light-blue;
                font-family: $helvetica-light;
                font-size: rem(21);

                @media all and (min-width: $md-breakpoint) {
                    margin-bottom: rem(53);
                    font-size: rem(32);
                }
            }

            &__options-preview {
                position: relative;
                padding: rem(18) rem($gutter) rem(12);
                margin: 0 rem(-$gutter);
                background: #F5F5F5;

                @media all and (max-width: $sm-breakpoint - 1) {
                    padding-right: 46%;
                    font-size: rem(14);
                }

                @media all and (min-width: $md-breakpoint) {
                    padding: rem(35) rem($product-modal-padding-lg) rem(30);
                    margin: 0 rem(-$product-modal-padding-lg);
                }

            }
            &__options-available-preview {
                display: flex;
                align-items: center;
                flex-direction: row;
                margin-bottom: 0.5rem;
                .custom-menu-down-arrow {
                    font-size: rem(20);
                    transform: rotate(270deg);
                }
                div,
                span,
                strong {
                    margin-right: 0.25rem;
                }
            }
            &__options-done {
                position: absolute;
                top: 50%;
                right: rem($gutter);
                width: 15%;
                text-align: center;
                transform: translate(0, -50%);

                @media all and (max-width: $sm-breakpoint - 1) {
                    width: 24%;
                }

                @media all and (min-width: $md-breakpoint) {
                    right: rem($product-modal-padding-lg);
                }

                .close {
                    padding-top: rem(14);
                    padding-bottom: rem(14);

                    @media all and (max-width: $sm-breakpoint - 1) {
                        padding-top: rem(7);
                        padding-bottom: rem(7);
                        font-size: rem(12);
                    }
                }
            }
        }

        &.product-options-wrapper-unavailable {
            // top: rem(-63);
            // margin: 0 rem(-2);

            a.active {
                // height: rem(90);
            }
            a.disabled {
                // height: rem(85);
                color: #E8E8E8;
                cursor: not-allowed;
            }
        }

        .empty-message {
            display: none;
            padding: rem(18) 0 rem(20);
            color: #C6C6C6;
            border-bottom: 1px solid #F7F7F7;
        }
    }

    .more-details {
        margin: rem(20) 0;

        a {
            color: $light-blue;
        }
    }
    .product.attribute.care {
        a {
            color: $light-blue;
        }
    }

    .product-storelocator-wrapper {
        padding: rem(30) rem(16);
        text-align: center;
        // background: #F5F5F5;

        // &.store-harvey {
        //     @media all and (min-width: $xlg-breakpoint) {
        //         p {
        //             display: inline-block;
        //             padding-right: rem(15);
        //             margin-bottom: 0;
        //         }
        //
        //         .product-store-img {
        //             margin-bottom: rem(20)
        //         }
        //     }
        // }
        .actions {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            @media all and (max-width: $md-breakpoint - 1) {
                align-items: center;
                flex-direction: column;
            }
            button,
            a {
                margin: 0.5rem;
                @media all and (min-width: $md-breakpoint) {
                    max-width: 250px;
                }
            }
        }
        .custom-question.tooltip {
            display: inline-block;
            .tooltip-text {
                right: 0;
                bottom: 125%;
                left: initial;
                z-index: 15;
                margin-bottom: 0;
                &:before {
                    right: -12px;
                    left: initial;
                }
            }
        }
        #email-product {
            display: none;
            margin-right: 0.5rem;
            &.active {
                display: block;
            }
        }
        .price-selected {
            display: none;
            flex-direction: column;
            &.active {
                display: flex;
            }
            span,
            strong {
                margin-bottom: 1rem;
            }
            .price-adjusted {
                color: #9F3834;
            }
        }
        &.store-lazboy {
            .product-store-img {
                img {
                    width: rem(200);
                }
            }
        }

        p {
            padding: 0 rem(32);
            margin-bottom: rem(30);

            @media all and (max-width: $sm-breakpoint - 1) {
                padding: 0 rem(16);
                font-size: rem(14);
            }
        }

        a {
            padding-top: rem(16);
            padding-bottom: rem(16);
            margin-right: 0;
        }

        .inner {
            padding: rem(20) 0;

            label {
                display: block;
                margin-right: rem(30);
                margin-bottom: rem(20);
                color: $white;
                font-family: $helvetica-light;
                font-size: rem(20);
                font-weight: 300;
                line-height: 1.5;
            }

            .select-wrapper {
                position: relative;
                background: $light-rose url('../images/arrow.png') no-repeat right center;

                // @-moz-document url-prefix('') {
                //     &:after {
                //         position: absolute;
                //         top: 0;
                //         left: 100%;
                //         display: block;
                //         width: 20px;
                //         height: 100%;
                //         margin: 0 0 0 rem(-20);
                //         background: rgba(166, 113, 125, 1);
                //         content: '';
                //     }
                // }

                select {
                    width: 100%;
                    padding: rem(22) rem(24);
                    color: $white;
                    font-family: $helvetica-bold;
                    font-size: rem(14);
                    background: transparent;
                    border: none;
                    border-radius: 0;
                    appearance: none;

                    option {
                        color: $rose;
                        background: $white;
                    }

                    &:focus {
                        outline: none;
                    }
                    &::-ms-expand {
                        display: none; // Hide arrow in IE
                    }
                }
            }
        }
    }

    .product-storelocator {
        position: fixed;
        top: 0;
        right: 0;
        bottom:0;
        left: 0;
        z-index: 100;
        display: none;
        overflow: auto;

        &__overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 100;
            background: $blue;
            opacity: 0.9;

            .close {
                position: fixed;
                top: rem(32);
                right: rem(32);
                width: rem(21);
                height:rem(21);
                opacity: 0.8;
                //transition: transform 0.5s ease-in-out;

                &:hover {
                    opacity:1;
                    //transform: rotate(180deg);
                }

                &:before,
                &:after {
                    position: absolute;
                    left: rem(9);
                    width:rem(2);
                    height:rem(21);
                    background: $white;
                    content: ' ';
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg);
                }
            }
        }

        &__content {
            @include container($container, $gutter);

            @media all and (max-width: $md-breakpoint) {
                margin: rem(83) auto;
            }

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 101;
            min-height: 100vh;
            margin: rem(157) auto;
            overflow: auto;
            background: $lighter-grey;
        }

        &.active {
            display: block;
        }
    }

    // Related Products Item List.
    .related {
        @include container($container, $gutter);
        @media all and (max-width: $md-breakpoint) {
            padding: 0 rem(20);
        }
        position: relative;
        flex: 1 1 100%;
        order: 3;
        //margin: rem(80) auto rem(40);
        margin: 0 auto rem(50);

        h2 {
            margin: rem(15) 0 rem(60);
            color: #4A4A4A;
            font-size: rem(24);
            font-weight: 300;
        }

        .block-actions {
            display: none;
        }
        .products-grid {
            @media all and (max-width: $md-breakpoint) {
                margin: 0 rem(-20);
            }
            margin:0 rem(-10);

            .product-items {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                padding:0;
                margin: 0;
                overflow: hidden;

                .item{
                    @media all and (max-width: $md-breakpoint) {
                        width: 269px;
                        margin-right: rem(10);
                    }
                    //display: flex;
                    align-items: center;
                    flex-basis: calc(25% - 20px);
                    flex-flow: column nowrap;
                    justify-content: flex-start;
                    width: 100%;
                    height: 300px;
                    padding: 0;
                    margin: rem(5) rem(10) rem(30);
                    text-align:center;
                    background: $white;

                    * {
                        outline: none;
                    }

                    a.product-item-photo {
                        display: flex;
                        align-items: center;
                        flex-flow: row wrap;
                        justify-content: center;
                        width: 100% !important;
                        height: 218px;
                        padding: 0;
                        margin: 0 0 rem(19);
                        overflow: hidden;
                        color: #CCCCCC;
                        line-height: 218px;
                        text-align: center;
                        background: $white;

                        img {
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                    strong.product-item-name {
                        margin: rem(15) 0 0;

                        a {
                            display: block;
                            height: rem(26);
                            margin: 0 rem(15) rem(-5);
                            overflow: hidden;
                            color: #7297A9;
                            font-family: $helvetica-light;
                            font-size: rem(20);
                            font-weight: 100;
                            text-align: center;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                        }

                        img {
                            max-width: 100%;
                        }
                    }
                    .price-box {
                        margin-top: 5px;
                        text-align: center;

                        span {
                            margin-left: rem(12);
                            color: #898989;
                            font-family: $helvetica;
                            font-size: rem(14);
                            //font-weight: 500;
                            line-height: 1.43;
                            &.old-price.sly-old-price.no-display, &.old-price {
                                display: none;
                            }
                            &.price {
                                &:before {
                                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                        margin: rem(2) 0 0 rem(-44);
                                    }
                                    position: absolute;
                                    margin: rem(2) 0 0 rem(-30);
                                    font-family: $helvetica-light;
                                    font-size: rem(12);
                                    // content: 'From RRP';
                                }
                                span,
                                strong {
                                    &.was-price {
                                        font-weight: 700;
                                        text-decoration: line-through;
                                    }
                                    &.now-price {
                                        font-weight: 700;
                                    }
                                }
                                div.fabric-container,
                                div.leather-container {
                                    &.has-discount .now-price {
                                        color: rgba(159, 56, 57, 1);
                                    }
                                }
                            }

                            &.covered {
                                &:before {
                                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                        margin: rem(2) 0 0 rem(-44);
                                    }
                                    position: absolute;
                                    margin: rem(2) 0 0 rem(-30);
                                    font-family: $helvetica-light;
                                    font-size: rem(12);
                                    // content: 'From RRP';
                                }
                                // span,
                                // strong {
                                //     &.was-price {
                                //         font-weight: 700;
                                //         text-decoration: line-through;
                                //     }
                                //     &.now-price {
                                //         color: rgba(159, 56, 57, 1);
                                //         font-weight: 700;
                                //     }
                                // }
                            }

                            &.non-covered {
                                &:before {
                                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                        margin: rem(2) 0 0 rem(-44);
                                    }
                                    position: absolute;
                                    margin: rem(2) 0 0 rem(-30);
                                    font-family: $helvetica-light;
                                    font-size: rem(12);
                                    // content: 'Fabric from RRP';
                                }
                                span,
                                strong {
                                    &.was-price {
                                        font-weight: 700;
                                        text-decoration: line-through;
                                    }
                                    &.now-price {
                                        color: rgba(159, 56, 57, 1);
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                    .field.choice {
                        display: none;
                    }

                    &:hover {
                        box-shadow: 0 0 rem(10) 0 rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }

        .slick-arrow {
            opacity: 0;
        }

        .slick-slider .slick-list,
        .slick-track, .slick-slide,
        .slick-slide img {
            transform: none;
        }
    }

    .carousel-nav-wrapper {
        @media all and (max-width: 1280px) {
            display: none;
        }
        position: absolute;
        right: 0;
        left: 0;
        height: rem(200);
        margin-top: rem(150);

        .carousel-nav {
            @media all and (max-width: $md-breakpoint) {
                display: none;
            }
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 9;
            width: rem(30);
            height: rem(30);
            margin-top: rem(0);
            cursor: pointer;
            opacity:1;

            &.carousel-nav-prev {
                margin-left: rem(-630);
                //left: rem(27);
                //left: rem(10);
                background: url('../images/icons/slide-prev.png') no-repeat center center;
            }
            &.carousel-nav-next {
                //right : rem(27);
                margin-left: rem(599);
                background: url('../images/icons/slide-next.png') no-repeat center center;
            }

            &:hover {
                zoom: 110%;
            }
        }
    }

    .addthis_inline_follow_toolbox {
        display: none;
        .addthis_toolbox {
            // display: flex;
            display: none;

            a.at-follow-btn {
                flex: 1 0 25%;
                margin: 0 !important;

                .at-custom-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100% !important;
                    height: rem(80) !important;
                    line-height: rem(80) !important;

                    svg {
                        width: 100% !important;
                        height: 100% !important;
                        max-height: rem(34);
                    }
                }
            }
        }
    }
    #at4-share, #at4-share2 {
        top: 30% !important;
        @media all and (min-width: $lg-breakpoint) {
            display: block;
        }
        display: none;
        a {
            @media all and (min-width: $lg-breakpoint) {
                display: block;
            }
            display: none;
        }
    }

    .product-disclaimer {
        @media all and (max-width: $sm-breakpoint) {
            padding: 0 rem(15) 0 0;
            margin: rem(43) 0 0;
        }
        position: fixed;
        bottom: 0;
        padding: 0 rem(100) 0 0;
        margin: rem(43) 0 0;
        font-size: rem(14);
        line-height: 1.57;
        background: $white;
    }

    // storelocator popup styles
    .description__wrapper .detail {
        margin: 0 rem(20);
    }

    .amlabel-position-wrapper {
        z-index: 9 !important;
        display: none !important;
    }

    .gallery ~ .amlabel-position-wrapper {
        display: block !important;
    }

    .ambanners {
        //display: none !important;

        img {
            width: 100%;
        }

        .block {
            display: none;
        }
    }
    // .store-lazboy ~ .ambanners {
    //     display: block !important;
    // }
}

body.modal-open #at4-share,
body.modal-open #at4-share2 {
    display: none !important;
}

.stock.unavailable,
a.minimal-price-link {
    display: none !important;
}

.products-carousel-container {
    @include container($xl-container, $gutter);
    position: relative;
    margin: 0 auto 3.125rem;
    overflow: hidden;

    &--inner {
        position: relative;
        overflow: hidden;
    }

    .product-item-info {
        position: relative;
        padding-bottom: rem(55);
        background: #FFFFFF;
        border: 1px solid #F6F6F6;

        .btn--view {
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            overflow: hidden;
            text-align: center;
            text-transform: uppercase;
            border-color: $lighter-grey;
            border-width: 1px 0 0;
        }
        .product-item-photo {
            display: block;
            width: 100%;
        }
    }
    .c-header {
        padding-left: 12px;
        color: $light-blue;

        @media all and (max-width: $md-breakpoint - 1) {
            margin: rem(30) auto rem(24);
            font-size: rem(18);
            text-align: center;
        }
    }
    .item a.product-item-photo img {
        width: 100%;
        max-width: 100%;
    }
    .next-arrow, .prev-arrow {
        position: absolute;
        top: 50%;
        z-index: 10;
        margin-top: -1.9rem;
        color: #00679B;
        font-size: 2.1875rem;
        cursor: pointer;
    }
    .next-arrow {
        right: -1.75rem;
        transform: scale(-1);
    }
    .prev-arrow {
        left: -1.75rem;
    }
    .prev-arrow[aria-disabled='true'] {
        display: none !important;
    }
    .next-arrow[aria-disabled='true'] {
        display: none;
    }
    .item_sale {
        background: #2875A4;
    }
    .product-item-details {
        padding: 22px 12px;
        .price-box .price-container {
            span.price {
                display:flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 1rem;
                &:before {
                    margin-right: rem(4);
                    font-family: $helvetica-light;
                    font-size: rem(12);
                    // content: 'From RRP';
                }
                div {
                    &.fabric-container,
                    &.leather-container {
                        display:flex;
                        align-items: center;
                        flex-direction: column;
                        padding: 0 0.5rem;
                        &.has-discount {
                            .was-price {
                                font-weight: 700;
                                text-decoration: line-through;
                            }
                            .now-price {
                                color: rgba(159, 56, 57, 1);
                                font-weight: 700;
                            }
                        }
                        .now-price {
                            font-weight: 700;
                        }
                        .swatch-count {
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                            margin-top: 1rem;
                            font-size: rem(13);
                            .custom-leather,
                            .custom-fabric {
                                margin-right: 0.5rem;
                            }
                        }
                        .custom-question.tooltip {
                            display: none;
                        }
                    }
                    &.leather-container {
                        border-left: 1px solid #F6F6F6;
                    }
                }
                &.leather-only {
                    div.leather-container {
                        border-left: 0;
                    }
                }
                span {
                    &.was-price {
                        font-weight: 700;
                        text-decoration: line-through;
                    }
                    &.now-price {
                        // color: rgba(159, 56, 57, 1);
                        font-weight: 700;
                    }
                }
            }
        }
    }
    .price-box {
        margin-top: 6px;
    }
    .item strong.product-item-name a {
        display: block;
        height: 1.625rem;
        margin: 0 0.9375rem -0.3125rem;
        overflow: hidden;
        color: #7297A9;
        font-family: HelveticaNeueLight, Arial, sans-serif;
        font-size: 1.25rem;
        font-weight: 100;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .item_sale strong.product-item-name a {
        display: block;
        height: 1.625rem;
        margin: 0 0.9375rem -0.3125rem;
        overflow: hidden;
        color: #FFFFFF;
        font-family: HelveticaNeueLight, Arial, sans-serif;
        font-size: 1.25rem;
        font-weight: 100;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .item .price-box span {
        color: #898989;
        font-family: HelveticaNeue, Arial, sans-serif;
        font-size: 0.875rem;
        line-height: 1.43;
    }
    .item_sale .price-box span {
        color: #FFFFFF;
        font-family: HelveticaNeue, Arial, sans-serif;
        font-size: 0.875rem;
        line-height: 1.43;
    }
    
    .item .price-box strong,
    .item .price-box span {
        &.sale:before {
            margin-right: 0.25rem;
            font-family: HelveticaNeueLight, Arial, sans-serif;
            font-size: 0.75rem;
            font-weight: 600;
            // content: 'NOW';
        }
        &.covered:before {
            margin-right: 0;
            font-family: HelveticaNeueLight, Arial, sans-serif;
            font-size: 0.75rem;
            // content: 'From RRP';
        }
        &.non-covered:before {
            margin-right: 0;
            font-family: HelveticaNeueLight, Arial, sans-serif;
            font-size: 0.75rem;
            // content: 'Fabric from RRP';
        }
        &.old-price.sly-old-price.no-display, &.old-price {
            display: none;
        }
        &.was-price {
            font-weight: 700;
            text-decoration: line-through;
        }
        &.now-price {
            // color: rgba(159, 56, 57, 1);
            font-weight: 700;
        }
        div.fabric-container,
        div.leather-container {
            &.has-discount .now-price {
                color: rgba(159, 56, 57, 1);
            }
        }
    }
    .item .featured-price span.non-covered:before {
        margin: 0 !important;
    }
    .item .featured-price span.covered:before {
        margin: 0 !important;
    }

    .item {
        // box-align: center;
        // box-orient: vertical;
        // box-pack: start;
        // align-items: center;
        // flex-basis: calc(25% - 20px);
        // box-direction: normal;
        // flex-flow: column nowrap;
        // justify-content: flex-start;
        width: 100%;
        padding-right: 24px;
        margin: 0.3125rem 0.625rem 1.875rem;
        text-align: center;
        background: none;
    }
}



.product__container {
    @include container($xl-container, $gutter);
}

.product__row {
    @media all and (min-width: $md-breakpoint) {
        display: flex;
        flex-direction: row;
        // flex-wrap: wrap;
        justify-content: space-between;
    }
}

.product__img {
    position: relative;
    overflow: hidden;

    @media all and (max-width: $md-breakpoint - 1) {
        margin-right: rem(-$gutter);
        margin-left: rem(-$gutter);
    }

    @media all and (min-width: $md-breakpoint) {
        flex-basis: 55%;
        flex-grow: 0;
        flex-shrink: 0;
    }
}


.product__info {
    @media all and (min-width: $md-breakpoint) {
        flex-basis: 45%;
        flex-grow: 0;
        flex-shrink: 0;
        padding: 0 2rem;
    }
    .main-option-preview,
    .option-preview,
    .swatch-preview {
        display: inline-block;
        color: $light-blue;
        pointer-events: none;
        opacity: 0;
        transition: all 0.5s;
        &.active {
            opacity: 1;
        }
        &__material {
            margin-left: 0.25rem;
            &::before {
                content: '(';
            }
            &::after {
                content: ')';
            }
        }
    }
    .main-option-preview {
        margin-right:0.5rem;
    }
    .customise-your-price {
        display: block !important;
        margin-top: 2.5rem;
        color: $light-blue;
        font-size: rem(20);
        text-align: left;
    }
}

.modal-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
}
.popup-wrapper .modal-bg {
    z-index: 13;
}
.product__modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: none;
    overflow-y: scroll;

    // &::-webkit-scrollbar {
    //     -webkit-appearance: none;
    //     width: 7px;
    // }
    //
    // &::-webkit-scrollbar-thumb {
    //     border-radius: 4px;
    //     background-color: rgba(0, 0, 0, 0.5);
    //     -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    // }

    &:after {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 20;
        background: $white;
        content: '';

        @media all and (min-width: $md-breakpoint) {
            left: 30%;
        }

        @media all and (min-width: $lg-breakpoint) {
            left: 50%;
        }
    }

    &-inner {
        position: relative;
        z-index: 30;
        width: 100%;
        padding: rem(24) rem($product-modal-padding-sm);
        // overflow: auto;
        pointer-events: none;
        background: $white;

        // &::-webkit-scrollbar {
        //     -webkit-appearance: none;
        //     width: 7px;
        // }
        //
        // &::-webkit-scrollbar-thumb {
        //     border-radius: 4px;
        //     background-color: rgba(0,0,0,.5);
        //     -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
        // }

        @media all and (min-width: $md-breakpoint) {
            left: 30%;
            width: 70%;
            padding: rem(50) rem($product-modal-padding-lg);
        }

        @media all and (min-width: $lg-breakpoint) {
            left: 50%;
            width: 50%;
        }
    }

    .product-add-form {
        padding-bottom: rem(100);
        pointer-events: auto;
    }

    &-close {
        position: fixed;
        top: rem(32);
        right: rem($product-modal-padding-sm);
        z-index: 110;
        font-size: rem(11);
        cursor: pointer;

        @media all and (min-width: $md-breakpoint) {
            top: rem(58);
            right: rem($product-modal-padding-lg);
            font-size: rem(18);
        }
    }
}

.product-social-links {
    // margin-top: rem(16);

    .product-addto-links {
        display: inline-block;
    }

    a {
        display: inline-block;
        padding: rem(8) 0;
        margin-right: rem(10);
        font-size: rem(12);
        white-space: nowrap;

        @media all and (min-width: $md-breakpoint) {
            padding: rem(16) 0;
            margin-right: rem(24);
            font-size: rem(14);
        }

        &:before {
            @include icon();
            display: inline-block;
            margin-right: rem(2);
            color: $light-blue;
            font-size: rem(20);
            vertical-align: middle;

            @media all and (min-width: $md-breakpoint) {
                margin-right: rem(5);
                font-size: rem(30);
            }
        }

        > span {
            vertical-align: block;
        }
    }

    .towishlist:before {
        content: '\e91f';
    }

    .mailto:before {
        content: '\e91a';
    }

    .print {

        &:before {
            content: '\e918';
        }

        @media all and (max-width: $md-breakpoint - 1) {
            display: none;
        }
    }

    .download {
        margin-right: 0;

        &:before {
            content: '\e914';
        }
    }

    .product-extras {
        // margin-top: rem(22);
    }

    .brand-content {
        display: flex;
        align-items: center;
        flex-direction: row;
        min-height: 12rem;
        border: 2px solid $light-blue;
        &.active {
            display: flex !important;
        }
        .banner {
            display: none;
        }
        .banner,
        .content {
            flex-basis: 100%;
            min-height: 12rem;
            @media all and (max-width: $md-breakpoint - 1) {
                flex-basis: 100%;
                min-width: 100%;
                min-height: 7rem;
            }
        }
        .content {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            .brand-content-top {
                margin-bottom: 0.5rem;
                color: $light-blue;
                font-size: rem(30);
            }
        }
        &.banner-only-start {
            display: none;
            border: 0;
        }
        &.content-only,
        &.banner-only {
            border: 2px solid $light-blue;
            .banner,
            .content {
                flex-basis: 100%;
            }
        }
        &.both {
            .banner,
            .content {
                flex-basis: 50%;
            }
        }
        @media all and (max-width: $md-breakpoint - 1) {
            flex-direction: column;
            min-height: 7rem;
            &.content-only,
            &.banner-only {
                .banner,
                .content {
                    min-height: inherit;
                }
            }
            &.both {
                .banner,
                .content {
                    min-height: 200px;
                }
            }
        }
    }
}