@function rem($size, $context: $base-font-size) {
    $remSize: $size / $context;
    @return #{$remSize}rem;
}

@mixin placeholder ($color) {
    &::-webkit-input-placeholder { /* Chrome */
        color: $color !important;
        opacity: 1 !important;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        color: $color !important;
        opacity: 1 !important;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        color: $color !important;
        opacity: 1 !important;
    }

    &:-moz-placeholder { /* Firefox 4 - 18 */
        color: $color !important;
        opacity: 1 !important;
    }
}

@mixin container ($max-width, $padding) {
    //@media all and (max-width: $md-breakpoint) {
    //    padding-right: 0;
    //    padding-left: 0;
    //}
    width: 100%;
    max-width: rem($max-width + ( $padding * 2 ));
    padding-right: rem($padding);
    padding-left:  rem($padding);
    margin-right: auto;
    margin-left: auto;
}


@mixin triangle ($color) {
    &:after {
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-left: 6px solid $color;
        content: '';
    }
}

/* $class outputs the selector, do not place mixin within a rule
   $dir == up, down, left or right,
   $bg == background-color of arrow container
*/

@mixin chevron($class, $size, $weight, $color, $dir, $bg) {
    @if $dir == up {
        $dir : bottom;
    }
    @elseif $dir == down {
        $dir : top;
    }
    @elseif $dir == right {
        $dir : left;
    }
    @else {
        $dir : right;
    }

    .#{$class} {
        position:relative;
        display:block;
        height: $size * 2;
    }

    .#{$class}:before,
    .#{$class}:after {
        position:absolute;
        display:block;
        /*Size*/
        border:$size solid transparent;
        content:'';
    }

    .#{$class}:before {
        #{$dir}:0;
        /*Arrow Color*/
        border-#{$dir}-color:$color;
    }

    .#{$class}:after {
        /*Thickness*/
        #{$dir}:-$weight;
        border-#{$dir}-color:$bg;
    }
}


@mixin icon () {
    font-variant: normal;
    font-family: icomoon !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    text-transform: none;
    speak: none;
}
