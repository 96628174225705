
/*------------------------------------*\
    PLAIN TEMPLATE
\*------------------------------------*/
.page-layout-plain {
    .page-wrapper {
        position: relative;
        min-height: 100vh;
        padding-top: 0 !important;
        padding-bottom: rem(70);
        border-bottom: rem(45) solid $bright-blue;

        @media all and (min-width: $md-breakpoint) {
            border-width: rem(52);
        }
    }
}

/*------------------------------------*\
    HEADER
\*------------------------------------*/
.page-header-simple {
    padding: rem(10);
    text-align: center;
    border-bottom: 1px solid #E7E7E7;

    @media all and (min-width: $md-breakpoint) {
        padding: rem(20);
    }

    .logo {
        display: inline-block;
        width: 200px;
        margin: 0 auto;

        @media all and (min-width: $md-breakpoint) {
            width: 300px;
        }

        img {
            width: auto;
            height: auto;
            max-width: 100%;
        }
    }
}
