.newsletter-modal {
    .page-title {
        margin-bottom: rem(40) !important;
    }

    .note {
        margin-bottom: rem(60);
        font-family: $helvetica-light;
    }

    .fieldset {
        margin-bottom: rem(60);
    }

    /** CSS hack for FF. hide default dropdown button! **/
    .mc-field-group.field {
        overflow: hidden;
        select {
            width: 110%;
            background: $white;
            outline: none;
        }
        &.hidden {
            display: none !important;
        }
    }
}