@charset 'UTF-8';

// Default Variables

// Slick icon entity codes outputs the following
// '\2190' outputs ascii character '←'
// '\2192' outputs ascii character '→'
// '\2022' outputs ascii character '•'

$slick-loader-path: './' !default;
$slick-arrow-color: $bright-blue !default;
$slick-dot-color: $bright-blue !default;
$slick-dot-color-active: darken($bright-blue, 10%) !default;
$slick-prev-character: '\e916' !default;
$slick-next-character: '\e916' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;

/* Slider */

.slick-slider {
    position: relative;
    box-sizing: border-box;
    display: block;
    user-select: none;
    touch-action: pan-y;
}
.slick-list {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    overflow: hidden;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin: auto;

    &:before,
    &:after {
        display: table;
        content: '';
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    [dir='rtl'] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: $white slick-image-url('ajax-loader.gif') center center no-repeat;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    top: 15%;
    @media all and (min-width: 474px) { 
        top: 20%;
    }
    @media all and (min-width: $xs-breakpoint) { 
        top: 25%;
    }
    @media all and (min-width: 600px) { 
        top: 30%;
    }
    @media all and (min-width: $sm-breakpoint) { 
        top: 15%;
    }
    @media all and (min-width: 888px) { 
        top: 20%;
    }
    z-index: 1;
    display: block;
    width: rem(40);
    height: rem(40);
    padding: rem(4) 0;
    color: $white;
    font-size: 0;
    line-height: rem(40);
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    &:hover, &:focus {
        color: transparent;
        background: transparent;
        outline: none;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        display: block;
        width: rem(40);
        height: rem(40);
        padding: rem(4);
        color: $white;
        font-family: icomoon;
        font-size: rem(32);
        line-height: 1;
        background-color: $slick-arrow-color;
        border-radius: 50%;
        opacity: $slick-opacity-default;
    }
}

.slick-prev {
    left: 0;
    transform: rotate(90deg) translate(0, -50%);
    [dir='rtl'] & {
        right: 0;
        left: auto;
    }
    &:before {
        content: $slick-prev-character;
        [dir='rtl'] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: 0;
    transform: rotate(-90deg) translate(0, -50%);
    [dir='rtl'] & {
        right: auto;
        left: 0;
    }
    &:before {
        content: $slick-next-character;
        [dir='rtl'] & {
            content: $slick-prev-character;
        }
    }
}

.slick-disabled {
    display: none !important;
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: rem(32);
}

.slick-dots {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    list-style: none;
    li {
        position: relative;
        display: inline-block;
        width: rem(8);
        height: rem(8);
        padding: 0;
        margin: 0 rem(8);
        cursor: pointer;
        button {
            display: block;
            width: rem(8);
            height: rem(8);
            padding: rem(8);
            color: transparent;
            font-size: 0;
            line-height: 0;
            cursor: pointer;
            background: transparent;
            border: 0;
            outline: none;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: rem(8);
                height: rem(8);
                background-color: $slick-dot-color;
                border-radius: 50%;
                content: $slick-dot-character;
                opacity: $slick-opacity-not-active;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}