.contentmanager-contenttype-store-promo {

    article.store-promo-container {
        @include container($container, $gutter);
    }

    .cms-store_promo-wrapper {
        .hero-image {
            position: relative;
            //height: rem(700);
            margin-top: rem(-160);
            overflow: hidden;

            .hero-image__image {
                img {
                    position: relative;
                    left:50%;
                    width: auto;
                    height: rem(700);
                    max-width: none !important;
                    min-height: rem(540);
                    transform:translateX(-50%);

                    @media all and (min-width: $md-breakpoint) {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            .hero-image__promotion {

                @include container($container, $gutter);

                @media all and (min-width: $lg-breakpoint) {
                    bottom: rem(69);
                }

                position: absolute;
                top: rem(200);
                bottom: rem(30);
                display: flex;
                align-items: flex-end;
                flex-flow: row nowrap;

                @media all and (max-width: $md-breakpoint) {
                    top: rem(160);
                    right: 0;
                    bottom: rem(3);
                    left: 0;
                    padding: 0;
                    margin: 0;
                }

                @media all and (min-width: $lg-breakpoint) {
                    left: 50%;
                    margin-left: rem(-610);
                }

                .item {
                    @media all and (max-width: $md-breakpoint) {
                        width: 100%;
                        height: auto;
                    }
                    width: 387px;
                    height: 323px;
                    background-color: rgba(0, 103, 155, 0.9);

                    &__title {
                        @media all and (max-width: $md-breakpoint) {
                            padding: rem(32) rem(20) 0;
                        }
                        padding: rem(34) rem(27) 0;
                        color: #FFFFFF;
                        font-family: $helvetica-light;
                        font-size: rem(14);
                        font-weight: 700;
                        text-align: left;
                    }

                    &__headline {
                        @media all and (max-width: $md-breakpoint) {
                            padding: rem(18) rem(41) rem(37) rem(20);
                            font-size: rem(24);
                        }
                        padding: rem(16) rem(38) rem(24) rem(24);
                        color: #FFFFFF;
                        font-family: $helvetica-light;
                        font-size: rem(32);
                        font-weight: 300;
                        line-height: 1.19;
                        text-align: left;
                    }
                }
            }
        }

        .store_promo-container {
            @include container($container, $gutter);

            @media all and (max-width: $md-breakpoint) {
                padding: 0 rem(20) 0;
            }

            padding: rem(19) rem(120);

            h1.title {
                margin: rem(72) 0;
                color: #7297A9;
                font-family: $helvetica-light;
                font-size: rem(32);
                font-weight: 300;
                line-height: 1.31;
                text-align: left;
            }

            .cms-store_promo__content--headline {
                margin: 0 0 rem(55);
                color: #666666;
                font-family: $helvetica-light;
                font-size: rem(20);
                font-weight: 300;
                line-height: 1.5;
                text-align: left;
            }

            .cms-store_promo__content--body {
                color: #666666;
                font-family: $helvetica-light;
                font-size: rem(14);
                line-height: 1.57;
                text-align: left;

                h2, h3, h4, h5, h6 {
                    color: #4A4A4A;
                }
            }
        }

        .featured-products {
            @include container($container, $gutter);

            @media all and (max-width: $md-breakpoint) {
                //padding: 0 rem(20) 0 0;
                padding: 0;
                //border: 1px solid #FF0000;
            }

            @media all and (max-width: $sm-breakpoint) {
                //padding: 0;
                //border: 1px solid #00FF00;
            }

            display: flex;
            flex-flow: row nowrap;
            justify-content: space-around;
            margin: rem(122) auto 0;

            .item {
                @media all and (max-width: $md-breakpoint) {
                    margin: 0 rem(20) 0;
                }
                @media all and (max-width: $sm-breakpoint) {
                    margin: 0 rem(20);
                }
                @media all and (max-width: 520px) {
                    margin: 0 rem(20) 0 0;
                }

                flex: 1;
                margin: 0 rem(30) 0 0;
                //margin: 0 rem(15);
                background: $white;

                a {
                    display: block;

                    .item__image {
                        @media all and (max-width: $md-breakpoint) {
                            height: auto;
                        }
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: auto;

                        img {
                            width: 100%;
                            height: auto;
                            max-width: 100%;
                        }
                    }

                    .item__detail {
                        height: rem(95);

                        .item__name {
                            padding: rem(26) 0 rem(5);
                            color: #7297A9;
                            font-family: $helvetica-light;
                            font-size: rem(18);
                            font-weight: 300;
                            line-height: 1.11;
                            text-align: center;
                        }

                        .item__price {
                            color: #9B9B9B;
                            font-family: $helvetica;
                            font-size: rem(14);
                            font-weight: 500;
                            line-height: 1.43;
                            text-align: center;
                        }
                    }

                    .item_sale {
                        background: #2875A4;

                        .item__name {
                            color: #FFFFFF;
                        }

                        .item__price {
                            color: #FFFFFF;
                        }
                    }
                }

                &:hover {
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                }

                @media all and (min-width: $md-breakpoint) {
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        .store-locator {
            @include container($container, $gutter);

            @media all and (max-width: $md-breakpoint) {
                margin: rem(40) auto rem(118);
            }

            margin: rem(65) auto rem(158);

            .title {
                @media all and (min-width: $md-breakpoint) {
                    height: rem(170);
                }

                display: flex;
                align-items: center;
                flex-flow: row wrap;

                .title__heading {
                    h2 {
                        @media all and (max-width: $md-breakpoint) {
                            padding-right: rem(80);
                            margin: rem(84) 0 rem(65);
                        }
                        color: $light-blue;
                        font-family: $helvetica-light;
                        font-size: rem(32);
                    }
                }

                .title__filter {
                    @media all and (max-width: $md-breakpoint) {
                        flex-basis: 100%;
                    }
                    display: flex;
                    align-items: center;
                    flex: 1;
                    justify-content: flex-end;

                    label {
                        @media all and (max-width: $md-breakpoint) {
                            display: none;
                        }
                        flex: 4 0;
                        margin-right: rem(20);
                        color: #4A4A4A;
                        font-family: $helvetica-light;
                        font-size: rem(20);
                        font-weight: 300;
                        text-align: right;
                    }

                    .select-wrapper {
                        @media all and (max-width: $md-breakpoint) {
                            height: rem(60);
                            margin-bottom: rem(70);
                        }
                        flex: 5 0;

                        select {
                            display: block;
                            padding: rem(22) rem(24) rem(22);
                            margin-bottom: rem(80);
                            font-family: $helvetica-bold;
                            font-size: rem(14);
                            text-indent: 0.01px;
                            text-overflow: ellipsis;
                            background: transparent;
                            border: none;
                            border-radius: 0;
                            appearance: none;

                            @media all and (min-width: $md-breakpoint) {
                                margin-bottom: 0;
                            }
                            &:focus {
                                outline: none;
                            }
                        }
                    }
                }
            }

            // copied from storelocator.scss
            .contents {
                @media all and (min-width: $md-breakpoint) {
                    display: flex;
                    align-content: flex-start;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    margin: 0 0 rem(73);
                }
                margin: 0 rem(-20) rem(60);

                &__item {
                    @media all and (min-width: $md-breakpoint) {
                        //flex: 1 0 calc(25% - 30px);
                        display: flex;
                        align-content: center;
                        flex-basis: calc(25% - 30px);
                        justify-content: center;
                        height: 267px;
                        margin-top: rem(30);
                    }

                    display: block;
                    height: 80px;
                    margin-top: rem(1);
                    background: $white;

                    .wrapper {
                        @media all and (min-width: $md-breakpoint) {
                            height: 267px;
                            text-align: center;
                            cursor: pointer;
                        }
                        display: flex;
                        align-content: center;
                        flex-flow: column nowrap;
                        width: 100%;
                        height: rem(80);


                        &__logo {
                            @media all and (min-width: $md-breakpoint) {
                                display: flex;
                                align-items: center;
                                flex: 2;
                                justify-content: center;
                            }
                            display: none;
                            padding: rem(30) 0 0;
                        }

                        &__name {
                            @media all and (min-width: $md-breakpoint) {
                                flex: 1;
                            }
                            display: flex;
                            align-items: center;
                            flex: 1 0 100%;
                            justify-content: center;
                            padding: rem(10) rem(30);
                            color: $dark-grey;
                            font-family: $helvetica-bold;
                            font-size: rem(14);
                            font-weight: 300;
                            line-height: 1.57;
                            text-align: center;
                            // vertical-align: middle;
                        }
                    }

                    &:hover {
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                    }

                    &--hidden {
                        height: 0;
                    }

                    .detail-wrapper {
                        display: none;
                    }

                    &.active {

                        @media all and (max-width: $md-breakpoint) {
                            margin-top: rem(6);
                        }

                        .detail-wrapper {
                            display: block !important;

                            .description {
                                @media all and (min-width: $md-breakpoint) {
                                    margin-top: 297px!important;
                                }
                                position: absolute;
                                right: 0;
                                left: 0;
                                // margin-top: 0;

                                &__wrapper {
                                    @include container($container, $gutter);

                                    .detail {
                                        @media all and (min-width: $md-breakpoint) {
                                            display: flex;
                                            padding: rem(28);
                                        }

                                        align-content: center;
                                        flex-flow: row wrap;
                                        padding: 0;
                                        overflow: hidden;
                                        color: $dark-grey2;
                                        font-size: rem(14);
                                        line-height: 1.57;
                                        background: $white;

                                        h3.section-title {
                                            margin-top: rem(30);
                                            color: $dark-grey;
                                            font-family: $helvetica;
                                            font-size: rem(14);
                                            font-weight: 700;
                                            line-height: 1.57;
                                            text-decoration: none;
                                        }

                                        &__summary {
                                            flex: 2;

                                            .about-business {
                                                @media all and (min-width: $md-breakpoint) {
                                                    display: flex;
                                                    padding: rem(30);
                                                }

                                                align-content: center;
                                                flex-flow: row nowrap;
                                                padding-top: rem(5);

                                                a {
                                                    display: block;
                                                    margin-top: rem(2);
                                                    color: $light-blue;
                                                    font-family: $helvetica-bold;
                                                }

                                                &__address {
                                                    flex: 1;
                                                }
                                                &__hours {
                                                    flex: 1;

                                                    tr {
                                                        td:nth-child(even) {
                                                            text-align: right;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &__map {
                                            @media all and (min-width: $md-breakpoint) {
                                                padding: 0 0 0 rem(33);
                                            }
                                            flex: 1;
                                            padding: rem(20) 0 rem(10);

                                            .mapbox_store_map {
                                                @media all and (max-width: $sm-breakpoint) {
                                                    height: rem(324);
                                                }
                                                height: rem(367);

                                                * {
                                                    outline: none;
                                                }
                                            }

                                        }
                                    }

                                    .store_link {
                                        @media all and (min-width: $md-breakpoint) {
                                            padding-top: rem(0);
                                            margin: 0;
                                        }
                                        position: relative;
                                        margin: rem(8) rem(-20) rem(-15);

                                        a {
                                            @media all and (min-width: $md-breakpoint) {
                                                padding: rem(39) rem(43) rem(40);
                                            }
                                            display: block;
                                            padding: rem(39) rem(28) rem(40) rem(24);
                                            color: $white;
                                            font-family: $helvetica-light;
                                            font-size: rem(18);
                                            font-weight: 100;
                                            background: $light-blue;
                                            transition: all 0.2s;

                                            &:after {
                                                @media all and (max-width: $md-breakpoint) {
                                                    right: rem(15);
                                                }
                                                position: absolute;
                                                top: 50%;
                                                right: rem(25);
                                                width: 0;
                                                height: 0;
                                                margin-top: rem(-6);
                                                border: 6px solid transparent;
                                                border-left: 6px solid #FFFFFF;
                                                content: '';
                                            }

                                            &:hover {
                                                background: rgba($light-blue, 0.9);
                                                //opacity: 0.9;
                                            }
                                        }
                                    }

                                    .banner {
                                        @media all and (min-width: $md-breakpoint) {
                                            padding-top: rem(0);
                                            margin: 0;
                                        }

                                        position: relative;
                                        display: flex;
                                        justify-content: center;
                                        padding-top: rem(15);
                                        margin: 0 rem(-20);
                                        overflow: hidden;
                                        text-align: center;
                                        background: none;

                                        p {
                                            padding: 0;
                                            margin: 0;
                                        }

                                        img {
                                            //max-width: 100%;
                                            width: rem(1200);
                                        }

                                        .promotional-offer-box {
                                            @include container($container, $gutter);

                                            @media all and (min-width: $md-breakpoint) {
                                                //padding: rem(170) rem(50);
                                            }

                                            position: absolute;
                                            right: 0;
                                            left: 0;
                                            display: flex;
                                            align-items: flex-end;
                                            height: 100%;
                                            padding: 0;
                                            //padding-top: rem(140);

                                            .promotional-offer {
                                                @media all and (min-width: $sm-breakpoint) {
                                                    width: 387px;
                                                    height: 387px;
                                                    margin: 0 0 rem(90) rem(44);
                                                }

                                                display: flex;
                                                flex-flow: column nowrap;
                                                justify-content: flex-start;
                                                width: 100%;
                                                height: rem(325);
                                                padding: rem(28);
                                                margin: 0 rem(17) rem(40);
                                                color: $white;
                                                text-align: left;
                                                border: 4px solid $white;

                                                small {
                                                    display: block;
                                                    flex: 2;
                                                    font-size: rem(14);
                                                    font-weight: 700;
                                                }

                                                p {
                                                    display: flex;
                                                    align-items: flex-start;
                                                    flex: 10;
                                                    font-family: $helvetica-light;
                                                    font-size: rem(32);
                                                    font-weight: 100;
                                                    line-height: 1.31;
                                                }

                                                a {
                                                    display: block;
                                                    flex: 1;
                                                    font-size: rem(14);
                                                }

                                                &.align-right {
                                                    //align-items: flex-end;
                                                    //justify-content: flex-end;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.active {
                                    display: block
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
