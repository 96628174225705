.customer-account-login {

    h2.page-title {
        @media all and (min-width: $md-breakpoint) {
            padding:0;
            margin: rem(10) 0 rem(60) !important;
        }
        padding: 0 rem(24);
    }

    .login-page a {
        font-family: $helvetica-light;

        &:hover {
            text-decoration: underline;
        }
    }

    .modal__content {
        @media all and (min-width: $md-breakpoint) {
            padding: rem(56) rem(92) rem(60) !important;
        }
        padding: rem(56) rem(20) rem(60) !important;

    }

    .modal__footer p {
        font-family: $helvetica-light;
    }
}