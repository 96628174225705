.categories {  
    &__toolbar {
        &--top {
            position: relative;
            @include container($xl-container, $gutter);
            margin-bottom: rem(16);

            @media all and (min-width: $md-breakpoint ) {
                margin-bottom: rem(32);
            }

            .toolbar-products {
                position: relative;

                @media all and (max-width: $md-breakpoint - 1 ) {
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .pages, .modes {
                display: none;
            }

            .field.limiter {
                position: relative;
                left: rem(30);
                display: inline-block;
                width: auto;

                @media all and (max-width: $md-breakpoint - 1 ) {
                    display: none;
                }

                label {
                    display: none;
                }

                .control {
                    display: inline-block;
                }

                select {
                    display: inline-block;
                    width: 100%;
                    //max-width: rem(410);
                    padding: rem(11) rem(32) rem(11) 130px;
                    font-family: $helvetica-bold;
                    font-size: rem(14);
                    background: $white url('../images/arrow-down.svg') no-repeat;
                    background-position: right 16px top 16px;
                    background-size: 11px 5.9px;
                    border: 1px solid #F6F6F6;
                    border-radius: 0;
                    appearance: none;
                    &:focus {
                        outline: none;
                    }
                    &::-ms-expand {
                        display: none; // Hide arrow in IE
                    }
                }

                .limiter-text {
                    position: absolute;
                    top: 12px;
                    left: 18px;
                    font-size: 14px;
                    pointer-events: none;
                }
            }

            .toolbar-amount {
                display: inline-block;

                @media all and (max-width: $md-breakpoint - 1 ) {
                    display: none;
                }
            }

            .sorter {
                @media all and (max-width: $md-breakpoint - 1 ) {
                    position: relative;
                    float: right;
                    width: calc(50% - 15px);
                }

                @media all and (min-width: $md-breakpoint) {
                    position: absolute;
                    top: 6px;
                    right: 0;
                    text-align: right;
                }
                .sorter-action.sort-asc {
                    display: none;
                }
                label {
                    position: absolute;
                    top: 12px;
                    left: 18px;
                    z-index: 10;
                    font-size: 14px;
                    pointer-events: none;

                    @media all and (max-width: $sm-breakpoint - 1 ) {
                        display: none;
                    }
                }

                .select-wrapper {
                    display: inline-block;

                    @media all and (max-width: $md-breakpoint - 1 ) {
                        display: block;
                    }

                    select {
                        display: inline-block;
                        width: 100%;
                        //max-width: rem(410);
                        padding: rem(11) rem(42) rem(11) rem(11);
                        font-family: $helvetica-bold;
                        font-size: rem(14);
                        background: $white url('../images/arrow-down.svg') no-repeat;
                        background-position: right 16px top 16px;
                        background-size: 11px 5.9px;
                        border: 1px solid #F6F6F6;
                        border-radius: 0;
                        appearance: none;

                        @media all and (min-width: $sm-breakpoint) {
                            padding-left: 82px;
                        }

                        &:focus {
                            outline: none;
                        }
                        &::-ms-expand {
                            display: none; // Hide arrow in IE
                        }
                    }
                }
            }
        }
        &--bottom {
            @include container($container, $gutter);
            .modes, .toolbar-amount , .field.limiter, .toolbar-sorter {
                display: none;
            }
        }
    }
    &__subcategories {
        a {
            &:hover, &.active {
                background-color: $lighter-grey;
            }
            i {
                font-size: rem(60);
            }
        }
    }
}

.catalog-category-view {

    .other-suppliers-container {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 104rem;
        padding-right: 2rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        margin-right: auto;
        margin-left: auto;
        @media all and (max-width: $md-breakpoint) {
            flex-direction: column;
        }
        a {
            display: block;
            padding: 1rem;
            margin-top: 0.5rem;
            margin-right: 0.5rem;
            color: #7297A9;
            text-align: center;
            text-transform: none;
            border: 2px solid #7297A9;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: #FFFFFF;
                background-color: #7297A9;
            }
        }
    }

    .page-main {
        position: relative;
    }
    .category-view {
        position:relative;
        width:100%;
        overflow:hidden;
        .category-image {
            img {
                position: relative;
                left:50%;
                display: block;
                width: 120%;
                height: auto;
                max-width: none !important;
                transform:translateX(-50%);

                @media all and (min-width: $md-breakpoint) {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .category-image-container {
        position: relative;
        margin-bottom: rem(10);
        overflow: hidden;

        .ambanners {

            color: $white;
            font-size: 0;
            
            @media all and (min-width: $sm-breakpoint + 1) {
                position: absolute;
                top: 0;
                left: 0;
                width: 29%;
                height: 100%;

                // data-position="4"
                &[data-position='5'] {
                    right: 0;
                    left: initial;
                }
            }

            @media all and (min-width: $md-breakpoint) {
                width: 24.4%;
            }

            .block {
                display: none;
            }
        }

        img[alt='desktop'] {
            @media all and (max-width: $sm-breakpoint) {
                display: none;
            }
        }

        img[alt='mobile'] {
            position: relative;
            top: 0;
            width: 100vw;
            max-width: 100vw !important;

            @media all and (min-width: $sm-breakpoint + 1) {
                display: none;
            }
        }
    }
    .page-title-wrapper {
        margin-bottom: rem(18);
        @include container($xl-container, $gutter);
        @media all and (min-width: $md-breakpoint) {
            margin-top: -15px;
        }
    }
    .products-grid {
        ol {
            width: 100%;
            padding: 0;
            margin: 0;
            li {
                position: relative;
                list-style: none;
                background:#FFFFFF;
                border: 1px solid $lighter-grey;
                transition: all 0.3s;

                &:hover {
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                }
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .product-item-info {
            &.featured {
                .featured-product__caption-content {
                    @media all and (min-width: $md-breakpoint) {
                        top: auto;
                        bottom: rem(70);
                    }
                }
            }
            .product-item-photo {
                position: relative;
                height: 0;
                padding-top: 100%;
            }
            .product-image-photo {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: auto;
            }
            .btn--view {
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                overflow: hidden;
                text-align: center;
                text-transform: uppercase;
                border-color: $lighter-grey;
                border-width: 1px 0 0;
            }

            > a {
                display: block !important;
            }
        }
        .product-items {
            &__container {
                @include container($xl-container, $gutter);

                &--full {
                    li {
                        margin-bottom: rem(30);
                    }
                    width: 100%;
                }
            }
            &__list {
                .product-item-details {
                    padding: rem(20) rem(5);
                    line-height: 1.11;
                    text-align: center;
                    .product-item-link {
                        display: block;
                        margin-bottom: rem(5);
                        color: $light-blue;
                        font-family: $helvetica-light;
                        font-size: rem(20);
                    }
                    .product-price {
                        color: #898989;
                        font-size: rem(14);
                        line-height: 1.43;
                        .price-box {
                            display: inline-block;
                            .price-container .price-label {
                                display: none;
                            }
                        }
                    }
                }
                @media all and (min-width: $lg-breakpoint) {
                    display: flex;
                    margin-right: rem(-15);
                    margin-left: rem(-15);
                }
            }
            &__row {
                @media all and (min-width: $sm-breakpoint) {
                    display: flex;
                    flex: 1 0 50%;
                    flex-basis:50%;
                    flex-wrap: wrap;
                    margin: 0 -(rem(15));
                }

                li {
                    padding-bottom: rem(60);
                    margin-bottom: rem(30);

                    @media all and (min-width: $sm-breakpoint) {
                        flex: 1 0 calc(50% - 30px);
                        flex-basis: calc(50% - 30px);
                        flex-grow: 0;
                        margin: 0 rem(15) rem(30);
                        background-color: $white;
                    }
                    @media all and (min-width: $md-breakpoint) {
                        flex: 1 0 calc(33.33% - 30px);
                        flex-basis: calc(33.33% - 30px);
                        flex-grow: 0;
                    }

                    @media all and (min-width: $lg-breakpoint) {
                        flex: 1 0 calc(25% - 60px);
                        flex-basis: calc(25% - 60px);
                        flex-grow: 0;
                        margin: 0 rem(30) rem(60);
                    }
                }
                &--two {
                    @media all and (min-width: $sm-breakpoint) {
                        flex: 1 0 50%;
                        flex-basis:50%;
                        flex-wrap: wrap;
                    }
                    li {
                        margin-bottom: rem(30);
                        @media all and (min-width: $sm-breakpoint) {
                            flex: 1 0 calc(50% - 30px);
                            flex-basis: calc(50% - 30px);
                            margin: 0 rem(15) rem(30);
                            background-color: $white;
                        }
                    }
                }
                &--one {
                    @media all and (min-width: $sm-breakpoint) {
                        flex: 1 0 50%;
                        flex-basis:50%;
                    }
                    li {
                        margin-bottom: rem(30);
                        @media all and (min-width: $sm-breakpoint) {
                            flex: 1 0 calc(100% - 30px);
                            flex-basis: calc(100% - 30px);
                            margin: 0 rem(15) rem(30);
                            background-color: $white;
                        }
                    }
                }
                &--four {
                    @media all and (min-width: $sm-breakpoint) {
                        flex: 1 0 100%;
                        flex-basis:100%;
                        flex-wrap: wrap;
                    }
                    li {
                        margin-bottom: rem(30);
                        background-color: $white;
                        @media all and (min-width: $sm-breakpoint) {
                            flex: 1 0 calc(50% - 30px);
                            flex-basis: calc(50% - 30px);
                            margin: 0 rem(15) rem(30);
                        }
                        @media all and (min-width: $lg-breakpoint) {
                            flex: 1 0 calc(25% - 30px);
                            flex-basis: calc(25% - 30px);
                            margin: 0 rem(15) rem(30);
                        }
                    }
                }

                .is-placeholder {
                    flex-grow: 1;
                    opacity: 0;
                }
            }
        }
    }

    .category-info {
        @include container($xl-container, $gutter);
        margin-bottom: rem(30);
        text-align: center;

        @media all and (min-width: $md-breakpoint) {
            h1 {
                margin-top: 0;
            }
        }

        @media all and (min-width: $lg-breakpoint) {
            margin-bottom: rem(44);
        }
    }

    .category-description {
        width: 90%;
        margin: 0 auto;

        @media all and (min-width: $md-breakpoint) {
            width: 60%;
        }

        @media all and (max-width: $md-breakpoint - 1 ) {
            display: none;
        }

        p {
            color: $dark-grey2;
            font-family: $helvetica-light;
            line-height: 1.57;
        }
    }
    .amlabel-position-wrapper {
        z-index: 9 !important;
        display: none !important;

        .amasty-label-container:nth-child(2) {
            display: none !important;
        }
    }
    .gallery .amlabel-position-wrapper {
        display: block !important;
    }
}

.product-attr {
    margin-top: rem(20);
    font-size: rem(12);
    line-height: rem(14);

    &__square {
        display: inline-block;
        margin-right: rem(8);
        vertical-align: middle;

        span {
            display: inline-block;
            width: rem(10);
            height: rem(11);
            background: $orange;

            &:nth-child(2) {
                background: $rose;
            }

            &:last-child {
                background: $light-blue;
            }
        }
    }
}

.message.info.empty{
    @include container($container, $gutter);
    margin-top:rem(10);
    margin-bottom:rem(100);
    color: $dark-grey;
    text-align: center;
}

#amasty-shopby-product-list {
    position: relative;
}

.product__spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 12;
    padding-top: 10%;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);

    .cssload-loader {
        top: 80px;
    }
}
