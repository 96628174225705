.cms-page-view {
    p {
        font-family: $helvetica-light;
        font-size: rem(14);
        font-weight: 100;
        line-height: 1.5;
    }

    .page-main {
        @extend .container;
        padding-top: rem(20);
        color: $dark-grey2;
    }

    .page-title {
        @media all and (max-width: $sm-breakpoint) {
            margin: rem(24) 0;
        }
        margin: rem(32) 0;
    }

    .cms-content {
        color: $dark-grey2;
    }

    .cms-banner {
        position:relative;
        width:100%;
        overflow:hidden;
        .banner-image {
            &:before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                width: 30%;
                background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                content: '';
            }
            img {
                position: relative;
                left: 50%;
                display: block;
                width: 125%;
                height: auto;
                max-width: none !important;
                transform:translateX(-50%);

                &.comfort-club-badge {
                    position: absolute;
                    top: 50%;
                    left: 10%;
                    z-index: 2;
                    width: 15%;
                    transform:translateY(-50%);
                }

                @media all and (min-width: $md-breakpoint) {
                    width: 100%;
                    height: auto;
                    &.comfort-club-badge {
                        width: 12.5%;
                    }
                }
            }
        }
    }
}

.cms-title {
    margin: rem(30) auto;
    text-align: center;

    @media all and (min-width: $md-breakpoint) {
        max-width: 1000px;
        margin: rem(70) auto rem(40);
    }

    h1 {
        color: $light-blue;
        font-family: $helvetica-light;
        font-size: rem(24);

        @media all and (min-width: $md-breakpoint) {
            font-size: rem(32);
        }

        @media all and (min-width: $lg-breakpoint) {
            margin-bottom: rem(40);
        }
    }

    p {
        font-size: rem(16);
    }
}
