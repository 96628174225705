.news-updates {
    #ct-field-34 {
        padding-top: rem(80);
        @include container($container, $gutter);
        >h1,
        >h2{
            margin-bottom: rem(70);
            color: $white;
            font-family: $helvetica-light;
            font-size: rem(32);
            font-weight: 300;
        }
    }

    .slick-initialized {
        @media all and (max-width: $sm-breakpoint-max) {
            margin: 0;
        }

        .slick-list {
            background: transparent !important;
        }
    }

    .contents-items {
        @media all and (max-width: $md-breakpoint) {
            //display: block;
        }
        @media all and (min-width: $md-breakpoint) {
            display: flex;
            justify-content: space-between;
        }
        .content-item {
            @media all and (max-width: $sm-breakpoint-max) {
                min-height: rem(600);
                padding: 0 10px;
                margin: 0;
            }
            display: flex;
            flex-direction: column;
            background: $white;
            &__text {
                display: flex;
                flex: 1 0 auto;
                flex-direction: column;
                padding: rem(30);
                color: $dark-grey2;
                font-size: rem(14);
                line-height: 1.4;
                text-align: center;

                h2 {
                    margin-top: 0;
                    margin-bottom: rem(23);
                    color: $bright-blue;
                    font-family: $helvetica-light;
                    font-size: rem(20);
                    line-height: 1.4;
                }
            }
            &__content {
                margin-bottom: auto;
                font-size: 16px;
            }
            &__tags {
                margin-top: auto;
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0;
                    margin: rem(30) 0 rem(5);
                }
                li {
                    margin-right: rem(3);
                    margin-bottom: rem(6);
                    list-style: none;
                    p {
                        margin: 0;
                    }
                    a {
                        padding: rem(6) rem(10);
                        color: #999999;
                        font-size: rem(10);
                        line-height: 2.2;
                        text-transform: uppercase;
                        border: 1px solid $light-grey;
                    }
                }
            }
            @media all and (min-width: $md-breakpoint) {
                &:hover {
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                }
            }

        }
        > div {
            position:relative;
            color: $dark-grey2;
            background-color: $white;
            @media all and (min-width: $md-breakpoint) {
                width: 33.33%;
                margin-right: rem(32);
            }
            a {
                display:block;
                overflow:hidden;
                text-decoration: none;
            }
            img {
                width: 100%;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    &__more {
        padding-bottom: rem(50);
        margin-top: rem(-215);
        background-color: $lighter-grey;
        &-container {
            text-align: center;
            @include container($container, $gutter);
            a {
                margin-top: rem(290);
                text-align: left;
            }
        }
    }
}