.newsletter-signup-wrapper {
    background-color: #F0F4F6;

    .text-content {
        h3 {
            position: relative;
            display: inline-block;
            color: $bright-blue;
            font-family: $helvetica;
            line-height: 2.5;

            &:before {
                position: absolute;
                left: 10%;
                width: 80%;
                height: 2px;
                background-color: $light-grey;
                content: '';
            }
        }
    }

    .comfort-club-signup {
        .fieldset {
            width: 100%;
            margin: 0;
        }

        .control.field {
            width: 100%;
            padding: 5px 10px;
            margin: 0;
            text-align: center;

            @media all and (min-width: $xs-breakpoint) {
                width: calc((100% / 2 ) - 65px);
            }

            @media all and (min-width: $sm-breakpoint) {
                width: calc((100% / 2 ) - 90px);
            }

            &.action {
                @media all and (min-width: $xs-breakpoint) {
                    width: 130px;
                }

                @media all and (min-width: $sm-breakpoint) {
                    width: 180px;
                }

                .btn[type=submit] {
                    padding-right: 10px;
                    padding-left: 10px;
                }
            }
        }
    }
}

.page-footer {
    position: relative;
    color: $bright-blue;
    background-color: $white;
    border-top: 1px solid $lighter-grey;

    .footer {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: rem(50);
        text-align: center;
        transition: all 0.3s ease-in-out;

        @media all and (min-width: $md-breakpoint) {
            display: block;
            min-height: rem(420);
            padding-top: rem(120);
            text-align: left;
        }
        @media all and (min-width: $lg-breakpoint) {
            min-height: rem(320);
            padding-top: rem(50);
        }
        @media all and (min-width: $xlg-breakpoint) {
            @include container($xl-container, $xl-gutter);
        }        
        .newsletter,
        .nav-toggle {
            display: none;
        }
        .logo {
            @media all and (min-width: $md-breakpoint) {
                position: absolute;
                top: rem(40);
                left: rem(40);
                display: block;
                margin-bottom: 0;
            }
            display: none;
            order: 1;
            margin-bottom: rem(35);
            outline: none;

            img {
                width: rem(208);
            }
        }
        .navigation {
            order: 1;
            margin-bottom: rem(35);
            outline: none;
            &.au .occasional {
                display: none;
            }
            > ul {
                padding: 0;
                margin: 0;
                text-align: left;
                list-style: none;
                @media all and (min-width: $md-breakpoint) {
                    display: flex;
                    justify-content: space-between;
                    max-width: 80%;
                    margin: auto;
                    > li {
                        > span {
                            font-family: $helvetica;
                            font-size: rem(19);
                            line-height: rem(40);
                        }
                        > ul {
                            display: block;
                            padding: rem(8) 0;
                            list-style: none;
                            > li {
                                > a {
                                    display: inline-block;
                                    color: $dark-grey;
                                    font-size: rem(15);
                                    line-height: rem(32);
                                    &:hover {
                                        color: $bright-blue;
                                    }
                                }
                            }
                        }
                    }
                }
                @media all and (min-width: $lg-breakpoint) {
                    max-width: 60%;
                }
                @media all and (min-width: $xlg-breakpoint) {
                    max-width: 50%;
                }
                @media all and (max-width: $sm-breakpoint-max) {
                    border-top: 1px solid $lighter-grey;
                    > li {
                        position: relative;
                        display: block;
                        padding: rem(16) rem(32);
                        border-bottom: 1px solid $lighter-grey;
                        &.level0 .toggle-nav {
                            position: absolute;
                            top: 0;
                            right: rem(20);
                            z-index: 10;
                            display: block;
                            width: rem(48);
                            height: rem(48);
                            padding: 0;
                            line-height: rem(48);
                            text-align: center;
                            &:after {
                                color: $bright-blue;
                                font-size: rem(20);
                                content: '+';
                            }
                        }
                        &.open > .toggle-nav:after {
                            font-size: rem(22);
                            content: '-';
                        }
                        > a {
                            display: inline-block;
                            font-family: $helvetica;
                            font-size: rem(15);
                            line-height: rem(48);
                            letter-spacing: rem(1);
                        }
                        > ul {
                            display: none;
                            padding: rem(16) 0;
                            list-style: none;
                            opacity: 0;
                            transition: all 0.3s ease-in-out;
                            &.open {
                                display: block;
                                opacity: 1;
                            }
                            > li {
                                > a,
                                > span {
                                    display: inline-block;
                                    color: $dark-grey;
                                    font-size: rem(15);
                                    line-height: rem(32);
                                }
                            }
                        }
                    }
                }
            }
        }
        .floating-logo {
            display: none;
        }
        &__links {
            order: 2;
            color: $bright-blue;
            span {
                display: inline-block;
                margin-right: rem(16);
                font-size: rem(12);
            }
            @media all and (max-width: $sm-breakpoint-max) {
                span {
                    display: none;
                }
            }
            @media all and (min-width: $md-breakpoint) {
                position: absolute;
                right: 0;
                bottom: rem(40);
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                padding-right: rem(32);
            }
            a:hover {
                text-decoration: underline;
            }
            .social-icon {
                margin-top: rem(20);
                margin-bottom: rem(20);
                @media all and (min-width: $md-breakpoint) {
                    margin-top: 0;
                    margin-bottom: 0;
                    font-size: rem(12);
                }
            }
        }
        &__copyright {
            order: 3;
            &-links {
                @media all and (min-width: $md-breakpoint) {
                    position: absolute;
                    bottom: rem(40);
                    left: 0;
                    display: flex;
                    padding: rem(8) rem(40);
                }
            }
            &-text {
                margin-bottom: rem(45);
                font-family:$helvetica-light;
                font-size: rem(12);

                @media all and (min-width: $md-breakpoint) {
                    margin-bottom: 0;
                }

                &--mobile {
                    @media all and (min-width: $md-breakpoint) {
                        display: none;
                    }
                }

                &--desktop {
                    display: none !important;
                    @media all and (min-width: $md-breakpoint) {
                        display: block !important;
                    }
                }
            }
            &-join {
                margin-top: rem(56);
                margin-bottom: rem(36);
                @media all and (min-width: $md-breakpoint) {
                    position: absolute;
                    right: rem(20);
                    bottom: rem(46);
                    margin-top: 0;
                    margin-bottom: 0;
                }
                .btn {
                    margin:auto;
                }
            }
            
            .btn:hover {
                color: $blue;
                background: $white;
                outline: none;
            }
        }

        .footer-storelocator-wrapper {
            display: none;
            @media all and (min-width: $md-breakpoint) {
                position: absolute;
                top: 60px;
                right: 50px;
                left: auto;
                display: block;
            }

            @media all and (min-width: $lg-breakpoint) {
                top: 160px;
                right: auto;
                left: 40px;
            }

            a {
                width: auto;
                padding: 10px 15px;
            }
        }
    }

    a.btn {
        display: none;

        &:first-child {
            display: block;
        }
    }
}