.pager, .pages {
    @include container($container, $gutter);
    padding: rem(32) 0 rem(60);
    .toolbar-amount, .label.pages-label {
        display: none;
    }
    .items {
        padding: 0;
        margin: 0;
        text-align: center;
        .item {
            display: inline-block;
            margin-right: 2px;
            margin-left: 2px;
            color: #999999;
            // font-family: $helvetica-bold;
            font-size: rem(14);
            // font-weight: 700;
            line-height: rem(28);
            vertical-align: middle;
            list-style: none;
            &.current {
                > a, > strong {
                    display: block;
                    width: rem(32);
                    height: rem(30);
                    color: $light-blue;
                    border: 1px solid $light-blue;
                    // border: 3px solid transparent;
                }
            }
            a:not(.action) {
                display: block;
                width: rem(32);
                height: rem(30);
                border: 1px solid #F8F8F8;
                transition: all 0.2s;
                &:hover {
                    display: block;
                    // height: rem(34);
                    border: 1px solid $light-blue;
                }
            }
            &__next {
                margin-left: rem(20);
                color: $light-blue;
                font-size: rem(14);
                line-height: rem(34);

                &--disabled {
                    color: #E5E5E5;
                }
                @media all and (min-width: $xs-breakpoint) {
                    &:before {
                        display: inline-block;
                        padding-right: 15px;
                        content: 'Next';
                    }
                }

                .custom-arrow {
                    display: inline-block;
                    transform: scaleX(-1);
                }
            }
            &__previous {
                display: block;
                margin-right: rem(20);
                color: $light-blue;
                font-size: rem(14);
                line-height: rem(34);

                &--disabled {
                    color: #E5E5E5;
                }
                @media all and (min-width: $xs-breakpoint) {
                    &:after {
                        display: inline-block;
                        padding-left: 15px;
                        content: 'Previous';
                    }
                }
            }
        }
        .pages-item-previous,
        .pages-item-next {
            border: 0;
        }
    }
}
