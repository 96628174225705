.contentmanager-contenttype-policies {

    .cms-policies-wrapper {

        @media all and (max-width: $md-breakpoint) {
            padding: rem(21) 0 rem(52);
        }
        padding: rem(52) 0;

        .sub-navigation {
            @media all and (max-width: $md-breakpoint) {
                display: block;
                margin: 0 rem(15) rem(68);
            }
            display: none;
        }

        article.warranty-container {
            @include container($container, $gutter);

            color: $dark-grey2;
            font-family: $helvetica-light;
            font-size: rem(14);
            line-height: 1.57;

            h1.title {
                @media all and (max-width: $sm-breakpoint) {
                    //margin: rem(28) rem(15);
                    margin: rem(28) 0;
                }
                margin: rem(28) 0;
                color: $light-blue;
                font-family: $helvetica-light;
                font-size: rem(32);
                font-weight: 300;
                line-height: 1.31;
            }
        }

        &.sub_menu--inactive {
            @media all and (max-width: $sm-breakpoint) {
                padding: rem(20) 0 rem(22);
            }
            padding: rem(114) 0 rem(132);
            text-align: center;

            h1.title {
                @media all and (max-width: $sm-breakpoint) {
                    margin: rem(50) 0 rem(15) !important;
                }

                margin: rem(28) 0 rem(50) !important;
            }

            .cms-warranty {
                display: block !important;

                &__content--body {
                    @media all and (min-width: $md-breakpoint) {
                        padding: 0 10%;
                    }
                    color: #666666;
                    font-size: rem(20);
                    font-weight: 300;
                    line-height: 1.5;
                }
            }

            .thanks-button {
                @media all and (max-width: $sm-breakpoint) {
                    margin-top: rem(60);
                }
                margin-top: rem(72);
            }
        }
    }

    .cms-warranty {
        display: flex;

        &__content {
            @media all and (max-width: $sm-breakpoint) {
                //padding: 15px;
                margin-bottom: rem(60);
            }

            flex: 1 0 calc(100% - 300px);
            flex-basis: calc(100% - 300px);
            flex-flow: row nowrap;
            margin-bottom: 0;

            &--headline {
                margin: rem(23) 0;
                color: $dark-grey2;
                font-size: rem(20);
                font-weight: 300;
                line-height: 1.5;
            }

            .elevated-title label.label{
                top: rem(-18) !important;
                left: rem(50);
            }

            .hidden-title label.label{
                display: none !important;
            }

            .webforms-datepicker {
                float: none;
                margin-bottom: 0!important;
            }

            .webforms-calendar .ui-datepicker-trigger {
                font-size:rem(14);
            }

            input[type='radio'],
            input[type='checkbox'] {
                margin-right: rem(22);
                &:after {
                    position: absolute;
                    display: block;
                    width: rem(24);
                    height: rem(24);
                    margin: rem(-5) 0 0 rem(-3);
                    background: $white;
                    border-radius: rem(12);
                    content: '';
                    transition: all 0.4s;
                }

                &:hover:after {
                    background: $light-blue;
                }

                &:checked:after {
                    background: $blue;
                }
            }

            input[type='checkbox'] {
                &:after {
                    border-radius: 0;
                }
            }

            .field.flex-inline .control > div,
            .field.flex-half .control > div,
            .field.flex-fourth .control > div,
            .field.flex-third .control > div {
                display: flex;
                align-items: flex-start;
                flex-direction: row;
                flex-flow: row wrap;

                .field {
                    @media all and (max-width: $md-breakpoint) {
                        flex: 1 0 100%;
                    }
                    align-items: center;
                    flex: 1 0 44%;
                }
            }

            .field.flex-third .control > div .field {
                flex: 1 50% 28%;
            }

            .field.flex-fourth .control > div .field {
                flex: 1 50% 21%;
            }

            .field.flex-inline .control > div .field {
                display: flex;
                flex: 0;
                input[type='radio'] {
                    // margin-right: rem(5);
                }
            }

            .field.has-select .control {
                overflow: hidden;
                background: $white url('../images/arrow-grey.png') no-repeat;
                background-position: right;

                select {
                    background: transparent;
                }
            }

            .has-mobile-margin-top {
                @media all and (max-width: $md-breakpoint) {
                    margin-top: rem(20);
                }
            }

            .webforms-calendar {
                input[type='text'] {
                    background: $white url('../images/arrow-grey.png') no-repeat center right;
                }

                button {
                    opacity: 0;
                }
            }
        }
        &__link {
            @media all and (max-width: $md-breakpoint) {
                display: none;
            }
            flex: 1 0 rem(267);
            padding-left: rem(30);

            ul {
                padding:0;
                margin:0;
                list-style-type: none;
                li {
                    border-bottom: 1px solid $light-black;
                    a {
                        display: block;
                        padding: rem(20) 0;
                        font-size: rem(14);
                        font-weight: 700;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    &:first-child{
                        border-top:1px solid $light-black;
                    }

                    &:hover,
                    &.active {
                        color: $blue;
                        border-right: 6px solid $blue;
                    }
                }
            }
        }
    }
}

.cms-warranty {

    .sidebar.sidebar-additional {
        display: none;
    }

    .footer-coloured-blocks {
        margin-right: rem(-10);
        margin-left: rem(-10);
    }

    .page-main {
        @extend .container;
        padding-top: rem(50);
    }

    p {
        a {
            color: $light-blue;
        }
    }

    .page-title {
        @media all and (min-width: $sm-breakpoint) {
            padding-left: 0;
            margin: rem(33) 0 rem(33);
        }

        padding-left: rem(15);
        margin-bottom: rem(25);
    }

    .main {
        color: #666666;
        font-family: $helvetica-light;
        font-size: rem(20);
        line-height: 1.5;
    }

    .cms-warranty {
        display: flex;
        &__form {
            @media all and (max-width: $sm-breakpoint) {
                padding: 15px;
                margin-bottom: rem(60);
            }
            margin-bottom: 0;

            p {
                color: $dark-grey3;
                font-size: rem(14);
                line-height: 1.57;

                &.title {
                    color: $dark-grey2;
                    font-size: rem(20);
                    font-weight: 300;
                    line-height: 1.5;
                }
            }

            .elevated-title label.label{
                top: rem(-18) !important;
                left: rem(50);
            }

            .hidden-title label.label{
                display: none !important;
            }

            .webforms-datepicker {
                float: none;
                margin-bottom: 0!important;
            }

            .webforms-calendar .ui-datepicker-trigger {
                font-size:rem(14);
            }

            input[type='radio'],
            input[type='checkbox'] {
                margin-right: rem(22);
                &:after {
                    position: absolute;
                    display: block;
                    width: rem(24);
                    height: rem(24);
                    margin: rem(-5) 0 0 rem(-3);
                    background: $white;
                    border-radius: rem(12);
                    content: '';
                    transition: all 0.4s;
                }

                &:hover:after {
                    background: $light-blue;
                }

                &:checked:after {
                    background: $blue;
                }
            }

            input[type='checkbox'] {
                &:after {
                    border-radius: 0;
                }
            }

            //.webforms-fields-title div[aria-labelledby],
            //.webforms-fields-q9_answer div[aria-labelledby],
            //.webforms-fields-q10_answer div[aria-labelledby],
            //.webforms-fields-q11_answer div[aria-labelledby],
            //.webforms-fields-q12_answer div[aria-labelledby]{
            //    display: flex;
            //    flex-flow: row wrap;
            //
            //    .field {
            //        align-items: center;
            //        flex: 1 0 rem(70);
            //        //background: rgba(255, 255, 0, 0.2);
            //    }
            //}
            //.webforms-fields-q1_answer div[aria-labelledby],
            //.webforms-fields-q2_answer div[aria-labelledby],
            //.webforms-fields-q3_answer div[aria-labelledby],
            //.webforms-fields-q7_answer div[aria-labelledby],
            //.webforms-fields-q8_answer div[aria-labelledby]{
            //    display: flex;
            //    flex-flow: row wrap;
            //    .field {
            //        align-items: center;
            //        flex: 1 0 44%;
            //        //background: rgba(255, 0, 0, 0.2);
            //    }
            //}
            //
            //.webforms-fields-q4 div[aria-labelledby] div:first-child,
            //.webforms-fields-q5 div[aria-labelledby] div:first-child {
            //    display: flex;
            //    flex-flow:row wrap;
            //    .field {
            //        align-items: center;
            //        flex: 1 0 44%;
            //        //background: rgba(0, 0, 0, 0.2);
            //    }
            //}

            //.webforms-fields-comment {
            //    margin: 40px 0 0 -80px;
            //}

            //.field.type-select-radio > .control > div:first-child,
            //.field.type-select-checkbox > .control > div:first-child {
            //    display: flex;
            //    flex-flow: row wrap;
            //    //background: rgba(255, 0, 0, 0.2);
            //
            //    .field {
            //        align-items: center;
            //        flex: 1 0 44%;
            //    }
            //}

            .field.flex-inline .control > div,
            .field.flex-half .control > div,
            .field.flex-fourth .control > div,
            .field.flex-third .control > div {
                display: flex;
                align-items: flex-start;
                flex-direction: row;
                flex-flow: row wrap;

                .field {
                    @media all and (max-width: $md-breakpoint) {
                        flex: 1 0 100%;
                    }
                    align-items: center;
                    flex: 1 0 44%;
                }
            }

            .field.flex-third .control > div .field {
                flex: 1 50% 28%;
            }

            .field.flex-fourth .control > div .field {
                flex: 1 50% 21%;
            }

            .field.flex-inline .control > div .field {
                display: flex;
                flex: 0;
                input[type='radio'] {
                    // margin-right: rem(5);
                }
            }

            .field.has-select .control {
                overflow: hidden;
                background: $white url('../images/arrow-grey.png') no-repeat;
                background-position: right;

                select {
                    background: transparent;
                }
            }

            .has-mobile-margin-top {
                @media all and (max-width: $md-breakpoint) {
                    margin-top: rem(20);
                }
            }

            .webforms-calendar {
                input[type='text'] {
                    background: $white url('../images/arrow-grey.png') no-repeat center right;
                }

                button {
                    opacity: 0;
                }
            }

            //.field.flex-inline .control div[aria-labelledby],
            //.field.flex-half .control div[aria-labelledby],
            //.field.flex-fourth .control div[aria-labelledby],
            //.field.flex-third .control div[aria-labelledby] {
            //    display: flex;
            //    align-items: flex-start;
            //    flex-direction: row;
            //    flex-flow: row wrap;
            //
            //    .field {
            //        align-items: center;
            //        flex: 1 0 44%;
            //    }
            //}
            //
            //.field.flex-third .control div[aria-labelledby] .field {
            //    flex: 1 0 28%;
            //}
            //
            //.field.flex-fourth .control div[aria-labelledby] .field {
            //    flex: 1 0 21%;
            //}
            //
            //.field.flex-inline .control div[aria-labelledby] .field {
            //    display: flex;
            //    flex: 0;
            //    input[type='radio'] {
            //        margin-right: rem(5);
            //    }
            //}
        }
        &__link {
            @media all and (max-width: $md-breakpoint) {
                display: none;
            }
            flex: 1 0 rem(267);
            padding-left: rem(30);
            ul {
                padding:0;
                margin:0;
                list-style-type: none;
                li {
                    border-bottom: 1px solid $light-black;
                    a {
                        display: block;
                        padding: rem(20) 0;
                        font-size: rem(14);
                        font-weight: 700;
                        
                        &.sub_nav_link {
                            padding-left: rem(25);

                            &:before {
                                margin-right: rem(5);
                                content: '-';
                            }
                        }

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    &:first-child{
                        border-top:1px solid $light-black;
                    }

                    &:hover,
                    &.active {
                        color: $blue;
                        border-right: 6px solid $blue;
                    }
                }
            }
        }
    }

    .actions-toolbar {

        &__wrapper {
            @media all and (max-width: $md-breakpoint) {
                display: block;
            }
            display: flex;
            flex-flow: row wrap;

            .secondary {
                margin-left: rem(20);
                .loading-indicator {
                    display: none;
                }

                .please-wait {
                    //display: block !important;
                    span {
                        display: none;
                    }

                    .lazboy-spinner {
                        @media all and (max-width: $md-breakpoint) {
                            top: rem(10);
                        }
                        //zoom: 50%;
                        transform: scale(0.5);
                    }
                }
            }
        }
    }

    .webforms {
        margin-top: rem(0);

        .fieldset {
            h3 {
                margin: rem(40) rem(10) rem(20);
            }

            &.fs-addresses h3,
            &.fs-purchase h3 {
                margin: rem(8) 0;
                span {
                    visibility: hidden;
                }
            }
        }

        .fs-product-and-store-details {
            margin-top: rem(30);
        }

        .field {
            //overflow: hidden;
        }

        select{
            //background: $white url('../images/arrow-grey.png') no-repeat;
            //background-position: right;
            width: 110%;
            background: transparent;
            border: none;
            border-radius: 0;
            appearance: none;
        }

        .webforms-fields-wrf-month label.label,
        .webforms-fields-wrf-year label.label,
        .webforms-fields-wrf-country label.label{
            top: rem(30);
        }


        .recaptcha {
            @media all and (max-width: $sm-breakpoint) {
                display: flex;
            }
            display: inline-block;
            justify-content: center;
            float: none;
            width: 100%;
            margin: rem(50) 0 rem(45);
            text-align: center;
        }

        button.submit {
            @media all and (max-width: $md-breakpoint) {
                width: 100%;
            }
        }
    }

    .footer-coloured-blocks {
        @media all and (max-width: $sm-breakpoint) {
            margin: 0 rem(-20) rem(100);
        }
    }
}

#ui-datepicker-div {
    padding: rem(30) rem(20) rem(40);
    background: $white;
    border: none;
    box-shadow: 0 0 rem(10) rgba(0, 0, 0, 0.2);

    table.ui-datepicker-calendar {
        border: none;

        tr {
            td:first-child,
            td:last-child,
            th:first-child,
            th:last-child {
                color: #FA4832;
                opacity: 0.8;

                a {
                    color: #FA4832;
                }
            }
        }
        th, td {
            padding: rem(4) rem(6);
            background: transparent;
            border: none;

            a {
                display: block;
                padding: rem(8);
                color: $light-blue;
                text-align: center;
                transition: all 0.2s;

                &:hover {
                    color: $white;
                    background: $light-blue;
                    border-radius: rem(20);
                }

                //&.ui-state-highlight,
                &.ui-state-active {
                    color: $white !important;
                    background: $blue;
                    border-radius: rem(20);
                    box-shadow: 0 0 rem(10) rgba(0, 0, 0, 0.3);
                    opacity: 1!important;
                }
            }
        }
    }

    .ui-datepicker-header {
        //box-shadow: -5px rem(10) rem(10) rgba(0, 0, 0, 0.2);
        font-family: $helvetica-light;

        .ui-datepicker-next span {
            border-color: transparent transparent transparent $light-blue;
        }
        .ui-datepicker-prev span {
            border-color: transparent $light-blue transparent transparent;
        }

        .ui-datepicker-title {
            display: flex;
            align-items: center;
            flex-flow: row wrap;

            span {
                width: 100%;
                margin: 0;

                &:first-child {
                    font-size: rem(20);
                }
                &:last-child {
                    color: $light-grey;
                    font-size: rem(14);
                }
            }
        }
    }

    .ui-datepicker-buttonpane {
        display: none;
    }
}