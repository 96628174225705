.account {
    .page-main {
        @extend .container;
        padding-top: rem(50);
    }

    .actions-toolbar {
        margin-top: rem(5) !important;
        text-align: center;
    }

    .footer-coloured-blocks {
        @media all and (max-width: $md-breakpoint) {
            //margin: rem(76) rem(-20) rem(100);
            margin: rem(76) rem(-20) 0;
        }
        margin-top: rem(90);
    }

    .page-title-wrapper {
        @media all and (max-width: $sm-breakpoint) {
            margin: rem(20) 0 rem(36);
        }
        margin: rem(36) 0 rem(60);
    }

    .subhead-content {
        margin-bottom: rem(35);
        color: $dark-grey2;
        font-family: $helvetica-light;
        font-size: rem(20);
        font-weight: 300;
        line-height: 1.5;
    }

    .edit-content {
        margin-bottom: rem(20);
        color: $dark-grey2;
        font-family: $helvetica-light;
        font-size: rem(14);
        line-height: 1.57;
    }

    .profile-field-country {
        margin-bottom: rem(75);
    }

    .hidden-label {
        display: none;
    }

    .primary button {
        @media all and (max-width: $md-breakpoint) {
            width: 100%;
        }
    }

    fieldset.password {
        margin: 0 rem(-15) rem(70);
    }
}
