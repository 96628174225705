.breadcrumbs {
    // position: fixed;
    // top: $header-height;
    // z-index: 11;
    // display: none;
    // width: 100%;
    @include container($xl-container, $gutter);
    min-height: $breadcrumbs-height;
    // color: $white;
    font-family: $helvetica-light;
    font-size: rem(12);
    // background-color: rgba($blue, 0.9);

    @media all and (min-width: $md-breakpoint) {
        display: flex;
        justify-content: space-between;
    }

    @media all and (max-width: $md-breakpoint - 1 ) {
        display: none;
    }

    &.hideElement{
        display:none;
    }

    .items {
        padding: rem(21) 0;
        margin: 0;
        list-style-type: none;
    }

    .item {
        position: relative;
        display: inline-block;

        &:after {
            margin: 0 rem(5);
            content: '/';
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }

        .active {
            color: $light-blue;
        }
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.account-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: rem(10);

    &-link {
        //display: flex;
        //flex-direction: column;
        //flex-grow: 1;
        //justify-content: center;
        height: 100%;
        min-height: $breadcrumbs-height;
        margin: 0 rem(20);
        font-family: $helvetica-bold;
        line-height: rem(60);
        border-bottom: 4px solid transparent;
        transition: all 0.4s ease;

        &--active, &:hover {
            border-bottom: 4px solid $white;
        }
    }
    a{
        &:hover{
            text-decoration:none;
        }
    }
}
