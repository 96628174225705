.generic-page {
    &__banner {
        margin: 0;
        .image {
        }
        .banner-image {
            display: flex;
            justify-content: center;
            min-height: $full-header-height;

            img {
                @media all and (max-width: $sm-breakpoint) {
                    width: auto;
                }
                height: 100%;
            }
        }
        img {
            width: 100%;
        }
    }

    &__text {
        padding-top: rem(78);
        padding-bottom: rem(100);
        color: $dark-grey2;
        font-size: rem(14);
        line-height: 1.57;

        h1, h2, h3 {
            color: $dark-grey2;
            font-family: $helvetica;
            font-weight: 300;
        }
        h1 {
            margin-bottom: rem(50);
            font-family: $helvetica-light;
            font-size: rem(20);
            line-height: 1.51;
        }
        h2 {
            margin-top: rem(50);
            margin-bottom: rem(25);
            font-size: rem(24);
        }
        h3 {
            margin-top: rem(50);
            margin-bottom: rem(25);
            font-size: rem(18);
        }
        h4 {
            margin-top: rem(50);
            margin-bottom: rem(25);
            color: $dark-grey;
            font-family: $helvetica-bold;
            font-size: rem(14);
        }
        &-title {
            margin-top: 0;
            margin-bottom: rem(70);
            color: $light-blue !important;
            font-size: rem(32) !important;
        }
        address, blockquote {
            padding: rem(45) rem(20) rem(45) rem(32);
            color: $dark-grey3;
            font-family: $helvetica-light;
            font-size: rem(20);
            font-style: italic;
            font-weight: 300;
            line-height: 1.4;
        }
        img {
            width: auto;
            height: auto;
            max-width: 100%;
        }
    }

    &__tags {
        margin: 0 0 rem(43);
        ul {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            margin: rem(30) 0 rem(5);
        }
        li {
            margin-right: rem(3);
            margin-bottom: rem(6);
            list-style: none;
            p {
                margin: 0;
            }
            a {
                padding: rem(6) rem(10);
                color: #999999;
                font-size: rem(10);
                line-height: 2.2;
                text-transform: uppercase;
                border: 1px solid $light-grey;

                &:hover {
                    color: $white;
                    background: #989998;
                    border-color: #989998;
                }
            }
        }
    }
}

.contentmanager-contenttype-article {
    .generic-page {
        &__text {
            @include container($container, $gutter);
            @media all and (min-width: $md-breakpoint) {
                max-width: rem(762);
            }
        }
    }
}

.contentmanager-contenttype-inspiration,
.contentmanager-contenttype-policies-x,
.contentmanager-contenttype-generic {

    //@media all and (max-width: $md-breakpoint) {
    //    padding: rem(21) 0 rem(52);
    //}
    //padding: rem(52) 0;

    .sub-navigation {
        @media all and (max-width: $md-breakpoint) {
            display: block;
            margin: rem(21) rem(15) rem(-30);
        }
        display: none;
    }

    .page-main {
        @media all and (max-width: $md-breakpoint) {
            padding-bottom: rem(180);
            margin-bottom: rem(-180);
            //margin-bottom: rem(-100);
        }
        top: 0 !important;


        .banner-image {
            @media all and (max-width: $md-breakpoint) {
                min-height: 0;
            }
        }
    }
    .generic-page {
        &__text {
            @include container($container, $gutter);
            @media all and (min-width: $md-breakpoint) {
                //max-width: rem(762);
            }

            &--title {
                color: $light-blue;
                font-size: rem(32);
                font-weight: 300;
                line-height: 1.31;
            }
            &--body {
                display: flex;

                .content {
                    ul {
                        padding:0;
                        margin: 0 0 0 rem(15);
                        list-style-type: none;

                        li {
                            &:before {
                                position: absolute;
                                display: block;
                                width: rem(20);
                                height: rem(20);
                                margin: rem(-2) 0 0 rem(-15);
                                content: '-';
                            }
                        }
                    }

                    &__headline {
                        color: $blue;
                    }
                }
                .link {
                    @media all and (max-width: $md-breakpoint) {
                        display: none;
                    }
                    flex: 1 0 rem(267);
                    margin-left: rem(31);
                    ul {
                        padding:0;
                        margin:0;
                        list-style-type: none;
                        li {
                            border-bottom: 1px solid $light-black;
                            a {
                                display: block;
                                padding: rem(20) 0;
                                font-size: rem(14);
                                font-weight: 700;

                                &:hover {
                                    text-decoration: none;
                                }
                            }

                            &:first-child{
                                border-top:1px solid $light-black;
                            }

                            &:hover,
                            &.active {
                                color: $blue;
                                border-right: 6px solid $blue;
                            }
                        }
                    }
                }
            }
        }
    }
}


#comfortclub-popup,
#email-popup {
    &-overlay,
    &-success {
        .pref-loading {
            top: 0;
            z-index: 15;
            width: 100%;
            height: 100%;
            background-color: $light-blue;
            background-position: 50%;
        }
        form {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 0.5rem;
            .fieldset {
                margin: 0.5rem 0;
            }
            .terms {
                &:hover {
                    color: #EEEEEE;
                }
            }
        }
        position: fixed;
        top: 10%;
        left: 50%;
        z-index: 14;
        box-sizing: border-box;
        display: none;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        max-width: 30rem;
        min-width: 12.5rem;
        padding: 3rem 3.75rem;
        overflow-y: scroll;
        color: $white;    
        text-align: center;
        background: $light-blue;
        box-shadow: 0 1px 12px rgba(0, 0, 0, 0.175);
        opacity: 0;
        transform: translateX(-50%);
        transition: all 0.3s;
        .custom-close-row {
            display: block;
            width: 100%;
            padding-top: 1rem;
            text-align: right;
            cursor: pointer;
        }
        &.active {
            display: flex;
            opacity: 1;
        }
        @media all and (max-width: $md-breakpoint - 1) {
            top: 0;
            width: 100%;
            height: 100%;
            max-width: 100%;
        }
    }
    &-success {
        height: initial;
        color: $light-blue; 
        background: $white;
        .custom-tick-circle {
            font-size: rem(80);
        }
        .popup-success-row {
            margin: 1rem 0;
            a.btn {
                margin: 0;
            }
        }
        @media all and (max-width: $md-breakpoint - 1) {
            height: 100%;
        }
    }
}

#comfortclub-popup-overlay {
    padding: 0;
    overflow: hidden;
    color: $dark-blue;
    background: rgba(255, 255, 255, 0.75);
    @media all and (min-width: $md-breakpoint) {
        width: fit-content;
        max-width: max-content;
        min-width: none;
    }
    @media all and (max-width: $md-breakpoint - 1) {
        top: calc(#{$header-height} + #{$header-nav-storelocator});
        height: auto;
    }
    .custom-close-row {
        position: absolute;
        top: 0;
        right: 1rem;
        width: auto;
    }
    + .modal-bg {
        background: rgba(255, 255, 255, 0.75);
    } 
}

.cookie-notice-wrapper {
    display: none;
}

.modal-open .cookie-notice-wrapper {
    display: block;
}