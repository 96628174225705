
.guide-wrapper {
    margin-bottom: rem(50);

    @media all and  (min-width: $md-breakpoint) {
        margin-bottom: rem(100);
    }
}

.guide-row {
    @media all and (min-width: $md-breakpoint) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-right: rem(-25);
        margin-left: rem(-25);
    }
}

.guide-item {
    margin-bottom: rem(16);
    cursor: pointer;

    @media all and (min-width: $md-breakpoint) {
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: rem(25);
        padding-left: rem(25);
    }

    &.active {
        .guide-check {
            background: $light-blue;
        }
    }
}

.guide-form-wrapper {
    padding: rem(32);
    // margin-bottom: rem(150);
    text-align: center;
    background: #EDEDED;

    @media all and  (min-width: $md-breakpoint) {
        padding: rem(52);
        margin-bottom: rem(100);
    }

    h2 {
        margin-top: 0;
        color: $light-blue;
    }
}

.guide-form {
    width: 90%;
    margin: rem(30) auto 0;

    @media all and  (min-width: $md-breakpoint) {
        width: 80%;
        margin: rem(40) auto 0;
    }

    .hidden {
        display: none;
    }

    .control {
        width: 100%;
        margin-bottom: rem(30);
    }
}

.guide-form-half {
    justify-content: space-between;

    .control {
        @media all and (min-width: $sm-breakpoint) {
            flex-basis: calc(50% - 18px);
        }
    }
}

.guide-submit {
    margin-top: rem(20);
}

.guide-item__inner {
    @media all and (min-width: $sm-breakpoint) {
        display: flex;
    }
}

.guide-item__thumb {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    @media all and (min-width: $sm-breakpoint) {
        flex-basis: 125px;
        padding-bottom: 6px;
        margin-right: 30px;
    }
}

.guide-item__content {
    position: relative;
    padding: rem(16) 0 rem(32) rem(80);
    @media all and (min-width: $sm-breakpoint) {
        padding: rem(32) rem(80);
    }

    &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        border-bottom: 1px solid $lighter-grey;
        content: '';
    }

    h3 {
        margin: 0 0 rem(12);
        color: $light-blue;
    }

    p {
        margin: 0;
    }
}

.guide-check {
    position: absolute;
    left: 0;
    width: 35px;
    height: 35px;
    color: $white;
    font-size: rem(29);
    line-height: rem(33);
    text-align: center;
    background: $white;
    border: 1px solid $light-blue;
    border-radius: 50%;
    transition: all 0.3s;
}
