#content-filter-block {
    display: none;
}
.ct-listing-news {
    @media all and (max-width: $sm-breakpoint) {
        margin-bottom: rem(40);
    }
    margin-bottom: rem(70);

    &__top-content {
        @include container($container, $gutter);
        @media all and (min-width: $md-breakpoint) {
            display: flex;
            align-items: center;
        }
        .news-title {
            @media all and (min-width: $md-breakpoint) {
                flex: 1 0 auto;
                padding-top: rem(75) !important;
                padding-bottom: rem(113) !important;
            }
            padding-top: rem(50) !important;
            padding-bottom: rem(35) !important;

            h1 {
                margin-bottom: 0;
                color: $light-blue;
                font-family: $helvetica-light;
                font-size: rem(32);
                font-weight: 300;
            }
        }
        //.news-search {
        //    @media all and (min-width: $md-breakpoint) {
        //        flex: 2 0 auto;
        //        text-align: right;
        //    }
        //    label {
        //        display: block;
        //        margin-right: rem(30);
        //        margin-bottom: rem(20);
        //        font-family: $helvetica-light;
        //        font-size: rem(20);
        //        @media all and (min-width: $md-breakpoint) {
        //            display: inline-block;
        //            margin-bottom: 0;
        //        }
        //    }
        //    .select-wrapper {
        //        display: inline-block;
        //        background: #FF0000;
        //        select {
        //            display: inline-block;
        //            width: 100%;
        //            padding: rem(22) rem(24);
        //            margin-bottom: rem(80);
        //            font-family: $helvetica-bold;
        //            font-size: rem(14);
        //            background: $white url('../images/arrow-grey.png') no-repeat;
        //            background-position: right;
        //            border: none;
        //            border-radius: 0;
        //            appearance: none;
        //            @media all and (min-width: $md-breakpoint) {
        //                max-width: rem(410);
        //                margin-bottom: 0;
        //            }
        //            &:focus {
        //                outline: none;
        //            }
        //        }
        //    }
        //}

        .news-search {
            @media all and (min-width: $md-breakpoint) {
                display: flex;
                flex: 2 0 auto;
                text-align: right;
            }
            align-items: center;
            justify-content: flex-end;
            label {
                display: block;
                flex: 1;
                margin-right: rem(30);
                margin-bottom: rem(20);
                font-family: $helvetica-light;
                font-size: rem(20);
                @media all and (min-width: $md-breakpoint) {
                    display: inline-block;
                    margin-bottom: 0;
                }
            }
            .select-wrapper {
                flex: 3;
                height: rem(60);
                overflow: hidden;
                background: $white url('../images/arrow-grey.png') no-repeat;
                background-position: right;

                select {
                    @media all and (min-width: $md-breakpoint) {
                        margin-bottom: 0;
                    }
                    position: relative;
                    /*right: rem(-30);*/
                    display: block;
                    width: 100%;
                    padding: rem(22) rem(24) rem(22) rem(30);
                    margin-bottom: rem(80);
                    font-family: $helvetica-bold;
                    font-size: rem(14);
                    background: transparent;
                    border: none;
                    border-radius: 0;
                    appearance: none;
                    &:focus {
                        outline: none;
                    }
                    &::-ms-expand {
                        display: none; // Hide arrow in IE
                    }
                }
            }
        }
    }

    .content-item {
        display: flex;
        flex-direction: column;
        &__text {
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
        }
        &__content {
            margin-bottom: auto;
        }
        &__tags {
            margin-top: auto;
            ul {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                margin: rem(30) 0 rem(5);
            }
            li {
                margin-right: rem(3);
                margin-bottom: rem(6);
                list-style: none;
                p {
                    margin: 0;
                }
                a {
                    padding: rem(6) rem(10);
                    color: #999999;
                    font-size: rem(10);
                    line-height: 2.2;
                    text-transform: uppercase;
                    border: 1px solid $light-grey;
                    
                    &:hover {
                        color: $white;
                        background: #989998;
                        border-color: #989998;
                    }
                }
            }
        }
    }
}

