.cms-home {
    .page-title-wrapper,
    .coloured-blocks,
    .footer-coloured-blocks,
    .homepage-coloured-block {
        display: none;
    }
    .ambanners {
        position: absolute;
        z-index: 1;
        color: $white;
        font-size: 0;
        div {
            @media all and (min-width: $sm-breakpoint) {
                height: 100%;
                a, img {
                    height: 100%;
                }
            }
        }
        &:empty {
            display: none;
        }

        @media all and (max-width: $sm-breakpoint - 1) {
            right: 0;
            left: 0;
            width: 100vw;
            height: 100vw !important;

            &:not(:empty) {
                ~ .home-content {
                    padding-top: 33.3333vw;
                }
            }

            img[alt='mobile'] {
                position: absolute;
                bottom: 0;
            }

            img[alt='desktop'] {
                display: none;
            }
        }

        @media all and (min-width: $sm-breakpoint) {
            top: 0;
            right: 0;
            left: 0;
            width: 33.3333vw;
            height: 33.2vw !important;
            
            img[alt='mobile'] {
                display: none;
            }
            &:not(:empty) ~ .hidden-xs {
                .custom-slider {
                    .owl-prev {
                        left: 35vw !important;
                    }
                    .owl-dots {
                        left: 65vw !important;
                    }
                }
            }
        }

        .block {
            display: none;
        }
    }

    .home {
        &-content {
            @media all and (min-width: $xlg-breakpoint) {
                @include container($xl-container, $xl-gutter);            
            }
        }
        &__intro {
            padding: rem(32);
            text-align: center;
            h1 {
                margin: 0 0 rem(32);
                @media all and (max-width: $sm-breakpoint-max) {
                    font-size: rem(24);
                }
            }
            .description {
                max-width: rem(1000);
                margin: auto;
                p {
                    color: $dark-grey2;
                    font-family: $helvetica-light;
                    line-height: 1.57;
                }
            }
            @media all and (min-width: $lg-breakpoint) {
                padding: rem(40);
            }
        }
        &__categories-block--mobile {
            padding: 0 rem(16);
            ul {
                display: flex;
                flex: 1 0 auto;
                flex-wrap: wrap;
                padding: 0;
                list-style: none;
            }
            li {
                flex-basis: 100%;
                padding: rem(8);
                @media all and (min-width: $xs-breakpoint) {
                    flex-basis: 50%;
                }
                &.participating-stores {
                    a {
                        padding: 0;
                        button {
                            width: 100%;
                        }
                    }

                }
            }
            a {
                width: 100%;
                padding: rem(16);
                text-align: center;
                text-transform: uppercase;
            }
        
        }
        &__promo {
            &-block--mobile {
                text-align: center;
                .ct-field-image {
                    margin: rem(32) auto;
                }
            }
            h1 {
                margin: 0 0 rem(32);
                @media all and (max-width: $sm-breakpoint-max) {
                    font-size: rem(24);
                }
            }
            @media all and (min-width: $sm-breakpoint) {
                .row {
                    display: flex;
                    align-items: flex-start;
                    flex: 1 0 auto;
                    justify-content: space-between;
                    margin: 0 - rem(8);
                    > div {
                        flex-basis: 100%;
                        padding: rem(8);
                    }
                }
            }
            @media all and (min-width: $lg-breakpoint) {
                .row {
                    margin: 0 - rem(16);
                    > div {
                        padding: rem(16);
                    }
                }
            }
        }
        &__news {
            padding-bottom: rem(32);
            h2 {
                color: $bright-blue;
                font-size: rem(24);
                @media all and (min-width: $md-breakpoint) {
                    font-size: rem(32);
                }
            }
            p {
                font-size: rem(12);
                @media all and (min-width: $md-breakpoint) {
                    font-size: rem(16);
                }
            }
            .news-updates {
                text-align: left;
            }
            .content-item__link {
                margin: rem(32) 0;
                font-family: $helvetica-bold;
            }
            .content-item__tags {
                display: none;
            }
        }
    }

    .column.main {
        h2.mgz-element-heading {
            color: $bright-blue;
            font-size: 2em;
            @media all and (max-width: $sm-breakpoint-max) {
                font-size: rem(24);
            }
        }
    }
}

.best-seller-slider {
    .custom-slider {
        margin-bottom: 40px;

        .owl-theme.owl-carousel {
            .banner-item {
                padding: 0 10px;

                .content_slider {
                    position: relative;
                    top: auto;
                    bottom: auto;
                    order: 10;
                    color: $dark-grey2;
                }
            }

            .owl-item {
                .banner-image {
                    @media all and (min-width: $sm-breakpoint) {
                        min-height: 160px;
                    }
                    @media all and (min-width: $md-breakpoint) {
                        min-height: 200px;
                    }
                    @media all and (min-width: $lg-breakpoint) {
                        min-height: 240px;
                    }
                }
                .banner-title {
                    position: relative;
                    top: auto;
                    bottom: auto;
                    margin-top: 15px;
                    color: $dark-grey2;
                    font-family: $helvetica;
                    font-size: 16px;
                }
                .banner-description {
                    padding-top: 5px;
                    color: $dark-grey2;
                    font-family: $helvetica-light;
                    line-height: 1.57;
                }
                .buttons {
                    margin-bottom: 5px;
                }
                .action.primary {
                    color: $dark-grey2;
                    font-family: $helvetica-bold;
                }
            }

            .owl-dots {
                bottom: -40px;

                .owl-dot {
                    span {
                        background-color: $light-grey;
                    }

                    &.active {
                        span {
                            background-color: $bright-blue;
                        }
                    }
                }
            }
        }
    }

    &.with-heading-and-button {
        .custom-slider {
            .owl-theme.owl-carousel {
                .owl-item {
                    .banner-title {
                        color: $bright-blue;
                        font-family: $helvetica-light;
                        font-size: 18px;
                        line-height: 1.4;
                    }

                    .action.primary {
                        font-size: 14px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

.shop-by-container {
    .shop-by-item {
        .mgz-element-inner {
            > .mgz-element-button {
                margin-top: auto;
            }
        }
    }
}

.lookbooks-container {
    a.mgz-btn {
        &:hover {
            background-color: darken($bright-blue, 10%);
        }
    }
}