.forgot-password-page {
    padding: 0;
    text-align: center;
    .container {
        padding: 0;
    }

    @media all and (min-width: $md-breakpoint) {
        padding: rem(50) 0 rem(50);
    }

    .page-title {
        width: rem(270);
        margin: 0 auto rem(70) auto !important;

        @media all and (min-width: $sm-breakpoint) {
            width: auto;
        }
    }

    .fieldset {
        width: calc(100% + #{rem(20)});
        margin-bottom: 0;
    }

    .note {
        margin-bottom: rem(45);
        font-family: $helvetica-light;
    }

    .actions-toolbar {
        margin-bottom: rem(70);

        @media all and (min-width: $sm-breakpoint) {
            justify-content: center !important;
        }
    }

    a {
        color: $light-blue;
    }
}

.customer-account-forgotpassword {
   
    .page-title-wrapper {
        display: none;
    }

    .page-wrapper {
        padding-top: $header-height;

        .modal__logo {
            display: none;
        }

        @media all and (min-width: $md-breakpoint) {
            padding-top: 0;
            margin-top: $full-header-height;
        }
    }

    .field-recaptcha {
        margin: rem(30) auto rem(0);
    }

    .captcha-required {
        color: $error-color;
    }

    .g-recaptcha.reg_recaptcha {
        margin-top: rem(10);
        margin-bottom: rem(30);

        & > div {
            @media all and (max-width: $sm-breakpoint) {
                position: relative;
                left: 50%;
                margin-left: rem(-151);
            }
            margin: auto;
        }
    }

    #captcha_status-error {
        @media all and (max-width: $sm-breakpoint) {
            left: 50%;
            margin-left: rem(-152);
        }
        position: relative;
        top: rem(-52);
        width: rem(304);
        padding: rem(10);
        margin: auto;
        color: $white;
        font-size: rem(14);
        font-weight: 300;
        text-align: left;
        background-color: $error-color;
    }

    .page.messages {
        position: absolute;
        right: 0;
        left: 0;
        margin: 12px 15px 0;
    }

    .forgot-password-page .note {
        padding: 0 rem(30);
        color: $dark-grey2;
    }

    .modal__content {
        @media all and (max-width: $md-breakpoint) {
            padding: rem(40) rem(20) rem(90);

            button {
                width: 100%;
            }
        }
    }
}