.homepage-slider {
    &__slide {
        position: relative;
        img {
            position: relative;
            width: auto;
            height: rem(700);
            @media all and (min-width: $md-breakpoint) {
                width: 100%;
                height: auto;
            }
        }
    }
    &__caption {
        @media all and (max-width: $md-breakpoint) {
            top: 0;
        }
        position: absolute;
        top:  rem(100);
        left: 0;
        z-index: 9;
        width: 100%;
        height: rem(250);
        padding-top: rem(40);
        padding-bottom: rem(40);
        color: $white;
        background-color: rgba($blue, 0.9);

        &-content {
            position: relative;
            height: rem(250);
            @include container($container, $gutter);
        }

        &-text {
            display: none;
            padding-top: rem(40);
            padding-right: rem(170);
            padding-left: rem(430);
            font-size: rem(14);
            line-height: 1.57;
            @media all and (min-width: $md-breakpoint) {
                display: block;
            }
        }

        &-product {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: rem(387);
            max-width: rem(387);
            padding: rem(40);
            border: 4px solid $white;
            @media all and (min-width: $md-breakpoint) {
                position: absolute;
                top: rem(-8);
                left: rem(20);
            }

            .price-wrapper {
                @media all and (max-width: $sm-breakpoint) {
                    display: none;
                }
                display: block;
            }

            .product-link {
                @media all and (max-width: $sm-breakpoint) {
                    display: block;
                }

                display: none;
                font-family: $helvetica-bold;
                font-size: rem(14);

                &:after {
                    position: absolute;
                    width:0;
                    height:0;
                    margin: rem(4) 0 0 rem(15);
                    border: rem(6) solid transparent;
                    border-left: rem(6) solid $white;
                    content: '';
                    visibility: visible;
                    transition: all 0.3s;
                }
            }
        }

        .price-wrapper {
            font-family: $helvetica-bold;
            font-size: rem(14);
            &:before {
                content: 'From RRP';
            }
        }

        ol {
            padding: 0;
            margin: 0;
        }

        .product-name {
            margin: 0 0 rem(35);
            font-family: $helvetica-light;
            font-size: rem(30);
            line-height: 1.31;
            a {
                text-decoration: none;
            }
        }

        &-link {
            .product-name {
                position: absolute;
                bottom: rem(75);
                height: rem(20);
                margin: 0;
                font-family: $helvetica-bold;
                font-size: rem(14);
                a {
                    display: block;
                    visibility: hidden;
                    @include triangle($white);
                    &:after {
                        position: absolute;
                        top: 3px;
                        right: rem(-25);
                        visibility: visible;
                    }
                    &:before {
                        display: block;
                        content: 'Shop now';
                        visibility: visible;
                    }
                }
            }
        }
    }

    &__promo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &-content {
            position: relative;
            height: 100%;
            @include container($container, $gutter);
        }
        &-box {
            position: relative;
            top: rem(140);
            width: 100%;
            height: rem(287);
            max-width: rem(387);
            padding: rem(30);
            color: $blue;
            border: 4px solid $blue;
            @media all and (min-width: $md-breakpoint) {
                position: absolute;
                top: rem(160);
                right: rem(20);
                left: auto;
            }
            @media all and (min-width: $lg-breakpoint) {
                height: rem(387);
            }
        }
        &-text {
            h1 {
                font-family: $helvetica-light;
                font-size: rem(32);
                font-weight: 500;
            }
            h2 {
                font-family: $helvetica-bold;
                font-size: rem(14);
            }
            a {
                position: absolute;
                bottom: rem(30);
                left: 0;
                padding-right: rem(20);
                padding-left: rem(20);
                font-family: $helvetica-bold;
                font-size: rem(14);
                text-decoration: none;
                @include triangle($blue);
                &:after {
                    position: absolute;
                    right: rem(-30);
                }
            }
        }
        &-store {
            position: absolute;
            top: rem(500);
            right:rem(20);
            left:rem(20);
            /*width: 100%;*/
            max-width: rem(387);
            color: $white;

            @media all and (min-width: $md-breakpoint) {
                position: absolute;
                top:auto;
                right: rem(20);
                bottom: rem(100);
                left: auto;
                width: 100%;
            }
            @media all and (min-width: $lg-breakpoint) {
                bottom: rem(140);
            }
            a {
                position:relative;
                display: block;
                width: 100%;
                padding: rem(22) rem(30);
                font-family: $helvetica-bold;
                font-size: rem(14);
                text-decoration: none;
                background-color: $blue;
                @include triangle($white);

                &:after {
                    position: absolute;
                    top: 50%;
                    right: rem(20);
                    margin-top: -6px;
                }
            }
        }
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 9;
        transform: translateY(-50%);
    }

    .slick-dots {
        position: absolute;
        bottom: rem(27);
        left:50%;
        padding: 0;
        margin: 0;
        overflow:hidden;
        transform: translate(-50%, 0);

        @media all and (min-width: $sm-breakpoint) {
            top: 50%;
            right: rem(45);
            bottom:auto;
            left:auto;
            transform: translate(0, -50%);
        }

        li {
            display:inline-block;
            padding:0 rem(7);
            list-style: none;

            @media all and (min-width: $sm-breakpoint) {
                display:block;
                padding:0;
            }

            button {
                width: 12px;
                height: 12px;
                padding:0;
                font-size: 0;
                line-height: 1;
                background-color: $white;
                border: none;
                border-radius: 50%;
                opacity: 0.5;

                &:hover {
                    cursor: pointer;
                }
                &:focus {
                    outline: none;
                }
            }
            &.slick-active {
                button {
                    opacity: 1;
                }
            }
        }
    }
}
