.page {
    .message-error, .message-success {
        width: 100%;
        padding: rem(30);
        margin-bottom: rem(30);
        color: $white;
        font-family: $helvetica-bold;
        text-align: center;
        background-color: $light-blue;

        a {
            text-decoration: underline;
        }
    }

    &.messages{
        @media all and (max-width: $md-breakpoint) {
            position: static;
        }
        @media all and (min-width: $md-breakpoint) {
            //position:static;
        }
        //position:relative;
        //top:100px;
        z-index: 10;

    }
}