.cms-no-route {
    .page-main {
        @extend .container;
        padding-top: rem(70);
        text-align: center;
    }

    .page-title {
        @media all and (max-width: $md-breakpoint) {
            padding: rem(20) rem(30);
        }

        margin-bottom: rem(45);
    }

    .main dl {
        color: $dark-grey2;
        font-size: rem(14);
        line-height: 1.57;
    }

    .footer-coloured-blocks {
        @media all and (max-width: $md-breakpoint) {
            margin-right: rem(-20);
            margin-left: rem(-20);
        }
        margin-top: rem(150);
    }
}

.error-search {
    margin-top: rem(60);

    @media all and (min-width: $sm-breakpoint) {
        margin-top: rem(70);
    }

    .fieldset {
        @media all and (min-width: $sm-breakpoint) {
            flex-wrap: nowrap;
        }
    }

    .field {
        @media all and (min-width: $sm-breakpoint) {
            margin-right: 0;
            margin-bottom: 0;
        }
        margin-bottom: rem(20);

        input {
            outline: none;
        }
    }

    .btn {
        width: 100%;
        margin: 0 rem(10);

        @media all and (min-width: $sm-breakpoint) {
            width: rem(85);
            margin: 0;
        }
    }
}