*, *:before, *:after {
    box-sizing: inherit;
    outline: none;
    -webkit-tap-highlight-color: rgba($white, 0.25);
}

html,body{
    padding:0;
    margin:0;
}

html {
    box-sizing: border-box;
    font-size: $base-font-size + px;
}

body {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    color: $dark-grey;
    font-family: $helvetica;
    background-color: $white;
    &.modal-open {
        position: fixed;
        width:100%;
        overflow: hidden;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $helvetica-light;
    font-weight: $normal;
}

em {
    // font-family: $helvetica-italic;
    // font-style: $normal;
}

strong {
    font-family: $helvetica-bold;
    font-weight: $normal;
}

.container {
    @include container($container, $gutter);
    transition: all 0.6s;
}

.l-container {
    @include container($l-container, $gutter);
    transition: all 0.6s;
}

.xl-container {
    @include container($xl-container, $gutter);
    transition: all 0.6s;
}

.message.global.cookie {
    position: relative;
    top: calc(#{$header-height} + #{$header-nav-storelocator});
    @media all and (min-width: $md-breakpoint) {
        top: $full-header-height;
    }
    z-index: 1;
    display: flex;
    justify-content: center;
    padding: 1rem;
    p {
        margin-top: 0;
        font-size: 14px;
        @media all and (min-width: $md-breakpoint) {
            font-size: 16px;
        }
    }
    a {
        color: $light-blue;
    }
}

.mgz-element-text {
    color: $dark-grey2;
    line-height: 1.4;
}

.page-wrapper {
    position: relative;
    padding-top: calc(#{$header-height} + #{$header-nav-storelocator});
    @media all and (min-width: $md-breakpoint) {
        padding-top: $full-header-height;
    }

    h1.title,
    .page-title {
        color: $bright-blue;
        font-size: rem(32);
    }

    .page-main {
        position: relative;

        img {
            height: auto;
            max-width: 100% !important;
        }

        .footer-coloured-blocks {
            @media all and (min-width: $md-breakpoint) {
                margin-bottom: rem(-80);
            }

            .coloured-blocks {
                margin-top: 0;
            }
        }
    }
}

.cms-home,
.storelocator-store-index {
    .page-wrapper {
        margin-top:0 !important;
    }
    .page-main {
        top: 0;
        margin-top:0;
    }
}

.page-products {
    .product-price {
        span.old-price {
            display:none;
        }
        span.price {
            display:flex;
            flex-direction: row;
            margin-top: 1rem;
            &:before {
                margin-right: rem(4);
                font-family: $helvetica-light;
                font-size: rem(12);
                // content: 'From RRP';
            }
            div {
                &.fabric-container,
                &.leather-container {
                    display:flex;
                    align-items: center;
                    flex-direction: column;
                    padding: 0 0.5rem;
                    &.has-discount {
                        .was-price {
                            font-weight: 700;
                            text-decoration: line-through;
                        }
                        .now-price {
                            color: rgba(159, 56, 57, 1);
                            font-weight: 700;
                        }
                    }
                    .now-price {
                        font-weight: 700;
                    }
                    .swatch-count {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        margin-top: 1rem;
                        font-size: rem(13);
                        .custom-leather,
                        .custom-fabric {
                            margin-right: 0.5rem;
                        }
                    }
                    .custom-question.tooltip {
                        display: none;
                    }
                }
                &.leather-container {
                    border-left: 1px solid #F6F6F6;
                }
            }
            &.leather-only {
                div.leather-container {
                    border-left: 0;
                }
            }
            span {
                &.was-price {
                    font-weight: 700;
                    text-decoration: line-through;
                }
                &.now-price {
                    // color: rgba(159, 56, 57, 1);
                    font-weight: 700;
                }
            }
        }
    }
}

.page-products {
    .product-price {
        span.old-price {
            display:none;
        }
        span.covered {
            &:before {
                margin-right: 0;
                font-family: $helvetica-light;
                font-size: rem(12);
                // content: 'From RRP';
            }
            span {
                &.was-price {
                    font-weight: 700;
                    text-decoration: line-through;
                }
                &.now-price {
                    color: rgba(159, 56, 57, 1);
                    font-weight: 700;
                }
            }
        }
    }
}

.page-products {
    .product-price {
        span.old-price {
            display:none;
        }
        span.non-covered {
            &:before {
                margin-right: 0;
                font-family: $helvetica-light;
                font-size: rem(12);
                // content: 'Fabric from RRP';
            }
            span {
                &.was-price {
                    font-weight: 700;
                    text-decoration: line-through;
                }
                &.now-price {
                    color: rgba(159, 56, 57, 1);
                    font-weight: 700;
                }
            }
        }
    }
}

.page-layout-full-width {
    .page-main {
        max-width: 100%;
        padding-right: 0;
        padding-left: 0;
    }

    &.cms-page-view {
        .page-main {
            padding-top: 0;
        }
    }
}

.modal-popup {
    &.popup-authentication {
        display: none;
    }
}

.social-icon {
    display: inline-block;
    width: rem(32);
    height: rem(32);
    background-color: $bright-blue;
    border-radius: 50%;
    &:hover {
        text-decoration: none !important;
    }
    + .social-icon {
        margin-left: rem(10);
    }
    i {
        color: $white;
        font-size: rem(32);
        line-height: rem(32);
    }
}

.mfp-wrap {
    position: fixed !important;
    top: 0!important;
    right: 0!important;
    bottom: 0!important;
    left: 0!important;
    overflow: auto;
}

.lazboy-spinner {
    position: relative;
    top: rem(-5);

    #cssload-pgloading {

        .cssload-loadingwrap {
            position:absolute;
            top:45%;
            right:25%;
            bottom:45%;
            left:25%;
        }

        .cssload-bokeh {
            position: relative;
            width: 1em;
            height: 1em;
            padding:0;
            margin: 0 auto;
            font-size: 97px;
            list-style: none;
            border-radius: 50%;

            li {
                position: absolute;
                width: 0.2em;
                height: 0.2em;
                border-radius: 50%;

                &:nth-child(1) {
                    top: 0;
                    left: 50%;
                    margin: 0 0 0 -0.1em;
                    background: rgba(0, 103, 155, 0.98);
                    transform-origin: 50% 250%;
                    animation: cssload-rota 1.3s linear infinite, cssload-opa 4.22s ease-in-out infinite alternate;
                }

                &:nth-child(2) {
                    top: 50%;
                    right: 0;
                    margin: -0.1em 0 0 0;
                    background: rgba(114, 151, 169, 0.97);
                    transform-origin: -150% 50%;
                    animation: cssload-rota 2.14s linear infinite, cssload-opa 4.93s ease-in-out infinite alternate;
                }

                &:nth-child(3) {
                    bottom: 0;
                    left: 50%;
                    margin: 0 0 0 -0.1em;
                    background: rgb(156, 97, 110);
                    transform-origin: 50% -150%;
                    animation: cssload-rota 1.67s linear infinite, cssload-opa 5.89s ease-in-out infinite alternate;
                }

                &:nth-child(4) {
                    top: 50%;
                    left: 0;
                    margin: -0.1em 0 0 0;
                    background: rgb(165, 156, 78);
                    transform-origin: 250% 50%;
                    animation: cssload-rota 1.98s linear infinite, cssload-opa 6.04s ease-in-out infinite alternate;
                }
            }
        }

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            content: '';
        }

        @keyframes cssload-rota {
            from { }
            to
            { transform: rotate(360deg); }
        }
        @keyframes cssload-opa {
            0% { }
            12.0%
            { opacity: 0.8; }
            19.5%
            { opacity: 0.88; }
            37.2%
            { opacity: 0.64; }
            40.5%
            { opacity: 0.52; }
            52.7%
            { opacity: 0.69; }
            60.2%
            { opacity: 0.6; }
            66.6%
            { opacity: 0.52; }
            70.0%
            { opacity: 0.63; }
            79.9%
            { opacity: 0.6; }
            84.2%
            { opacity: 0.75; }
            91.0%
            { opacity: 0.87; }
        }
    }
}

/* Magento generates empty p blocks inside widget and content blocks. Just hide them */
.block.widget,
.cms-home .column.main,
.storelocator-store-index .column.main,
.top-banner{
    & > p{
        display:none;
    }
}

// Responsive styles for images in generic text
.cms-about{
    .column.main{
        img{
            width:100%;
            height:auto;

            @media all and (min-width: $md-breakpoint) {
                width:auto;
            }
        }
    }
}

img.no-float-on-mobile {

    @media all and (max-width: $md-breakpoint) {
        float: none;
        margin: rem(20) 0;
    }
}

// Utility classes for hiding country specific content
.au .hidden-au,
.nz .hidden-nz {
    display: none !important;
}
