.featured-product {
    @media all and (max-width: $sm-breakpoint) {
        display: flex;
        flex-flow: row wrap;
    }
    position: relative;
    z-index: 0;
    overflow-x: hidden;

    @media all and (min-width: $md-breakpoint) {
        margin-top: rem(-180);
    }
    &--small-gap {
        .featured-product__caption-content {
            @media all and (min-width: $md-breakpoint) {
                right: auto !important;
                left: rem(20) !important;
            }
        }
        @media all and (min-width: $md-breakpoint) {
            margin-top: rem(-80);
        }
    }

    &__image {
        @media all and (max-width: $sm-breakpoint) {
            order: 2;
        }

        img {
            @media all and (max-width: $sm-breakpoint) {
                position: static;
            }

            position: relative;
            left: -50%;
            display: block;
            width: auto;
            //height: rem(700);
            max-height: rem(700);
            min-height: rem(500);
            @media all and (min-width: $sm-breakpoint) {
                left: 0;
            }
            @media all and (min-width: $md-breakpoint) {
                width: 100%;
                height: auto;
                max-height: none;
                min-height: rem(700);
            }
        }
    }

    &__caption {
        @media all and (max-width: $sm-breakpoint) {
            position: static;
            flex: 1;
            order: 1;
        }
        @media all and (min-width: $sm-breakpoint) {
            position: absolute;
            top: 0;
            left: 50%;
            display: flex;
            align-items: flex-end;
            height: 100%;
            padding-bottom: rem(200);
            transform: translateX(-50%);
            @include container($container, $gutter);
        }

        &-content {
            position:relative;
            width: 100%;
            height: rem(387);
            max-width: rem(387);
            padding: rem(40);
            color: $white;
            //text-shadow: 0 0 rem(4) $dark-grey;
            text-shadow: none;
            border: 4px solid $white;

            @media all and (min-width: $sm-breakpoint) {
                position: absolute;
                right: rem(20);
                bottom: rem(64);
            }
            ol {
                padding: 0;
                margin: 0;
            }
            .price-wrapper {
                font-family: $helvetica-bold;
                font-size: rem(14);
                &:before {
                    content: 'From RRP ';
                }
            }
            .product-name {
                margin: rem(100) 0 rem(15);
                font-family: $helvetica-light;
                font-size: rem(30);
                line-height: 1.31;
                a {
                    text-decoration: none;
                }
            }

            &:hover {
                text-shadow: none;
            }
        }
        &-link {
            .product-name {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: rem(80);
                margin: 0;
                font-family: $helvetica-bold;
                font-size: rem(14);

                a {
                    display: block;
                    height: rem(80);
                    overflow-y: hidden;
                    line-height: rem(80);
                    //visibility: hidden;
                    @include triangle($white);

                    &:hover {
                        &:before {
                            color: $dark-grey;
                            background-color: $white;
                        }
                        &:after {
                            border-left-color: $dark-grey;
                        }
                    }
                    &:after {
                        position: absolute;
                        top: rem(34);
                        right: rem(220);
                        visibility: visible;
                        transition: all 0.3s;

                        @media all and (max-width:$sm-breakpoint){
                            right:auto;
                            left:rem(150);
                        }
                    }
                    &:before {
                        display: block;
                        padding-left: rem(40);
                        //content: 'Shop now';
                        content: 'Find out more';
                        visibility: visible;
                        transition: all 0.3s;
                    }
                }
            }
        }
        &.blue-box-solid {

            .featured-product__caption-content {
                @media all and (max-width: $sm-breakpoint) {
                    right: 0;
                    max-width: none;
                }
                @media all and (min-width: $sm-breakpoint) {
                    position: absolute;
                    //right: 0;
                    bottom: rem(125);
                }
                @media all and (min-width: $sm-breakpoint) {
                    //right: rem(20);
                }

                @media all and (max-width: $sm-breakpoint) {
                    height: auto !important;
                    padding: rem(45) rem(28) rem(50);
                    * {
                        padding: 0;
                        margin: 0;
                    }
                    .product-name {
                        margin-bottom: rem(20);
                    }
                }

                height: rem(323);
                background-color: rgba(0, 103, 155, 0.9);
                border: none;
            }
            .featured-product__caption-link {

                .product-name {
                    top: 100%;
                    height: rem(60);
                    margin-top: rem(2);
                    background-color: rgba(0, 103, 155, 0.9);

                    a {
                        height: rem(60);
                        line-height: rem(60);

                        &:after {
                            top: rem(24);
                            right: rem(25);
                            left: auto;
                        }

                        &:hover {
                            &:before {
                                color: $white;
                                background-color: rgba(156, 97, 110, 0.9);
                            }
                            &:after {
                                border-left-color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    .product-link{
        display:none;
    }
}

.cms-index-index .featured-product span.price {

    margin-left: rem(80);

    &:before {
        position: absolute;
        left: rem(41);
        margin: rem(2) 0 0;
        font-family: $helvetica;
        font-size: rem(14);
        font-weight: 700;
        content: 'From RRP';
    }
}

.cms-index-index .featured-product span.covered {

    margin-left: rem(80);

    &:before {
        position: absolute;
        left: rem(41);
        margin: rem(2) 0 0;
        font-family: $helvetica;
        font-size: rem(14);
        font-weight: 700;
        content: 'From RRP';
    }
}

.cms-index-index .featured-product span.non-covered {

    margin-left: rem(130);

    &:before {
        position: absolute;
        left: rem(41);
        margin: rem(2) 0 0;
        font-family: $helvetica;
        font-size: rem(14);
        font-weight: 700;
        content: 'Fabric from RRP';
    }
}
