.contentmanager-contentlist-view {
    .cms-store_directory {

        &__intro {
            margin: rem(32) 0;
            @include container($container, $gutter);
            @media (min-width: $xlg-breakpoint) {
                @include container($xl-container, $xl-gutter);
            }
            .title {
                margin: rem(32) 0;
                color: $light-blue;
                font-family: $helvetica-light;
                font-size: rem(30);
                font-weight: 300;
                text-transform: uppercase;
            }
        }

        &__contents,
        &__retailers {
            @include container($container, $gutter);
            @media (min-width: $xlg-breakpoint) {
                @include container($xl-container, $xl-gutter);
            }
            h2, h3 {
                padding: rem(24) 0;
                color: $dark-grey;
                font-size: rem(20);
                border-bottom: 1px solid $lighter-grey;
                @media all and (min-width: $sm-breakpoint) {
                    font-size: rem(24);
                }
            }
            // collapsible on mobile
            @media all and (max-width: $sm-breakpoint) {
                h3:after {
                    float: right;
                    margin-top: - rem(12);
                    font-size: rem(32);
                    letter-spacing: - rem(4);
                    content: '--';
                }
                .collapsed {
                    padding-bottom: rem(10);
                    h3 {
                        &:after {
                            margin-top: - rem(13);
                            content: '+';
                        }
                        ~ * {
                            display: none;
                        }   
                    }
                }
            }
            transition: all 0.1s;
            .content-item {
                margin: rem(8) 0;
                a {
                    display: inline-block;
                    padding: rem(8) rem(16) rem(8) 0;
                    font-size: rem(14);
                }
                strong {
                    display: block;
                    margin: rem(8) 0; 
                    font-weight: 700;
                    text-transform: uppercase;
                }
                p {
                    margin: rem(8) 0; 
                }
                
            }
            @media all and (min-width: $xs-breakpoint) {
                &-items {
                    display: flex;
                    align-items: flex-start;
                    flex-flow: row;
                    flex-wrap: wrap;
                }
                .content-item {
                    flex-basis: 50%;
                }
            }
            @media all and (min-width: $sm-breakpoint) {
                .north-island, .south-island,
                .harvey-norman, .other {
                    float: left;
                    width: 50%;
                    &.au {
                        width: 100%;
                        .content-item {
                            flex-basis: 25%;
                        }
                    }
                }
            }
            &.top-section {
                padding-right: 0;
                padding-left: 0;

                a {
                    text-decoration: underline;
                    text-transform: uppercase;
                }

                strong {
                    margin: 0;
                    text-transform: uppercase;
                }

                @media all and (min-width: $xs-breakpoint) {
                    .content-item {
                        flex-basis: 100%;
                    }
                }
            }
        }
        &__backup {
            margin-top: rem(32);
            margin-bottom: rem(32);
            @include container($container, $gutter);
            @media (min-width: $xlg-breakpoint) {
                @include container($xl-container, $xl-gutter);
            }
            .btn {
                position: relative;
                text-align: center;
                transition: none;
                @include chevron(chevron-left, 8px, 2px, $light-blue, left, $white);
                .chevron-left {
                    position: absolute;
                    top: 50%;
                    left: rem(32);
                    margin-top: -8px;
                }
                &:hover {
                    .chevron-left {
                        &:before {
                            border-right-color: $white;
                        }
                        &:after {
                            border-right-color: $light-blue;
                        }
                    }
                }
                @media all and (max-width: 400px) {
                    width: calc(100% - 60px);
                }
            }
            .back-to-top {
                position: absolute;
                right: rem(32);
                margin-top: rem(8);
                @media all and (min-width: $sm-breakpoint) {
                    right: rem(32);
                }
                @include chevron(chevron-up, 8px, 2px, $light-blue, up, $white);
                .chevron-up {
                    position: relative;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    background-color: $white;
                    border: 2px solid $light-blue;
                    border-radius: 50%;
                    &:before {
                        bottom: 15px;
                        left: 11px;
                    }
                    &:after {
                        bottom: 13px;
                        left: 11px;
                    }
                }
                &:hover {
                    .chevron-up {
                        background-color: $light-blue;
                        &:before {
                            border-bottom-color: $white;
                        }
                        &:after {
                            border-bottom-color: $light-blue;
                        }
                    }
                }
            }
        }
    }
}